import React from 'react';
import { SketchPicker } from 'react-color';
import styled from '@emotion/styled';

const Wrapper = styled.div({
  position: 'relative',
});

const Swatch = styled.div({
  padding: '5px',
  background: '#fff',
  borderRadius: '1px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',
});

const Color = styled.div(
  {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  (props) => ({ background: props.color })
);
const Popover = styled.div({
  bottom: 28,
  right: 0,
  position: 'absolute',
  zIndex: '5',
});

const Cover = styled.div({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
});

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.props.handleChange(color.hex);
  };

  render() {
    return (
      <Wrapper>
        <Swatch onClick={this.handleClick}>
          <Color color={this.props.color} />
        </Swatch>
        {this.state.displayColorPicker ? (
          <Popover>
            <Cover onClick={this.handleClose} />
            <SketchPicker
              color={this.props.color}
              onChange={this.handleChange}
            />
          </Popover>
        ) : null}
      </Wrapper>
    );
  }
}

export default ColorPicker;

import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../../../utils";
import css from "./Slides.module.scss";

function Slides({ slides, selectSlide }) {
  const renderSlides = () => {
    return slides.map((el) => (
      <li key={el.id} onClick={() => selectSlide(el)}>
        {el.name}
      </li>
    ));
  };

  if (isEmpty(slides)) {
    return <div className={css.container}>No slides found</div>;
  }

  return (
    <div className={css.container}>
      <h2>Slides</h2>
      <ul>{renderSlides()}</ul>
    </div>
  );
}

Slides.propTypes = {
  selectSlide: PropTypes.func.isRequired,
};

export default Slides;

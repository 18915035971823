import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';
import SubcategoryListItem from './SubcategoryListItem';

function CategoryListItem({ category }) {
  return (
    <li>
      <div className="list-inner">
        {category.name}
        <Category category={category} />
      </div>

      <ul className="subcats-container">
        {category.subcategories.map((el) => {
          return (
            <SubcategoryListItem
              key={el.id}
              category={el}
              parentId={category.id}
            />
          );
        })}
        <Category parentId={category.id} />
      </ul>
    </li>
  );
}

CategoryListItem.propTypes = {
  category: PropTypes.object.isRequired,
};

export default CategoryListItem;

/* eslint-disable react/no-did-update-set-state */
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadUser, updateProfile } from '../../../redux/actions/authActions';
import { isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import { TextField, Stack } from '@mui/material';

export class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      referralCode: '',
      success: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { loadUser, user } = this.props;
    if (isEmpty(user)) {
      loadUser();
    } else this.updateState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, errors } = this.props;
    if (!_.isEqual(prevProps.user, user)) {
      this.updateState();
    }
    if (!_.isEqual(prevProps.errors, errors)) {
      this.setState({ errors });
    }

    if (this.state.success && isEmpty(errors)) {
      setTimeout(() => {
        this.setState({
          success: false,
        });
      }, 3000);
    }
  }

  updateState = () => {
    const { user } = this.props;
    this.setState({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      union: user.union,
      local: user.local,
      referralCode: user.referralCode,
    });
  };

  handleSubmit = () => {
    const { snackbar } = this.props;
    const { firstName, lastName, email, password, password2 } = this.state;
    const payload = {
      firstName,
      lastName,
      email,
    };
    axios
      .put(`/users/profile`, payload)
      .then((res) => {
        if (password.length <= 1) {
          snackbar.setSnackbarMessage('Profile info updated');
        }
        this.setState({ success: true, errors: {} });
        this.props.loadUser();
      })
      .catch((err) => {
        this.setState({ errors: err.response.data });
      });

    if (password.length > 1)
      axios
        .put(`/users/changePassword`, {
          newPassword: password2,
          password,
        })
        .then((res) => {
          snackbar.setSnackbarMessage('Profile info & password updated');
          this.setState({
            success: true,
            password: '',
            password2: '',
            errors: {},
          });
        })
        .catch((err) => {
          this.setState({ errors: err.response.data });
        });
  };

  handleUpdate = () => {
    const { updateProfile } = this.props;
    updateProfile();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      id,
      firstName,
      lastName,
      email,
      password,
      password2,
      errors,
      referralCode,
    } = this.state;
    const mailToString = `mailto:?subject=UPP%20referral&body=${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`;

    const share = () => {
      if (navigator.share) {
        navigator
          .share({
            title: 'UPP referral',
            text: `Referral Code: ${referralCode}`,
            url: `${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Web Share API is not supported in your browser.');
      }
    };

    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(referralCode);
      alert('Referral Code copied to clipboard.');
    };

    return (
      <div>
        <h1>Profile settings</h1>

        {navigator.share ? (
          <>
            <p>USER ID {id}</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '2vw',
              }}
            >
              <div
                className="referralCode"
                onClick={() => {
                  handleCopyToClipboard();
                }}
              >
                <p>
                  REFERRAL CODE : <span>{referralCode}</span>
                </p>
              </div>
              <button className="shareButton" onClick={share}>
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3333 12.0161C12.6578 12.0161 12.0533 12.2721 11.5911 12.6732L5.25333 9.13153C5.29778 8.93524 5.33333 8.73896 5.33333 8.53414C5.33333 8.32932 5.29778 8.13303 5.25333 7.93675L11.52 4.42922C12 4.85592 12.6311 5.12048 13.3333 5.12048C14.8089 5.12048 16 3.97691 16 2.56024C16 1.14357 14.8089 0 13.3333 0C11.8578 0 10.6667 1.14357 10.6667 2.56024C10.6667 2.76506 10.7022 2.96135 10.7467 3.15763L4.48 6.66516C4 6.23845 3.36889 5.9739 2.66667 5.9739C1.19111 5.9739 0 7.11747 0 8.53414C0 9.9508 1.19111 11.0944 2.66667 11.0944C3.36889 11.0944 4 10.8298 4.48 10.4031L10.8089 13.9533C10.7644 14.1325 10.7378 14.3203 10.7378 14.508C10.7378 15.882 11.9022 17 13.3333 17C14.7644 17 15.9289 15.882 15.9289 14.508C15.9289 13.134 14.7644 12.0161 13.3333 12.0161Z"
                    fill="#ED1C24"
                  />
                </svg>
                <div className="shareText">SHARE</div>
              </button>
            </div>
          </>
        ) : (
          <>
            <p>
              USER ID:
              {id}
            </p>
            <div
              className="referralCode"
              onClick={() => {
                navigator.clipboard.writeText(referralCode);
              }}
            >
              <p>REFERRAL CODE : {referralCode}</p>
            </div>
            <a href={mailToString}>SHARE</a>
          </>
        )}

        <Stack direction="column" spacing={2} sx={{ my: 3 }}>
          <TextField
            value={firstName}
            name="firstName"
            label="First name"
            onChange={this.onChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            value={lastName}
            name="lastName"
            label="Last name"
            onChange={this.onChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            value={email}
            name="email"
            label="Email"
            helperText="Email address you use to log in"
            onChange={this.onChange}
            error={errors.email}
            disabled={true}
          />
          <TextField
            value={password}
            type="password"
            name="password"
            label="Old Password"
            onChange={this.onChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            value={password2}
            type="password"
            name="password2"
            label="New password"
            onChange={this.onChange}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
        </Stack>
        <Button text="Save" onClick={this.handleSubmit} />
      </div>
    );
  }
}

ProfileSettings.propTypes = {
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errors: state.errors,
});

const mapDispatchToProps = {
  updateProfile,
  loadUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);

/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  CLEAR_ERRORS,
  CLEAR_PAGES,
  GET_ERRORS,
  GET_PAGES,
  SET_PAGES_LOADING,
} from '../types';

export const setLoading =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_PAGES_LOADING,
      payload: val,
    });
  };

export const getPages =
  (page = 1, title) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS,
    });
    dispatch(setLoading());
    const params = {
      page,
      title,
    };
    try {
      const res = await axios.get(`/pages`, { params });
      dispatch({
        type: GET_PAGES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: CLEAR_PAGES });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };

import { Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { a11yProps } from '../../../utils';
import TabPanel from '../../Layout/TabPanel';
import CashbackPercentage from './CashbackPercentage';
import ReferralValue from './ReferralValue';
import { useHistory, useParams } from 'react-router-dom';

export default function SystemSettings() {
  const history = useHistory();
  const { page } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue);
      const newHistoryValue = `/dashboard/system/${newValue + 1}`;
      history.replace(newHistoryValue);
    },
    [history]
  );
  useEffect(() => {
    const realPage = page ? parseInt(page, 10) : 1;
    setSelectedTab(realPage - 1);
  }, [page]);
  return (
    <>
      <h1>System Settings</h1>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        <Tab label="System Wide Cashback Percentage" {...a11yProps(0)} />
        <Tab label="Member Referrals Cashback" {...a11yProps(1)} />
        <Tab label="Local Business Referrals Cashback" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <CashbackPercentage />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ReferralValue
          endpoint="member-referral-amount"
          fieldLabel="Cashback Value"
          fieldName="systemMemberReferralsAmount"
          confirmationText="You are about to change the member referrals cashback for the whole system"
        />
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <ReferralValue
          endpoint="local-business-referral-amount"
          fieldLabel="Cashback Value"
          fieldName="systemLocalBusinessReferralsAmount"
          confirmationText="You are about to change the local business referrals cashback for the whole system"
        />
      </TabPanel>
    </>
  );
}

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField } from '@mui/material';
import axios from 'axios';
import Button from '../../Inputs/Button';
import { isEmpty } from '../../../utils';

function CreatePoll({ history }) {
  const [pollName, setPollName] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      name: pollName,
    };
    try {
      const res = await axios.post('/polls', payload);
      setLoading(false);
      history.push(`/dashboard/polls/poll/${res.data.id}`);
    } catch (err) {
      setErrors(err.response);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Create a new poll</h1>
      <TextField
        fullWidth
        name="pollName"
        value={pollName}
        label="Set an internal name for your poll"
        onChange={(e) => setPollName(e.target.value)}
        error={isEmpty(errors) ? null : !!errors.data.name}
        helperText={isEmpty(errors) ? null : errors.data.name}
        inputProps={{ maxLength: 50 }}
        sx={{ mb: 3 }}
      />
      <Button text="Create" onClick={handleSubmit} isLoading={loading} />
    </div>
  );
}

export default withRouter(CreatePoll);

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCategories } from '../../../redux/actions/categoryActions';
import { isEmpty } from '../../../utils';
import Pagination from '../../Layout/Pagination';
import List from './List';

const columns = [
  { label: 'Business Name', name: 'name' },
  { label: 'Signup Date', name: 'signupDate', type: 'date' },
  { label: 'Approval Date', name: 'approvalDate', type: 'date' },
  { label: 'Last Payment Date', name: 'lastPaymentDate', type: 'date' },
  { label: 'Next Payment Date', name: 'nextPaymentDate', type: 'date' },
  { label: 'Status', name: 'status' },
];

const statusFilterOptions = [
  'Pending',
  'Approved',
  'Rejected',
  'Subscribed',
  'Cancelled',
];

const LocalBusinessDashboard = ({ match, history, getCategories }) => {
  const [totalResults, setTotalResults] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState({ field: 'name', asc: true });
  const [statusFilter, setStatusFilter] = useState('');
  const [data, setData] = useState([]);

  const { params } = match;

  const pageLimit = 25;

  const fetchData = useCallback(
    async (page, csv) => {
      const url = `/subscriptions/`;
      const params = {
        page,
        limit: pageLimit,
        status: statusFilter,
        order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
        csv: csv,
      };
      try {
        const res = await axios.get(url, { params });

        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `subscriptions-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setData(res.data.rows);
          setTotalResults(res.data.count);
          setErrors({});
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setErrors(err.response.data);
      }
    },
    [sortBy.asc, sortBy.field, statusFilter]
  );

  const changePage = useCallback(
    (page) => {
      fetchData(page);
      history.push(`/dashboard/local-business-subscriptions/${page}`);
    },
    [fetchData, history]
  );

  useEffect(() => {
    getCategories(params.page ? params.page : undefined);
    fetchData();
  }, [fetchData, getCategories, params.page]);

  useEffect(() => {
    changePage(1);
  }, [changePage, statusFilter]);

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
          pageLimit={pageLimit}
        />
      );
  };

  return (
    <div>
      <h1>Local Business Subscriptions</h1>
      <Box>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            name="status"
            label="Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {statusFilterOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button onClick={() => fetchData(1, true)}>Export</Button>

      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={data}
        loading={loading}
        errors={errors}
        fetchData={fetchData}
        params={params}
      />
      {renderPagination()}
    </div>
  );
};

LocalBusinessDashboard.propTypes = {};

const mapDispatchToProps = {
  getCategories,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LocalBusinessDashboard));

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import DropdownList from './Dropdown/DropdownList';
import css from './Dropdowns.module.scss';

const selectOptions = [
  { value: 'unions', label: 'Union' },
  { value: 'autoinsurances', label: 'Auto Insurance' },
  { value: 'homeinsurances', label: 'Home Insurance' },
  { value: 'carriers', label: 'Wireless Carrier' },
];

function Dropdowns({ match, history }) {
  const { tab } = match.params;

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    selectOptions.forEach((el) => {
      if (tab === el.value) {
        setActiveTab({ label: el.label, value: el.value });
      }
    });
  }, [tab]);

  const handleChange = (event) => {
    const val = event.target.value;
    const selectedItem = selectOptions.find((el) => val === el.value) || '';
    setActiveTab(selectedItem);
    if (selectedItem) {
      history.push(`/dashboard/dropdowns/${selectedItem.value}/`);
    } else history.push(`/dashboard/dropdowns/`);
  };

  return (
    <div className={css.dropdownsContainer}>
      <h1>Dropdowns</h1>
      <FormControl size="small" sx={{ minWidth: 200 }}>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          name="status"
          label="Status"
          value={activeTab?.value || ''}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {selectOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {activeTab ? (
        <DropdownList tab={activeTab} />
      ) : (
        <p>Select a dropdown to edit.</p>
      )}
    </div>
  );
}

Dropdowns.propTypes = {};

export default withRouter(Dropdowns);

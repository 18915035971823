import { MobileDatePicker } from '@mui/lab';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Stack,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { isEmpty } from '../../../utils';
import Pagination from '../../Layout/Pagination';
import Table from './Table';
import css from './TopVisitors.module.scss';

const sortOptions = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Last Visit', value: 'lastVisit' },
  { label: 'Visit Count', value: 'visitCount' },
];

const sortDirectionOptions = [
  { label: 'Ascending', value: 'ASC' },
  { label: 'Descending', value: 'DESC' },
];

const limitOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
];

export default function TopVisitors() {
  const [errors, setErrors] = useState({});
  const [CSVErrors, setCSVErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sterile, setSterile] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [limit, setLimit] = useState(10);
  const [orderType, setOrderType] = useState('DESC');
  const [orderField, setOrderField] = useState('visitCount');
  const getData = async (page = 1, csv = false) => {
    setLoading(true);
    if (page === 1 && csv === false) setPage(1);
    if (!csv) setSterile(true);

    const path = `/reports/visitors`;
    const params = {
      params: {
        page: page,
        limit: limit,
        orderField: orderField,
        orderType: orderType,
        startDate: startDate && moment(startDate).startOf('date').toDate(),
        endDate: endDate && moment(endDate).endOf('date').toDate(),
        csv: csv,
      },
    };

    try {
      const res = await axios.get(path, params);
      setLoading(false);
      if (csv) {
        setCSVErrors({});
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `submissions-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        setErrors({});
        setResults(res.data.results);
        setTotalResults(res.data.totalResults);
      }
    } catch (err) {
      setLoading(false);
      if (csv) {
        setCSVErrors(err.response.data);
        setTotalResults(null);
      } else {
        setErrors(err.response);
        setTotalResults(null);
      }
    }
  };

  const changePage = (page) => {
    getData(page);
    setPage(page++);
  };
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setSterile(false);
  };
  const handleChangeOrderField = (event) => {
    setOrderField(event.target.value);
    setSterile(false);
  };
  const handleChangeOrderType = (event) => {
    setOrderType(event.target.value);
    setSterile(false);
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
    setSterile(false);
  };
  const handleEndDate = (endDate) => {
    setEndDate(endDate);
    setSterile(false);
  };

  const isExportDisabled = () => {
    return !Array.isArray(results) || results?.length === 0;
  };

  return (
    <div className={css.container}>
      <div className={css.header_container}>
        <div>
          <h1>Top visitors report</h1>
          {isEmpty(CSVErrors) ? null : (
            <span className="error_msg">
              Export failed: {CSVErrors.message}
            </span>
          )}
        </div>
      </div>

      <Stack direction="row" spacing={1}>
        <MobileDatePicker
          name="startDate"
          value={startDate}
          label="Start Date"
          onChange={handleStartDate}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <MobileDatePicker
          name="endDate"
          value={endDate}
          label="End Date"
          onChange={handleEndDate}
          error={errors.endDate}
          minDate={moment(startDate).toDate()}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="sortBy-select-label">Sort by</InputLabel>
          <Select
            labelId="sortBy-select-label"
            name="sortBy"
            value={orderField}
            label="Sort by"
            onChange={handleChangeOrderField}
          >
            {sortOptions?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="Sort-select-label">Sort</InputLabel>
          <Select
            labelId="Sort-select-label"
            name="Sort"
            value={orderType}
            label="Sort"
            onChange={handleChangeOrderType}
          >
            {sortDirectionOptions?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 50 }}>
          <InputLabel id="Show-select-label">Show</InputLabel>
          <Select
            labelId="Show-select-label"
            name="Show"
            value={limit}
            label="Show"
            onChange={handleChangeLimit}
          >
            {limitOptions?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          isLoading={loading}
          onClick={() => getData(1)}
          disabled={sterile}
          variant="contained"
        >
          Generate
        </Button>
      </Stack>
      <Button onClick={() => getData(1, true)} disabled={isExportDisabled()}>
        Export
      </Button>
      {isEmpty(errors) ? (
        <Table data={results} errors={errors} />
      ) : (
        <p>{errors.data.message}</p>
      )}

      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
          pageLimit={limit.value}
        />
      ) : null}
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import UserListItem from './UserListItem';
import Spinner from '../../Inputs/Spinner';
import UserListHeader from './UserListHeader';
import { isEmpty } from '../../../utils';

const UserList = ({ users, loading, errors }) => {
  const list = isEmpty(errors) ? (
    users.map(user => <UserListItem key={user.id} user={user} />)
  ) : (
    <li>{errors.message}</li>
  );

  return (
    <div className='list-group'>
      <ul>
        <UserListHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

UserList.defaultProps = {
  users: []
};

export default UserList;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  AUTH_ERROR,
  GET_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_LOADING,
  SET_NOT_AUTHENTICATED,
  SET_REFETCHING_TOKEN,
  USER_LOADED,
} from '../types';

export const setLoading =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: val,
    });
  };

export const setRefreshingToken =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_REFETCHING_TOKEN,
      payload: val,
    });
  };

export const loadUser = () => async (dispatch) => {
  try {
    const res = await axios.get(`/users/profile`);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

const performLogIn = async (dispatch, email, password) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { email, password };
  try {
    const res = await axios.post(`/users/cmslogin`, body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const reLogInToRefreshToken = () => async (dispatch, getState) => {
  if (getState().auth.refreshingToken || !getState().auth.isAuthenticated)
    return;
  dispatch(setRefreshingToken());
  dispatch({
    type: REMOVE_TOKEN,
  });
  dispatch({
    type: SET_NOT_AUTHENTICATED,
  });
};

export const login = (email, password) => async (dispatch) => {
  dispatch(setLoading());
  await performLogIn(dispatch, email, password);
};

export const cancelLogin = () => (dispatch) => {
  dispatch(setRefreshingToken(false));
};

export const refetchToken = (email, password) => async (dispatch, getState) => {
  if (getState().auth.refreshingToken) return;
  dispatch(setLoading());
  await performLogIn(dispatch, email, password);
};

export const removeExpiredToken = () => async (dispatch, getState) => {
  if (getState().auth.refreshingToken) return;
  dispatch({
    type: REMOVE_TOKEN,
  });
  dispatch({
    type: SET_NOT_AUTHENTICATED,
  });
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const updateProfile = (body) => async (dispatch) => {
  dispatch(setLoading());
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(`/users/profile`, body, config);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

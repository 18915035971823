import { apiUrl } from '../../../config';

const colors = ['#000000', '#ED1C24', '#4F4F4F', '#828282'];

export const defaultConfig = {
  imageUploadURL: `${apiUrl}files/froala`,
  imageMaxSize: 1024 * 1024 * 5,
  key: process.env.REACT_APP_FROALA_KEY,
  attribution: false,
  heightMax: 400,
  toolbarButtons: [
    ['fullscreen', 'html', 'undo', 'redo'],
    ['bold', 'italic', 'underline', 'textColor', 'clearFormatting'],
    ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
    ['fontFamily'],
    ['fontSize', 'inlineClass', 'paragraphFormat'],
    ['insertLink', 'insertImage', 'quote'],
  ],
  paragraphFormat: {
    N: 'Normal',
    H2: 'Heading 2',
    H3: 'Heading 3',
    H4: 'Heading 4',
    PRE: 'Code',
  },
  pastePlain: true, // filters the pasted content and keeps only plain text by removing all its rich formatting.
  fontFamilySelection: true,
  fontFamily: {
    'Rubik,sans-serif': 'Rubik',
  },
  allowedAttributes: [],
  htmlRemoveTags: ['style', 'script'],
  fontSizeUnit: 'rem',
  fontSize: ['1', '1.25', '1.5', '2', '2.25', '3', '3.5'],
  colorsText: colors,
  useClasses: true,
  inlineClasses: {
    'discount-description': 'Discount Description',
    'business-description': 'Business Description',
    'slide-description': 'Slide Description',
    'homepage-header': 'Homepage Header',
    'homepage-text': 'Homepage Text',
    'about-us-header': 'About Us Header',
    'about-us-text': 'About Us Text',
    terms: 'Terms & Conditions',
  },
};

export const paragraphModuleConfig = {
  key: process.env.REACT_APP_FROALA_KEY,
  attribution: false,
  heightMax: 400,
  toolbarButtons: [
    ['html', 'undo', 'redo'],
    ['bold', 'italic', 'underline', 'textColor', 'clearFormatting'],
    ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
    ['fontFamily'],
    ['fontSize', 'inlineClass'],
    ['insertLink'],
  ],
  paragraphFormat: {
    N: 'Normal',
    H2: 'Heading 2',
    H3: 'Heading 3',
    H4: 'Heading 4',
    PRE: 'Code',
  },
  pastePlain: true, // filters the pasted content and keeps only plain text by removing all its rich formatting.
  fontFamilySelection: true,
  fontFamily: {
    'Rubik,sans-serif': 'Rubik',
  },
  allowedAttributes: [],
  htmlRemoveTags: ['style', 'script'],
  fontSizeUnit: 'rem',
  fontSize: ['1', '1.25', '1.5', '2', '2.25', '3', '3.5'],
  colorsText: colors,
  useClasses: true,
  inlineClasses: {
    'discount-description': 'Discount Description',
    'business-description': 'Business Description',
    'slide-description': 'Slide Description',
    'homepage-header': 'Homepage Header',
    'homepage-text': 'Homepage Text',
    'about-us-header': 'About Us Header',
    'about-us-text': 'About Us Text',
    terms: 'Terms & Conditions',
  },
};

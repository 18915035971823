import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../../redux/actions/userActions';
import { isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import Pagination from '../../Layout/Pagination';
import UserList from './UserList';
import UserSearch from './UserSearch';

const Users = ({ auth, users, getUsers, loading, history, match, errors }) => {
  const { results, totalResults } = users;
  const { params } = match;

  const [search, setSearch] = useState({
    name: '',
    role: '',
  });

  const [roleOptions, setroleOptions] = useState([]);

  useEffect(() => {
    getUsers(params.page ? params.page : 1);

    axios
      .get(`/users/roles`)
      .then((res) => {
        const arr = res.data.map((el) => {
          return { label: el.roleName, value: el.roleName };
        });
        setroleOptions(arr);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [getUsers, params.page]);

  const changePage = (page) => {
    getUsers(
      page,
      search.name,
      isEmpty(search.role) ? undefined : search.role
    );
    history.push(`/dashboard/users/${page}`);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getUsers(
      1,
      search.name,
      isEmpty(search.role) ? undefined : search.role
    );
    history.push(`/dashboard/users/`);
  };

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No users found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const allowedRoles = ['SUPER', 'USER ADMIN', 'WEBSITE ADMIN'];

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="dashboard-header"
      >
        <h1>Users</h1>
        {allowedRoles.includes(auth.user.role) ? (
          <Button text="New User" btnLink url="/dashboard/users/add" />
        ) : null}
      </div>

      <UserSearch
        handleSearch={handleSearch}
        options={roleOptions}
        handleChange={setSearch}
        search={search}
      />
      <UserList users={results} loading={loading} errors={errors} />
      {renderPagination()}
    </div>
  );
};

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
  loading: state.users.loading,
  errors: state.errors,
});

const mapDispatchToProps = {
  getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Tooltip } from '@mui/material';

export default function ReorderButtons({
  index,
  handleMoveDownItem,
  handleMoveUpItem,
  items,
}) {
  return (
    <>
      <Box sx={{ width: 50, ml: 2 }}>
        {index !== 0 && (
          <Tooltip title="Move Up">
            <IconButton onClick={() => handleMoveUpItem(index)}>
              <KeyboardArrowUpIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ width: 50 }}>
        {index !== items.length - 1 && (
          <Tooltip title="Move Down">
            <IconButton onClick={() => handleMoveDownItem(index)}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  );
}

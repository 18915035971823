/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../../redux/actions/authActions';

const Avatar = ({ logout, auth: { isAuthenticated, user } }) => {
  console.log(`Avatar: "${user.role}"`);
  return (
    <div className="avatar">
      <ul>
        {isAuthenticated && user.role === 'SUPER' && (
          <li>
            <NavLink
              className="navlink"
              activeClassName="navlink-active"
              to="/dashboard/system"
            >
              <i className="fas fa-sliders-h" />
              System
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            className="navlink"
            activeClassName="navlink-active"
            to="/dashboard/profile"
          >
            <i className="fas fa-sliders-h" />
            Profile
          </NavLink>
        </li>
        <li>
          <span className="navlink" onClick={logout}>
            <i className="fas fa-sign-out-alt" />
            Logout
          </span>
        </li>
      </ul>
    </div>
  );
};

Avatar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);

import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../Inputs/Spinner";
import DiscountListItem from "./DiscountListItem";
import DiscountListHeader from "./DiscountListHeader";
import { isEmpty } from "../../../utils";

const DiscountList = ({ discounts, loading, errors }) => {
  const list = isEmpty(errors) ? (
    discounts.map((discount) => (
      <DiscountListItem key={discount.id} discount={discount} />
    ))
  ) : (
    <li>{errors.message}</li>
  );
  return (
    <div className="list-group">
      <ul>
        <DiscountListHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

DiscountList.propTypes = {
  discounts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

DiscountList.defaultProps = {
  discounts: [],
};

export default DiscountList;

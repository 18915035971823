import {
  Button,
  FormControl,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Select,
  FormHelperText,
  MenuItem,
  Autocomplete,
  Tabs,
  Tab,
} from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  a11yProps,
  areNullableFloatsEqual,
  isEmpty,
  normalizeFloatNullable,
} from '../../../utils';
import CustomButton from '../../Inputs/Button';
import ImageSelect from '../../Inputs/ImageSelect';
import Spinner from '../../Inputs/Spinner';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import Header from '../../Layout/Header';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import Pagination from '../../Layout/Pagination';
import css from './Business.module.scss';
import { BusinessCashbackScheduler } from './BusinessCashbackScheduler';
import { BusinessCashbackSplitage } from './BusinessCashbackSplitage';
import BusinessDiscountsList from './BusinessDiscountsList';
import CashbackField from './CashbackField';
import { debounce } from 'lodash';
import TabPanel from '../../Layout/TabPanel';
import { fetchUsers } from '../../../utils/fetchUsers';

const affiliateOptions = [
  'Affiliate',
  'National',
  'Regional',
  'Municipal',
  'Local',
];

const publishedOptions = ['Published', 'Not Published'];

const initialState = {
  name: '',
  label: '',
  loading: false,
  type: '',
  results: '',
  totalResults: 0,
  page: 1,
  discounts: [],
  selectedTab: 0,
  isAffiliate: true,
  localBusinessType: null,
  published: 'Published',
  userText: '',
  usersList: [],

  startDate: null,
  endDate: null,
  thumbnail: null,
  thumbnail_fr: null,
  discountErrors: {},
  address: '',
  addressText: '',
  addressList: [],
  postalCode: '',
  city: '',
  appartment: '',
  country: '',
  province: '',
  tab: 'en',
  en: {
    name: '',
    description: '',
    excerpt: '',
    website: '',
  },
  fr: {
    name: '',
    description: '',
    excerpt: '',
    website: '',
  },
  advertisingId: '',
  affiliateProgram: null,
  affiliateProgramId: -1,
  affiliateProgramOptions: [],
  errors: {},
  cashbackSplitOptionError: false,
  loadingAffiliateProgramOptions: true,
  userCashbackPercentage: null,
  systemWideCashbackUserSplitPercentage: null,
  hasBusinessOverride: false,
  originalPercentage: null,
  lng: null,
  lat: null,
  ownerId: null,
  website: null,
  editAffiliate: false,
  cashbackValue: null,
  dutyFree: false,
};

export class Business extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = initialState;
  }

  async componentDidMount() {
    const { mode, id, page } = this.props.match.params;
    if (page && id) {
      this.setState({ selectedTab: parseInt(page) });
    }
    const [error, usersList] = await fetchUsers();
    if (!error) {
      this.setState({ usersList });
    }

    if (mode === 'edit' && id) {
      this.setState({ loading: true });
      this.getBusinessesbyId(id);
      this.getBusinessesUserCashbackSplitageById(id);
      this.getDiscountsById(id);
      this.getSystemWideCashbackUserSplitPercentage();
    } else this.getAffiliatePrograms();
  }

  debounceFetchUsers = debounce(async (input) => {
    const [error, usersList] = await fetchUsers(input);
    if (!error) {
      this.setState({ usersList });
    }
  }, 500);

  debounceFetchAddresses = debounce(
    (text) => this.loadAddressOptions(text),
    500
  );

  // Reset state when component switches to ADD
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.url !== this.props.match.url &&
      this.props.match.url.includes('add')
    ) {
      this.setState({ ...initialState });
    }
  }

  componentWillUnmount() {
    this.setState({ ...initialState });
  }

  getAffiliatePrograms = async () => {
    const id = this.state.affiliateProgramId;
    try {
      const res = await axios.get(`/affiliates`);
      const programData = [
        { label: 'None', value: -1 },
        ...res.data.map((el) => ({
          label: el.name,
          value: el.id,
        })),
      ];
      const affiliateProgram = id
        ? programData.find((el) => el.value === id)
        : null;
      const affiliateProgramId = affiliateProgram?.value || -1;
      this.setState({
        affiliateProgramOptions: programData,
        loadingAffiliateProgramOptions: false,
        affiliateProgramId,
        affiliateProgram,
        tempAffiliateProgramId: affiliateProgramId,
      });
    } catch (err) {
      this.setState({ loadingAffiliateProgramOptions: false });
      console.log(err);
    }
  };

  getSystemWideCashbackUserSplitPercentage = async () => {
    try {
      const res = await axios.get(`/system/user-cashback-percentage`);
      this.setState({
        systemWideCashbackUserSplitPercentage: `${res.data}`,
      });
    } catch (err) {
      this.setState({
        systemWideCashbackUserSplitPercentage: null,
        cashbackSplitOptionError: true,
      });
      console.log(err);
    }
  };

  createOptionsArrayFromString = (string) => ({ label: string, value: string });

  updateState = (business) => {
    this.setState({
      en: {
        name: business.name,
        description: business.description,
        excerpt: business.excerpt ?? '',
        website: business.website ?? '',
      },
      fr: {
        name: business.name_fr,
        description: business.description_fr,
        excerpt: business.excerpt_fr ?? '',
        website: business.website_fr ?? '',
      },
      label: business.label,
      type: business.type,
      description: business.description,
      address: !isEmpty(business.address)
        ? { label: business.address, value: business.address }
        : null,
      published: business.published ? 'Published' : 'Not Published',
      startDate: business.startDate
        ? moment(business.startDate).startOf('day').toDate()
        : null,
      endDate: business.endDate
        ? moment(business.endDate).endOf('day').toDate()
        : null,
      thumbnail: business.thumbnail,
      thumbnail_fr: business.thumbnail_fr,
      city: business.city,
      country: business.country,
      province: business.province,
      postalCode: business.postalCode,
      loading: false,
      advertisingId: business.advertisingId,
      affiliateProgramId: business.affiliateProgramId,
      lng: business.lng,
      lat: business.lat,
      ownerId: business.owner && {
        value: business.owner.id,
        label: `${business.owner.firstName} ${business.owner.lastName} (${business.owner.email})`,
      },
      isAffiliate: business.type !== 'Local',
      localBusinessType: business?.localBusinessType,
      website: business.website,
      cashbackValue: business.cashbackValue,
      dutyFree: business.dutyFree,
    });
    this.getAffiliatePrograms();
  };

  onCashbackSplitageChange = ({
    userCashbackPercentage,
    hasBusinessOverride,
  }) => {
    this.setState({ userCashbackPercentage, hasBusinessOverride });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };

  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };

  handleWysiwyg = (content) => {
    const { tab } = this.state;
    this.setState({
      [tab]: {
        ...this.state[tab],
        description: content,
      },
    });
  };

  handleFileChange = (file, name) => {
    this.setState({ [name]: file });
  };

  handleUpdateAffiliate = async () => {
    const { id } = this.props.match.params;
    const { tempAffiliateProgramId, affiliateProgramOptions } = this.state;
    this.setState({ loading: true });
    await axios.put(`/businesses/${id}/affiliateNetwork/`, {
      affiliateProgramId: tempAffiliateProgramId,
    });
    const affiliateProgram = tempAffiliateProgramId
      ? affiliateProgramOptions.find(
          (el) => el.value === tempAffiliateProgramId
        )
      : null;
    this.setState({
      affiliateProgramId: tempAffiliateProgramId,
      affiliateProgram,
      editAffiliate: false,
      loading: false,
    });
  };

  handleSave = async () => {
    const { history } = this.props;
    const { mode, id } = this.props.match.params;
    const {
      label,
      type,
      address,
      published,
      startDate,
      endDate,
      en,
      fr,
      thumbnail,
      thumbnail_fr,
      city,
      country,
      province,
      postalCode,
      advertisingId,
      affiliateProgramId,
      hasBusinessOverride,
      userCashbackPercentage,
      originalPercentage,
      lng,
      lat,
      ownerId,
      localBusinessType,
      dutyFree,
      cashbackValue,
    } = this.state;
    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/businesses/${id}` : `/businesses`;

    const payload = {
      label,
      type: type,
      name: en.name,
      name_fr: fr.name,
      description: en.description,
      description_fr: fr.description,
      excerpt: en.excerpt,
      excerpt_fr: fr.excerpt,
      website: en.website,
      website_fr: fr.website,
      published: published ? published === 'Published' : null,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      thumbnail: !isEmpty(thumbnail) ? thumbnail.id : null,
      thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : null,
      address: !isEmpty(address) ? address.label : null,
      city: city,
      country: country,
      province: province,
      postalCode: postalCode,
      advertisingId: advertisingId,
      affiliateProgramId:
        affiliateProgramId && affiliateProgramId > 0
          ? affiliateProgramId
          : null,
      address_geolocation: lng && lat ? { lng, lat } : null,
      ownerId: ownerId && ownerId.value,
      localBusinessType,
      cashbackValue,
      dutyFree,
    };

    try {
      const res = await axios[requestMethod](path, payload);
      const businessId = res.data.id;
      if (!['National', 'Regional', 'Municipal'].includes(type.value)) {
        try {
          const targetPercentage = hasBusinessOverride
            ? userCashbackPercentage || '0'
            : null;
          if (
            (requestMethod === 'post' && targetPercentage) ||
            (requestMethod === 'put' &&
              !areNullableFloatsEqual(targetPercentage, originalPercentage))
          ) {
            await axios.post(
              `/businesses/${businessId}/user-cashback-splitage`,
              {
                userPercentage: hasBusinessOverride
                  ? userCashbackPercentage || '0'
                  : null,
              }
            );
          }
          history.push('/dashboard/businesses');
        } catch (error) {
          const obj = error.response.data;
          if (obj.userPercentage) {
            obj.userCashbackPercentage = obj.userPercentage;
          }
          this.setState({ errors: obj, fileUploading: false });
          this.myRef.current.scrollIntoView();
        }
      } else {
        history.push('/dashboard/businesses');
      }
    } catch (error) {
      const obj = error.response.data;
      if (
        obj.hasOwnProperty('name_fr') ||
        obj.hasOwnProperty('description_fr') ||
        obj.hasOwnProperty('excerpt_fr')
      ) {
        this.setState({ tab: 'fr' });
      } else this.setState({ tab: 'en' });
      this.setState({ errors: error.response.data, fileUploading: false });
      this.myRef.current.scrollIntoView();
    }
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleSelectAffiliate = (event) => {
    const value = event.target.value;
    if (!value) return this.setState({ type: '' });
    this.setState({ type: value });
    if (value === 'Local') {
      this.setState({ isAffiliate: false });
    } else {
      this.setState({ isAffiliate: true });
    }
  };

  handleSelectPublished = (event) => {
    this.setState({ published: event.target.value });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleTextField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  changePage = (page) => {
    const { id } = this.props.match.params;
    this.getDiscountsById(id, page);
    this.setState({ page });
    this.props.history.replace(`/dashboard/businesses/${id}/edit/${page}`);
  };

  getDiscountsById = (id, page) => {
    this.setState({ loading: true });
    axios
      .get(`/businesses/${id}/offers`, {
        params: { page: page ? page : 1 },
      })
      .then((res) => {
        this.setState({
          discounts: res.data.results,
          totalResults: res.data.totalResults,
          loading: false,
          discountErrors: {},
        });
      })
      .catch((err) => {
        this.setState({ discountErrors: err.response.data, loading: false });
      });
  };

  getBusinessesbyId = (id) => {
    axios
      .get(`/businesses/${id}`)
      .then((res) => {
        this.updateState(res.data);
      })
      .catch((err) => {
        this.setState({ errors: err.response });
      });
  };

  getBusinessesUserCashbackSplitageById = async (id) => {
    try {
      const res = await axios.get(`/businesses/${id}/user-cashback-splitage`);
      const userCashbackPercentage = normalizeFloatNullable(
        res.data.userPercentage
      );
      this.setState({
        userCashbackPercentage,
        originalPercentage: userCashbackPercentage,
        hasBusinessOverride: res.data.userPercentage != null,
      });
    } catch (err) {
      this.setState({
        userCashbackPercentage: null,
        originalPercentage: null,
        errors: {
          ...this.state.errors,
          userCashbackPercentage:
            'Error getting business wide user cashback splitage',
        },
      });
      console.log(err);
    }
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
    this.props.history.replace(
      `/dashboard/businesses/${this.props.match.params.id}/edit/${
        newValue ? newValue : ''
      }`
    );
  };

  loadAddressOptions = async (inputValue) => {
    const url = `/locations/autocomplete`;
    const params = { params: { input: inputValue } };

    try {
      const res = await axios.get(url, params);
      const addresses = res.data.map((el) => ({
        value: el.place_id,
        label: `${el.address}, ${el.additionalAddress}`,
        ...el,
      }));
      this.setState({
        addressList: addresses,
      });
      return addresses;
    } catch (err) {
      this.setState({ errors: err.response.data });
    }
  };
  fetchPlaceData = async (address) => {
    const url = `/locations/${address.place_id}/details`;
    try {
      const res = await axios.get(url);
      this.setState({
        address: {
          label: address.address,
          value: address.place_id,
          ...res.data,
        },
        city: res.data.city ? res.data.city : '',
        country: res.data.country ? res.data.country : '',
        province: res.data.province ? res.data.province : '',
        postalCode: res.data.postalCode ? res.data.postalCode : '',
        lng: res.data.lng || null,
        lat: res.data.lat || null,
      });
    } catch (error) {
      console.error(error);
      this.setState({ addressErrors: error.response.data });
    }
  };

  handleAddressSelect = (address) => {
    this.fetchPlaceData(address);
  };

  handleSelectAffiliateProgram = (event) => {
    this.setState({ affiliateProgramId: event.target.value });
  };

  handleSelectAffiliateProgramOnEdit = (event) => {
    this.setState({ tempAffiliateProgramId: event.target.value });
  };

  onChangeAdvertId = (e) => {
    this.setState({ advertisingId: e.target.value });
  };

  handleDelete = async () => {
    const { id } = this.props.match.params;
    try {
      await axios.delete(`/businesses/${id}`);
      this.props.history.push('/dashboard/businesses');
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  handleDeleteBtn = () => {
    if (this.state.deleteMode) {
      return (
        <Stack direction="row" spacing={1}>
          <Button variant="contained" color="error" onClick={this.handleDelete}>
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => this.setState({ deleteMode: false })}
          >
            Cancel
          </Button>
        </Stack>
      );
    }
    return (
      <Button
        variant="contained"
        color="error"
        onClick={() => this.setState({ deleteMode: true })}
      >
        Delete
      </Button>
    );
  };

  handleLocalBusinessTypeSelect = (event) => {
    this.setState({ localBusinessType: event.target.value });
  };

  renderSelectOwnerIdIfBusinessIsLocal = () => {
    const { isAffiliate, ownerId, usersList, userText, errors } = this.state;
    if (!isAffiliate)
      return (
        <Autocomplete
          clearText={''}
          value={ownerId}
          onChange={(event, newValue) => {
            this.setState({ ownerId: newValue });
          }}
          inputValue={userText}
          onInputChange={(event, newInputValue) => {
            this.setState({ userText: newInputValue });
            this.debounceFetchUsers(newInputValue);
          }}
          id="combo-box-user-list"
          options={usersList}
          sx={{ flex: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Owner"
              placeholder="Search..."
              error={!!errors.ownerId}
              helperText={errors.ownerId}
            />
          )}
        />
      );
  };

  renderSelectBusinessTypeIfBusinessIsLocal = () => {
    const { isAffiliate, localBusinessType, errors } = this.state;
    const options = ['Physical', 'Online only'];
    const localBusinessTypeOptions = options.map((el) => ({
      value: el,
      label: el,
    }));

    if (!isAffiliate)
      return (
        <FormControl fullWidth>
          <InputLabel id="localBusinessType-select-label">
            Local Business Type
          </InputLabel>
          <Select
            labelId="localBusinessType-select-label"
            name="localBusinessType"
            value={localBusinessType}
            label="Local Business Type"
            onChange={this.handleLocalBusinessTypeSelect}
            error={!!errors.localBusinessType}
          >
            {localBusinessTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.localBusinessType}</FormHelperText>
        </FormControl>
      );
  };

  renderCashbackValueConditionally = () => {
    const { type, cashbackValue, errors } = this.state;
    if (['National', 'Regional', 'Municipal'].includes(type)) {
      return (
        <CashbackField
          label="Cashback Value"
          value={cashbackValue}
          errorMessage={errors.cashbackValue}
          handleChange={(value) => {
            this.setState({ cashbackValue: value });
          }}
        />
      );
    }
  };

  renderDutyFreeSelectorConditionally = () => {
    const { type, dutyFree, loading } = this.state;
    if (!['National', 'Regional', 'Municipal'].includes(type)) {
      return (
        <div>
          <label className="label" htmlFor="isDutyFree">
            Is Duty-Free
          </label>
          <Switch
            inputProps={{ id: 'isDutyFree' }}
            size="small"
            color="primary"
            checked={dutyFree}
            onChange={(event) => {
              this.setState({ dutyFree: event.target.checked });
            }}
            disabled={loading}
          ></Switch>
        </div>
      );
    }
  };

  renderAddressFieldsIfLocalBusinessTypeIsPhysical = () => {
    const {
      appartment,
      addressText,
      addressList,
      city,
      province,
      postalCode,
      country,
      isAffiliate,
      localBusinessType,
      address,
      errors,
    } = this.state;
    if (!isAffiliate && localBusinessType === 'Physical') {
      return (
        <>
          <Autocomplete
            clearText={''}
            value={address}
            onChange={(event, newValue) => {
              this.handleAddressSelect(newValue);
            }}
            inputValue={addressText}
            onInputChange={(event, newInputValue) => {
              this.setState({ addressText: newInputValue });
              this.debounceFetchAddresses(newInputValue);
            }}
            id="combo-box-address-list"
            options={addressList}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                placeholder="Search..."
                required
                error={!!errors.address}
                helperText={errors.address}
              />
            )}
          />

          <TextField
            name="appartment"
            value={appartment}
            label="Appartment, unit, suite or floor"
            onChange={this.handleTextField}
            error={!!errors.appartment}
            helperText={errors.appartment}
          />
          <TextField
            name="city"
            value={city}
            label="City"
            onChange={this.handleTextField}
            error={!!errors.city}
            helperText={errors.city}
          />
          <TextField
            name="province"
            value={province}
            label="Province"
            onChange={this.handleTextField}
            error={!!errors.province}
            helperText={errors.province}
          />
          <TextField
            name="postalCode"
            value={postalCode}
            label="Postal Code"
            onChange={this.handleTextField}
            error={!!errors.postalCode}
            helperText={errors.postalCode}
          />
          <TextField
            value={country}
            name="country"
            label="Country"
            onChange={this.handleTextField}
            error={!!errors.country}
            helperText={errors.country}
          />
        </>
      );
    }
  };

  renderUserPercentageConditionally = () => {
    const {
      type,
      userCashbackPercentage,
      systemWideCashbackUserSplitPercentage,
      hasBusinessOverride,
      cashbackSplitOptionError,
      errors,
    } = this.state;
    if (!['National', 'Regional', 'Municipal'].includes(type)) {
      return (
        <BusinessCashbackSplitage
          userCashbackPercentage={userCashbackPercentage}
          systemWideCashbackUserSplitPercentage={
            systemWideCashbackUserSplitPercentage
          }
          hasBusinessOverride={hasBusinessOverride}
          onStateChange={this.onCashbackSplitageChange}
          cashbackSplitOptionError={cashbackSplitOptionError}
          errors={errors}
        />
      );
    }
  };

  renderCashbackSchedulerTabConditionally = () => {
    const { type } = this.state;
    if (!['National', 'Regional', 'Municipal'].includes(type)) {
      return <Tab label="Cashback Split Scheduler" {...a11yProps(2)} />;
    }
  };

  render() {
    const {
      label,
      tab,
      errors,
      loading,
      en,
      fr,
      type,
      selectedTab,
      published,
      startDate,
      endDate,
      thumbnail,
      thumbnail_fr,
      advertisingId,
      affiliateProgram,
      affiliateProgramId,
      affiliateProgramOptions,
      editAffiliate,
      tempAffiliateProgramId,
    } = this.state;

    const { mode, id } = this.props.match.params;

    let nameErr;
    let descriptionErr;
    let excerptErr;
    let websiteErr;

    if (!isEmpty(errors)) {
      nameErr = tab === 'fr' ? errors.name_fr : errors.name;
      descriptionErr =
        tab === 'fr' ? errors.description_fr : errors.description;
      excerptErr = tab === 'fr' ? errors.excerpt_fr : errors.excerpt;
      websiteErr = tab === 'fr' ? errors.website_fr : errors.website;
    }

    if (!loading) {
      return (
        <div className={css.businessContainer}>
          <Header
            title={
              mode === 'add'
                ? 'New Business'
                : tab === 'en'
                ? en.name
                : fr.name
                ? fr.name
                : en.name
            }
            btnText={selectedTab === 1 ? 'New Discount' : ''}
            btnLink
            url={selectedTab === 1 ? `/dashboard/discounts/${id}/list/` : ''}
          />

          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />

          {mode === 'edit' ? (
            <Tabs value={selectedTab} onChange={this.handleChangeTab}>
              <Tab label="Business details" {...a11yProps(0)} />
              <Tab label="Discounts" {...a11yProps(1)} />
              {this.renderCashbackSchedulerTabConditionally()}
              <Tab label="Settings" {...a11yProps(3)} />
            </Tabs>
          ) : null}

          <TabPanel value={selectedTab} index={0}>
            <Stack direction="column" spacing={2}>
              <TextField
                value={advertisingId}
                name="advertisingId"
                label="Advertising Id"
                placeholder="Advertising Id"
                required
                className="input-lg"
                onChange={this.onChangeAdvertId}
                error={!!errors.advertisingId}
                helperText={errors.advertisingId}
              />
              {mode !== 'edit' ? (
                <FormControl fullWidth>
                  <InputLabel id="affiliateProgramId-select-label">
                    Affiliate Program ID
                  </InputLabel>
                  <Select
                    labelId="affiliateProgramId-select-label"
                    name="affiliateProgramId"
                    value={affiliateProgramId}
                    label="Affiliate Program ID"
                    onChange={this.handleSelectAffiliateProgram}
                    error={!!errors.affiliateProgramId}
                  >
                    {affiliateProgramOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.affiliateProgramId}
                  </FormHelperText>
                </FormControl>
              ) : null}
              <TextField
                value={tab === 'en' ? en.name : fr.name}
                name="name"
                label="Business name"
                placeholder="Business name"
                required={tab === 'en'}
                className="input-lg"
                onChange={this.onChange}
                error={!!nameErr}
                helperText={nameErr}
              />

              <TextField
                value={label}
                name="label"
                label="Business Label"
                placeholder="Business label"
                required
                className="input-lg"
                onChange={this.handleTextField}
                error={!!errors.label}
                helperText={errors.label}
              />

              <TextField
                value={tab === 'en' ? en.excerpt : fr.excerpt}
                name="excerpt"
                label={`Business Excerpt (${tab})`}
                placeholder="Short Description"
                required
                className="input-lg"
                onChange={this.onChange}
                error={!!excerptErr}
                helperText={excerptErr}
              />

              <TextField
                value={tab === 'en' ? en.website : fr.website}
                name="website"
                label={`Business Website (${tab})`}
                placeholder="Website"
                className="input-lg"
                onChange={this.onChange}
                error={!!websiteErr}
                helperText={websiteErr}
              />

              <FormControl fullWidth>
                <InputLabel id="type-select-label">Business type</InputLabel>
                <Select
                  labelId="type-select-label"
                  name="BusinessType"
                  value={type}
                  label="Business type"
                  onChange={this.handleSelectAffiliate}
                  error={!!errors.type}
                >
                  {affiliateOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.type}</FormHelperText>
              </FormControl>

              {this.renderSelectOwnerIdIfBusinessIsLocal()}
              {this.renderSelectBusinessTypeIfBusinessIsLocal()}
              {this.renderCashbackValueConditionally()}
              {this.renderDutyFreeSelectorConditionally()}
              {this.renderAddressFieldsIfLocalBusinessTypeIsPhysical()}

              <MobileDatePicker
                name="startDate"
                value={startDate}
                label="Start date"
                onChange={this.handleStartDate}
                error={errors.startDate}
                maxDate={endDate}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />
              <MobileDatePicker
                name="endDate"
                value={endDate}
                label="End date"
                onChange={this.handleEndDate}
                error={errors.endDate}
                minDate={moment()}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />

              <FormControl fullWidth>
                <InputLabel id="published-select-label">
                  Published status
                </InputLabel>
                <Select
                  labelId="published-select-label"
                  name="PublishedStatus"
                  value={published}
                  label="Published status"
                  onChange={this.handleSelectPublished}
                  error={!!errors.published}
                >
                  {publishedOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.published}</FormHelperText>
              </FormControl>

              <Wysiwyg
                name="description"
                label="Business description"
                val={tab === 'en' ? en.description : fr.description}
                onChange={this.handleWysiwyg}
                error={descriptionErr}
              />

              <ImageSelect
                initialImage={thumbnail}
                label="Thumbnail (en)"
                name="thumbnail"
                type="Thumbnail"
                handleSelect={this.handleSelectImage}
                error={errors.thumbnail}
              />
              <ImageSelect
                initialImage={thumbnail_fr}
                label="Thumbnail (fr)"
                name="thumbnail_fr"
                type="Thumbnail"
                handleSelect={this.handleSelectImage}
                error={errors.thumbnail_fr}
              />

              {this.renderUserPercentageConditionally()}

              <div className="btn_appart">
                <Button variant="contained" onClick={this.handleSave}>
                  {mode === 'edit' ? 'Save' : 'Create Business'}
                </Button>
                {mode === 'edit' ? this.handleDeleteBtn() : null}
              </div>

              {!isEmpty(errors) ? (
                <span ref={this.myRef} className="error_msg">
                  There's an error, please check your input.
                </span>
              ) : null}
            </Stack>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <div>
              <BusinessDiscountsList
                discounts={this.state.discounts}
                errors={this.state.discountErrors}
                loading={loading}
                businessId={id}
              />
              {this.state.totalResults ? (
                <Pagination
                  totalRecords={this.state.totalResults}
                  page={this.state.page}
                  onPageChange={this.changePage}
                />
              ) : null}
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <BusinessCashbackScheduler
              businessId={this.props.match.params.id}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Stack direction="row" spacing={2} alignItems="baseline">
              {!editAffiliate ? (
                <>
                  <div>{affiliateProgram?.label}</div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({ editAffiliate: true });
                    }}
                  >
                    Change Affiliate
                  </Button>
                </>
              ) : (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="affiliateProgramId-select-label">
                      Affiliate Program ID
                    </InputLabel>
                    <Select
                      labelId="affiliateProgramId-select-label"
                      name="affiliateProgramId"
                      value={tempAffiliateProgramId}
                      label="Affiliate Program ID"
                      onChange={this.handleSelectAffiliateProgramOnEdit}
                    >
                      {affiliateProgramOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CustomButton
                    confirmationText="Confirm affiliate program update for this business?"
                    disabled={
                      !tempAffiliateProgramId ||
                      tempAffiliateProgramId === affiliateProgramId
                    }
                    onClick={this.handleUpdateAffiliate}
                    needConfirm
                    text="Save"
                  />
                </>
              )}
            </Stack>
          </TabPanel>
        </div>
      );
    }
    if (loading) return <Spinner />;
  }
}

export default withRouter(Business);

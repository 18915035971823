import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

const typeOptions = [
  'Any text',
  'Alphanumeric and symbols',
  'Letters and symbols',
  'Numeric',
  'Email',
  'Phone Number',
  'Canadian Postal',
];

function Text({ grabQuestionData, data }) {
  const [questionData, setQuestionData] = useState({
    maxResponseLength: 100,
    validator: 'Alphanumeric and symbols',
  });

  const { maxResponseLength, validator } = questionData;

  useEffect(() => {
    setQuestionData({
      maxResponseLength: data.maxResponseLength ? data.maxResponseLength : 100,
      validator: data.validator ? data.validator : 'Any text',
    });
  }, [data.maxResponseLength, data.validator]);

  useEffect(() => {
    const data = {
      maxResponseLength,
      validator: validator === 'Any text' ? null : validator,
    };
    grabQuestionData(data);
  }, [maxResponseLength, validator, grabQuestionData]);

  const handleSelect = (e) => {
    setQuestionData({ ...questionData, validator: e.target.value });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value > 0 && value.length <= 4) {
      setQuestionData({
        ...questionData,
        maxResponseLength: value,
      });
    }
  };

  return (
    <Stack spacing={2} sx={{ mt: 3 }}>
      <TextField
        size="small"
        label="Response Length"
        type="number"
        value={maxResponseLength}
        name="maxResponseLength"
        inputProps={{ min: 1 }}
        onChange={handleChange}
      />
      <FormControl size="small" fullWidth sx={{ mt: 2 }}>
        <InputLabel id="validator-select-label">Response Type</InputLabel>
        <Select
          labelId="validator-select-label"
          name="validator"
          value={validator}
          label="Response Type"
          onChange={handleSelect}
        >
          {typeOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

Text.propTypes = {};

export default Text;

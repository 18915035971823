import React from "react";

export const UserListHeader = () => {
  return (
    <li className="header">
      <div className="list-inner">
        <div className="name">Name</div>
        <div className="email">Email</div>
        <div className="role">Role</div>
        <div className="button-container">Action</div>
      </div>
    </li>
  );
};

export default UserListHeader;

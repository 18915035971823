import React from "react";
import PropTypes from "prop-types";
import PollListItem from "./PollListItem";
import Spinner from "../../Inputs/Spinner";
import PollListHeader from "./PollListHeader";
import { isEmpty } from "../../../utils";

const PollList = ({ polls, loading, errors }) => {
  const list = isEmpty(errors) ? (
    polls.map((poll) => <PollListItem key={poll.id} poll={poll} />)
  ) : (
    <li>{errors.message}</li>
  );

  return (
    <div className="list-group">
      <ul>
        <PollListHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

PollList.propTypes = {
  polls: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

PollList.defaultProps = {
  polls: [],
};

export default PollList;

import React from 'react';
import PropTypes from 'prop-types';
import Pages from './Pages/Pages';

export const Dashboard = () => {
  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;

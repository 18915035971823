import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Module from './Module';

const BuilderItem = ({
  alignment,
  id,
  index,
  type,
  size,
  text,
  text_fr,
  title,
  title_fr,
  pollId,
  entity,
  variety,
  limit,
  url,
  layout,
  businesses,
  orientation,
  item,
  item_fr,
  mediaItem,
  mediaItem_fr,
  items,
  discounts,
  images,
  image,
  image_fr,
  unit,
  inBasket,
  topSpacing,
  bottomSpacing,
  error,
  clearModuleError,
  deleteItemFromBasket,
  addListItem,
  moveListItem,
  updateBasket,
}) => {
  const ref = useRef(null);
  const checkDroppable = (item, monitor) => {
    if (!ref.current) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = ref.current?.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
    if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
    return { dragIndex, hoverIndex };
  };
  const [{ isDragging }, dragRef] = useDrag({
    type: 'component',
    item: () => ({ inBasket, id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, dropRef] = useDrop({
    accept: 'component',
    hover: (item, monitor) => {
      const droppable = checkDroppable(item, monitor);
      if (droppable && item.inBasket) {
        moveListItem(droppable.dragIndex, droppable.hoverIndex);
        item.index = droppable.hoverIndex;
      }
    },
    drop: (item, monitor) => {
      const droppable = checkDroppable(item, monitor);
      if (droppable && !item.inBasket) {
        addListItem(droppable.hoverIndex, item);
      }
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  dragRef(dropRef(ref));

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Module
        index={index}
        data={{
          alignment,
          id,
          type,
          inBasket,
          topSpacing,
          bottomSpacing,
          text,
          text_fr,
		  title,
		  title_fr,
          size,
          pollId,
          entity,
          variety,
          item,
          item_fr,
          mediaItem,
          mediaItem_fr,
          items,
          discounts,
          images,
          image,
          image_fr,
          unit,
          limit,
          url,
          layout,
          businesses,
          orientation,
        }}
        clearModuleError={clearModuleError}
        error={error}
        deleteItemFromBasket={deleteItemFromBasket}
        updateBasket={updateBasket}
      />
    </div>
  );
};

export default BuilderItem;

import React, { Component } from 'react';
import { Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../../Inputs/Button';

export class PageSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  render() {
    const { name } = this.props.search;
    const { handleSearch } = this.props;

    return (
      <Stack
        component="form"
        direction="row"
        spacing={1}
        sx={{ mb: 4 }}
        onSubmit={(e) => handleSearch(e, name)}
      >
        <TextField
          name="name"
          value={name}
          label="Search"
          onChange={this.handleChange}
          inputProps={{ maxLength: 30 }}
          size="small"
        />
        <Button text="Search" type="submit" />
      </Stack>
    );
  }
}

PageSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default PageSearch;

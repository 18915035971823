import React from "react";
import PropTypes from "prop-types";

function ListItem({ item, ent }) {
  return (
    <li>
      {item.name} {React.cloneElement(ent, { data: item })}
    </li>
  );
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ListItem;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Inputs/Button';
import axios from 'axios';
import { isEmpty } from '../../../../utils';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';

import style from './DropdownItem.module.scss';

export class DropdownItem extends Component {
  constructor() {
    super();
    this.dialogRef = React.createRef();
    this.state = {
      name: '',
      createMode: false,
      deleteMode: false,
      dialogId: '',
      dialogOpen: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (isEmpty(data)) {
      this.setState({ createMode: true });
    } else {
      this.setState({ name: data.name });
    }
  }

  setDeleteMode = (mod) => {
    this.setState({ deleteMode: mod });
  };

  handleCancel = (dialog) => {
    const { createMode } = this.state;
    const { data } = this.props;
    if (createMode) {
      this.setState({ name: '', errors: {} });
    } else {
      this.setState({ name: data.name, errors: {} });
    }
    this.closeDialog();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { createMode, deleteMode, name } = this.state;
    const { data, getData, tab } = this.props;

    const payload = { name };
    let path = createMode ? `/${tab.value}` : `/${tab.value}/${data.id}`;
    let method = 'put';
    if (createMode) method = 'post';
    if (deleteMode) method = 'delete';

    try {
      await axios[method](path, payload);
      getData();
      this.closeDialog();
      if (method === 'post') this.setState({ name: '', errors: {} });
      this.setState({ errors: {} });
    } catch (err) {
      this.setState({ errors: err.response.data });
    }
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };
  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { createMode, deleteMode, name, errors, dialogOpen } = this.state;
    const { data, tab } = this.props;

    let dialogHeader = `New ${tab.label}`;
    let dialog = this.dialogRef.current;

    let button = (
      <Button
        text={`New ${tab.label}`}
        onClick={this.openDialog}
        variant="text"
      />
    );
    let submitBtnText = 'Add';

    if (!createMode && !isEmpty(data)) {
      button = (
        <i title="edit" className="far fa-edit" onClick={this.openDialog}></i>
      );
      submitBtnText = 'Save';
      dialogHeader = `Edit`;
    }

    let buttons = (
      <Stack direction="row" spacing={2}>
        <Button
          onClick={(e) => this.handleSubmit(e, dialog)}
          text={submitBtnText}
        />
        <Button
          text="Delete"
          onClick={() => this.setDeleteMode(true)}
          color="error"
        />
      </Stack>
    );

    if (deleteMode) {
      dialogHeader = 'Confirm deletion';
      buttons = (
        <Stack direction="row" spacing={2}>
          <Button
            onClick={(e) => this.handleSubmit(e, dialog)}
            text={'Delete'}
            color="error"
          />
          <Button
            text="Cancel"
            onClick={() => this.setDeleteMode(false)}
            variant="text"
          />
        </Stack>
      );
    }

    return (
      <div>
        {button}

        <Dialog
          open={dialogOpen}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          disableBackdropClick
        >
          <DialogTitle>
            <div className={style.dialog_header}>
              <h3>{dialogHeader}</h3>
              <i
                onClick={() => this.handleCancel(dialog)}
                className="fas fa-times closeDialog"
              ></i>
            </div>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ minWidth: 300, mt: 2 }}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                value={name}
                onChange={this.handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {createMode ? (
              <Button
                onClick={(e) => this.handleSubmit(e)}
                text={submitBtnText}
              />
            ) : (
              buttons
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DropdownItem.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired,
};

export default DropdownItem;

import { MobileDatePicker } from '@mui/lab';
import { Autocomplete, Button, TextField } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchUsers } from '../../utils/fetchUsers';
import styled from 'styled-components';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import List from './List';

export default function UserActivitySummary({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [unionOptions, setUnionOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchUnions();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [, setPage] = useState(1);
  const [userFilter, setUserFilter] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'username', asc: true });
  const [business, setBusiness] = useState([]);
  const [union, setUnion] = useState([]);
  const [local, setLocal] = useState('');
  const [activityType, setActivityType] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    setPage(page);
    history.push(`/dashboard/user-activity-summary/${page}`);
  };

  const fetchBusinesses = async () => {
    axios
      .get(`/businesses/dropdown`)
      .then((res) => {
        setBusinessOptions(res.data);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };
  const fetchUnions = async () => {
    axios
      .get(`/unions`, { params: { dropdown: true } })
      .then((res) => {
        setUnionOptions(
          res.data.map((u) => {
            return { label: u.name, value: u.id };
          })
        );
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        const [error, userOptions] = await fetchUsers(request.input);
        if (!error) {
          setUserOptions(userOptions);
        }
      }, 1000),
    []
  );

  useEffect(() => {
    fetch({ input: userSearch });
  }, [userSearch, fetch]);

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const fetchData = useCallback(
    async (page = 1, csv = false) => {
      const url = `/reports/userActivitySummary`;
      const options = {
        page: page,
        csv: csv,
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        business: business && business.map((el) => el.value).toString(),
        union: union && union.map((el) => el.value).toString(),
        local: local,
        activity: activityType && activityType.map((el) => el.value).toString(),
        user: userFilter && userFilter.map((el) => el.value).toString(),
        order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
      };
      if (page === 1) history.push(`/dashboard/user-activity-summary/1`);
      setErrors({});
      setLoading(true);
      try {
        const res = await axios.get(url, { params: options });

        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `user-activity-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setTransactionData(res.data.rows);
          setTotalResults(res.data.count);
        }
        setLoading(false);
      } catch (error) {
        setErrors(error.response.data);
        setLoading(false);
        setTotalResults(0);
      }
    },
    [
      activityType,
      business,
      endDate,
      history,
      local,
      sortBy.asc,
      sortBy.field,
      startDate,
      union,
      userFilter,
    ]
  );

  useEffect(() => {
    fetchData();
    setPage(1);
  }, [userFilter, startDate, endDate, local, fetchData]);

  const activityOptions = [
    { label: 'Sale', value: 'affiliate_sale' },
    { label: 'Lead', value: 'affiliate_lead' },
    { label: 'Click', value: 'affiliate_click' },
    { label: 'Bonus', value: 'affiliate_bonus' },
  ];

  const columns = [
    { label: 'User name', name: 'username' },
    { label: 'Union', name: 'unionName' },
    { label: 'Local', name: 'local' },
    { label: 'Total Spend', name: 'totalSpend' },
    { label: 'Total Commission', name: 'totalComission' },
    { label: 'User Commission', name: 'userCommision' },
    { label: 'UPP Commission', name: 'uppCommision' },
    { label: 'Number of leads', name: 'leads' },
    { label: 'Number of purchases', name: 'sales' },
    { label: 'Total user referrals', name: 'referralCount' },
    { label: 'Total business referrals', name: 'businessReferralCount' },
    { label: 'Registration Date', name: 'registrationDate', type: 'date' },
    { label: 'Number of UPP visits', name: 'uppVisits' },
    { label: 'Number of UPP page Hits/Clicks', name: 'uppClicksHits' },
  ];

  return (
    <div>
      <h1>User Summary Dashboard</h1>

      <Controls>
        <Autocomplete
          size="small"
          sx={{ mr: 1, mb: 2, width: 200 }}
          value={userFilter}
          multiple
          onChange={(event, newValue) => {
            setUserFilter(newValue);
          }}
          inputValue={userSearch}
          onInputChange={(event, newInputValue) => {
            setUserSearch(newInputValue);
          }}
          id="combo-box-user-list"
          options={userOptions}
          renderInput={(params) => (
            <TextField {...params} label="User" placeholder="Search..." />
          )}
        />
        <MobileDatePicker
          name="startDate"
          value={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          maxDate={endDate}
          clearable
          renderInput={(params) => (
            <TextField {...params} size="small" sx={{ mr: 1 }} />
          )}
        />
        <MobileDatePicker
          name="endDate"
          value={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          minDate={startDate}
          clearable
          renderInput={(params) => (
            <TextField {...params} size="small" sx={{ mr: 1 }} />
          )}
        />
        <Autocomplete
          sx={{ mr: 1, width: 200 }}
          multiple
          id="business-select-label"
          options={businessOptions}
          value={business}
          onChange={(event, newValue) => {
            setBusiness(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Business" />}
        />
        <Autocomplete
          sx={{ mr: 1, width: 200 }}
          multiple
          id="union-select-label"
          options={unionOptions}
          value={union}
          onChange={(event, newValue) => {
            setUnion(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Union" />}
        />
        <TextField
          sx={{ mr: 1 }}
          size="small"
          name="Local"
          label="Local"
          value={local}
          onChange={(v) => setLocal(v.target.value)}
          inputProps={{ maxLength: 100 }}
        />
        <Autocomplete
          sx={{ mr: 1, width: 200 }}
          multiple
          id="activityType-select-label"
          options={activityOptions}
          value={activityType}
          onChange={(event, newValue) => {
            setActivityType(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Activity Type" />
          )}
          error={errors.activityType}
        />
      </Controls>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
      />
      {renderPagination()}
    </div>
  );
}

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;

  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const Spinner = ({ size }) => {
  return (
    <CircularProgress size={size} />
  );
};

Spinner.propTypes = {
  size: PropTypes.number
};

Spinner.defaultProps = {
  size: 30
};

export default Spinner;

import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Spinner from '../../Inputs/Spinner';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const statusFilterOptions = [
  'Pending',
  'Approved',
  'Rejected',
  'Subscribed',
  'Cancelled',
];

const UpdateSubscription = ({
  subId,
  options,
  setDialogOpen,
  getSubscriptionData,
  subStatus,
  businessId,
  fetchData,
  page,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState({
    status: '',
    business: '',
  });
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    fetchBusinessList();
  }, []);

  useEffect(() => {
    setFormData({
      status: subStatus,
      business: businessId,
    });
  }, [businessId, subStatus]);

  const fetchBusinessList = async () => {
    const url = '/businesses/dropdown';

    try {
      const res = await axios.get(url, { params: { type: 'local' } });
      setBusinesses(res.data);
    } catch (err) {
      console.log('Error fetching user list.');
    }
  };

  const { status, business } = formData;

  const handleSave = async () => {
    setLoading(true);
    const url = `/subscriptions/${subId}`;
    const payload = {
      businessId: business,
      status,
    };
    try {
      await axios.put(url, payload);
      setLoading(false);
      getSubscriptionData();
      fetchData(page, false);
      setDialogOpen(false);
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      setErrors(err.response.data);
    }
  };

  if (loading) return <Spinner />;

  return (
    <SelectWrapper>
      <SelectWrapperInner>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="status-select-label" shrink>
            Status
          </InputLabel>
          <Select
            onChange={(event) => {
              setFormData({ ...formData, status: event.target.value });
            }}
            labelId="status-select-label"
            name="status"
            label="Status"
            value={status}
            error={errors && !!errors.status}
            helperText={errors && errors.status}
          >
            {statusFilterOptions.map((op) => (
              <MenuItem key={op} value={op}>
                {op}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="businessId-select-label" shrink>
            Business ID
          </InputLabel>
          <Select
            onChange={(event) => {
              setFormData({ ...formData, business: event.target.value });
            }}
            displayEmpty
            labelId="businessId-select-label"
            name="businessId"
            label="Business ID"
            value={business}
            error={errors && !!errors.businessId}
            helperText={errors && errors.businessId}
          >
            {businesses.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectWrapperInner>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </SelectWrapper>
  );
};

const Row = ({
  field,
  value,
  dialog,
  dialogTitle,
  type,
  id,
  direction = 'row',
}) => {
  return (
    <RowWrapper direction={direction}>
      <FieldName>{field}</FieldName>
      <FieldValue direction={direction}>{value}</FieldValue>
    </RowWrapper>
  );
};

const ViewSubscription = ({
  id,
  setParentDialogOpen,
  fetchData,
  page,
  categories,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [errors, setErrors] = useState({});
  const [types, setTypes] = useState([]);
  const {
    name,
    status,
    isNumbered,
    number,
    started,
    taxNumberBN,
    taxNumberID,
    taxNumberREF,
    address,
    website,
    phone,
    contactPerson,
    contactPersonPhone,
    subcategories,
    facebook,
    instagram,
    twitter,
    plan,
    email,
    emails,
    signupDate,
    nextPaymentDate,
    lastPaymentDate,
    business,
    businessId,
    logo,
    referralMethod,
    referrerId,
    offers,
    description,
  } = subscriptionData;

  const socials = [
    {
      name: 'Facebook',
      link: facebook,
      icon: <i className="fab fa-facebook"></i>,
    },
    {
      name: 'Instagram',
      link: instagram,
      icon: <i className="fab fa-instagram"></i>,
    },
    {
      name: 'Twitter',
      link: twitter,
      icon: <i className="fab fa-twitter"></i>,
    },
  ];

  const getSingleSubscriptionData = useCallback(async () => {
    const url = `/subscriptions/${id}`;
    try {
      const res = await axios.get(url);
      setSubscriptionData(res.data);
      setLoading(false);
    } catch (err) {
      setErrors(err.response.data);
      setLoading(false);
    }
  }, [id]);

  const businessLogo = (image) => {
    return (
      <img
        style={{ maxWidth: '100px', maxHeight: '100px' }}
        src={image}
        alt="business logo"
      />
    );
  };

  const initializeTypes = useCallback(() => {
    categories?.map((category) =>
      setTypes((prevState) => [
        ...prevState,
        { name: category.name, elements: [] },
      ])
    );
  }, [categories]);

  const updateTypes = useCallback(() => {
    types?.forEach((type) => {
      subcategories?.forEach((subcategory) => {
        const { parent } = subcategory;
        if (type.name === parent.name) {
          const indexOfType = types?.findIndex(
            (type) => type.name === parent.name
          );

          const newTypes = [...types];
          const newType = newTypes[indexOfType];

          const elementExist = newType.elements.filter(
            (element) => element === subcategory.name
          );

          if (elementExist.length === 0) {
            newType.elements.push(subcategory.name);
            newTypes[indexOfType] = newType;
            setTypes(newTypes);
          }
        }
      });
    });
  }, [subcategories, types]);

  const renderTypeOfBusiness = () =>
    types?.map((type) => {
      if (type.elements.length > 0) {
        return (
          <BusinessType key={type.name}>
            <h5>{type.name}</h5>
            {type.elements.map((element) => (
              <div key={`type-element-${type.name}-${element}`}>
                - {element}
              </div>
            ))}
          </BusinessType>
        );
      }
      return null;
    });

  useEffect(() => {
    getSingleSubscriptionData();
    initializeTypes();
  }, [getSingleSubscriptionData, initializeTypes]);

  useEffect(() => {
    updateTypes();
  }, [subcategories, updateTypes]);

  if (loading) return <Spinner />;

  return (
    <Container>
      <Content>
        <Row field="Status" value={status} />
        <Row field="Plan" value={plan} />
        <Row
          field="Last Payment on"
          value={
            lastPaymentDate !== null
              ? moment(lastPaymentDate).format('YYYY-DD-MM')
              : ''
          }
        />
        <Row
          field="Next Payment"
          value={
            nextPaymentDate !== null
              ? moment(nextPaymentDate).format('YYYY-DD-MM')
              : ''
          }
        />
        <Row
          field="Signup Date"
          value={moment(signupDate).format('YYYY-DD-MM')}
        />
        <Row field="Legal Business Name" value={name} />
        <Row
          field="Business Description"
          value={description}
          direction="column"
        />
        <Row
          field="Are you a numbered company?"
          value={isNumbered === true ? 'Yes' : 'No'}
        />
        {isNumbered === true && <Row field="Business number" value={number} />}
        <Row field="How long have you been operating" value={started} />
        <Row
          field="Tax ID Number"
          value={`${taxNumberBN} ${taxNumberID}${
            taxNumberREF === 'null' ? '' : taxNumberREF
          }`}
        />
        <Row field="Street Address" value={address} />
        <Row
          field="Website"
          value={
            <a href={website} target="_blank" rel="noreferrer">
              {website}
            </a>
          }
        />
        <Row
          field="Social Media Accounts"
          value={socials.map((social, index) => {
            if (social.link !== '')
              return (
                <SocialLink href={social.link} key={index} target="_blank">
                  {social.icon}
                  {social.name}
                </SocialLink>
              );
            return null;
          })}
        />
        <Row field="Business Phone Number" value={phone} />
        <Row field="Contact Person" value={contactPerson} />
        <Row field="Contact Person Phone Number" value={contactPersonPhone} />
        <Row field="Email" value={email} />
        <Row
          field="Sent Emails"
          value={emails?.map((email, i) => (
            <SentEmail key={i}>
              <h5>Subject:</h5>
              <p>{email.subject}</p>
              <h5>Recepient:</h5>
              <p>{email.recepient}</p>
              <h5>Sent at:</h5>
              <p>
                {moment(email.sentAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </p>
            </SentEmail>
          ))}
          direction="column"
        />
        <Row
          field="Type of Business"
          value={renderTypeOfBusiness()}
          direction="column"
        />
        <Row field="Logo" value={businessLogo(logo)} />
        <Row field="Business label" value={business?.label} />
        <Row field="Which Package are you interested in" value={plan} />
        <Row field="Referral" value={referralMethod} />
        {referralMethod !== 'None' && (
          <Row field="Referral Code" value={referrerId} />
        )}
        <Row
          field="Discounts"
          value={offers.map((el, i) => (
            <Discount key={i}>
              <DiscountName>{el.name}</DiscountName>
              <DiscountImage src={el.image} />
            </Discount>
          ))}
        />
      </Content>
      <DialogActions>
        <Button onClick={() => setParentDialogOpen(false)}>Close</Button>
        <Button onClick={() => setDialogOpen(true)} variant="contained">
          update
        </Button>
      </DialogActions>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Update Subscription</DialogTitle>
        <UpdateSubscription
          subId={id}
          options={statusFilterOptions}
          setDialogOpen={setDialogOpen}
          getSubscriptionData={getSingleSubscriptionData}
          fetchData={fetchData}
          page={page}
          subStatus={status}
          businessId={businessId}
        />
      </Dialog>
    </Container>
  );
};

ViewSubscription.propTypes = {};

const mapStateToProps = (state) => ({
  categories: state.categories.categories.results,
  totalResults: state.categories.categories.totalResults,
  loading: state.categories.loading,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(ViewSubscription));

const SelectWrapper = styled.div`
  width: 500px;
  height: 450px;
  padding: 20px;
`;

const SelectWrapperInner = styled.div`
  height: 320px;
`;

const Container = styled.div`
  width: clamp(650px, 50vw, 2000px);
`;

const Content = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
  }
`;

const FieldName = styled.span`
  display: block;
  font-weight: 600;
  font-size: 18px;
  width: 60%;
  text-align: left;
  overflow: hidden;
  color: #44525a;
`;

const FieldValue = styled.span`
  display: block;
  width: 40%;
  font-size: 16px;
  height: auto;
  line-height: 120%;
  color: #44525a;

  ${(props) => props.direction === 'column' && `width: 100%; margin: 30px 0`}
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props) => props.direction === 'column' && `flex-direction: column;`};
  margin: 15px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #eee;
`;

const SocialLink = styled.a`
  display: block;
  color: #44525a;
  text-decoration: none;
  margin: 10px 0;

  i {
    margin-right: 5px;
  }
`;

const Discount = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const DiscountName = styled.span`
  font-weight: 500;
  white-space: nowrap;
`;

const DiscountImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-left: 20px;
`;

const SentEmail = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #44525a;
  }

  h5 {
    margin: 10px 0 0;
  }

  p {
    margin: 0;
    font-size: 14px;
  }
`;

const BusinessType = styled.div`
  h5 {
    margin: 15px 0 5px;
  }

  div {
    font-size: 14px;
  }
`;

import { GET_POLLS, SET_POLLS_LOADING } from '../types';

const initialState = {
  polls: {},
  loading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_POLLS:
      return {
        polls: payload,
        loading: false
      };
    case SET_POLLS_LOADING:
      return {
        ...state,
        loading: payload
      };

    default:
      return state;
  }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
} from '@mui/material';
import MediaLibrary from '../Dashboard/MediaLibrary/MediaLibrary';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { isEmpty } from '../../utils';
import css from './ImageSelect.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImageSelect({
  initialImage,
  label,
  name,
  type,
  handleSelect,
  handleSelectProduct,
  error,
  clearable,
  handleRemove,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!isEmpty(initialImage) || initialImage === null) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const selectImage = (image) => {
    setImage(image);
    if (handleSelect) {
      handleSelect(image, name);
    }
    if (handleSelectProduct) {
      handleSelectProduct(image);
    }
    setDialogOpen(false);
  };

  const removeImage = (image) => {
    if (image) {
      if (handleRemove) {
        handleRemove(image, name);
      }
      setDialogOpen(false);
    }
  };

  return (
    <div
      style={{ borderBottom: isEmpty(image) ? null : '1px solid #eee' }}
      className={css.container}
    >
      {isEmpty(image) ? null : <h5>{label}</h5>}
      {isEmpty(image) ? null : (
        <img className={css.image_preview} src={image.url} />
      )}
      <Button
        variant={!isEmpty(image) ? 'undefined' : 'outlined'}
        color={!isEmpty(image) ? 'undefined' : 'primary'}
        onClick={() => setDialogOpen(true)}
        startIcon={!isEmpty(image) ? <EditOutlinedIcon /> : null}
      >
        {isEmpty(image) ? `Select ${label}` : image.label}
      </Button>
      {error ? (
        <small className={css.invalid_feedback}>
          <i className="fas fa-times" />
          {error}
        </small>
      ) : null}

      <Dialog
        open={dialogOpen}
        aria-labelledby="form-dialog-title"
        fullScreen
        TransitionComponent={Transition}
      >
        <MediaLibrary grabSelectedImage={selectImage} mediaType={type} />
        <DialogActions>
          {clearable && !isEmpty(image) && (
            <Button onClick={() => removeImage(image)}> Remove Image</Button>
          )}
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ImageSelect.propTypes = {};

export default ImageSelect;

import React from "react";
import PropTypes from "prop-types";
import Button from "../../Inputs/Button";
import moment from "moment";

const DiscountListItem = ({ discount }) => {
  const {id, name, Business, startDate, endDate, createdAt} = discount;

  return (
    <li>
      <div className="list-inner">
        <div className="name">{name}</div>
        <div className="name">{Business ? Business.name : null}</div>
        <div className="date">{moment(startDate ? startDate : createdAt).format("MM-DD-YYYY")}</div>
        <div className="date">{endDate && moment(endDate).format("MM-DD-YYYY")}</div>
        <Button
          text="Edit"
          btnLink
          url={`/dashboard/discounts/${id}/edit/`}
        />
      </div>
    </li>
  );
};

DiscountListItem.propTypes = {
  discount: PropTypes.object.isRequired,
};

export default DiscountListItem;

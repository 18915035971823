import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Inputs/Button';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

const UserSearch = ({ search, handleSearch, handleChange, options }) => {
  const handleSelect = (e) => {
    handleChange({ ...search, role: e.target.value });
  };

  const { role, name } = search;

  return (
    <Stack
      sx={{ mb: 3 }}
      direction="row"
      spacing={2}
      component="form"
      onSubmit={(e) => handleSearch(e)}
    >
      <TextField
        size="small"
        name="search"
        value={name}
        label="Search"
        onChange={(e) =>
          handleChange({
            ...search,
            name: e.target.value,
          })
        }
        inputProps={{ maxLength: 30 }}
      />
      <FormControl size="small" sx={{ minWidth: 150 }}>
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-select-label"
          name="role"
          value={role}
          label="Role"
          onChange={handleSelect}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button text="Search" type="submit" />
    </Stack>
  );
};

export default UserSearch;
UserSearch.propTypes = {
  search: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

UserSearch.defaultProps = {};

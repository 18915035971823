import { MobileDatePicker } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Spinner from '../../Inputs/Spinner';
import css from './BusinessCashbackScheduler.module.scss';

const BusinessCashbackSchedulerEdit = ({ scheduler, onFinish }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [singleDayEvent, setSingleDayEvent] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startDateEditable, setStartDateEditable] = useState();
  const [endDate, setEndDate] = useState();
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [companySharePercentage, setCompanySharePercentage] = useState('100');

  const handleUserShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        const newUserSharePercentage = value ? normalizeFloat(value) : value;
        setUserSharePercentage(newUserSharePercentage);
        if (value === '100' && !companySharePercentage) {
          return;
        }
        setCompanySharePercentage(
          normalizeFloat(100 - parseFloat(value || '0'))
        );
      }
    },
    [companySharePercentage]
  );

  const handleCompanyShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        setCompanySharePercentage(value ? normalizeFloat(value) : value);
        if (value === '100' && !userSharePercentage) {
          return;
        }
        const newUserSharePercentage = normalizeFloat(
          100 - parseFloat(value || '0')
        );
        setUserSharePercentage(newUserSharePercentage);
      }
    },
    [userSharePercentage]
  );

  useEffect(() => {
    setIsLoading(false);
    setError({});
    if (scheduler) {
      const isNew = !scheduler.id;
      if (isNew) {
        setSingleDayEvent(false);
        setStartDate(moment(new Date()).add(1, 'days'));
        setEndDate(moment(new Date()).add(2, 'days'));
        setUserSharePercentage(normalizeFloat(0));
        setCompanySharePercentage(normalizeFloat(100));
      } else {
        if (!scheduler.endDate) {
          setSingleDayEvent(true);
          setEndDate(moment(scheduler.startDate).endOf('day'));
        } else {
          setSingleDayEvent(false);
          setEndDate(moment(scheduler.endDate).endOf('day'));
        }
        setStartDate(moment(scheduler.startDate).startOf('day'));
        setUserSharePercentage(normalizeFloat(scheduler.amount));
        setCompanySharePercentage(
          normalizeFloat(100 - parseFloat(scheduler.amount))
        );
      }
    }
    return () => {};
  }, [scheduler]);
  useEffect(() => {
    if (!startDate) {
      setStartDateEditable(false);
    } else {
      const tomorrow = moment(new Date()).add(1, 'day').startOf('day');
      if (moment(startDate).isBefore(tomorrow)) {
        setStartDateEditable(false);
      } else {
        setStartDateEditable(true);
      }
    }
    return () => {};
  }, [startDate]);
  const handleCloseDialog = useCallback(() => {
    onFinish();
    setError({});
  }, [onFinish]);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const resultingScheduler = { ...scheduler };
    resultingScheduler.startDate = moment(startDate).format('YYYY-MM-DD');
    resultingScheduler.endDate = singleDayEvent
      ? null
      : moment(endDate).format('YYYY-MM-DD');
    resultingScheduler.amount = userSharePercentage || '0';
    setUserSharePercentage(resultingScheduler.amount);
    setCompanySharePercentage(
      normalizeFloat(100 - parseFloat(resultingScheduler.amount))
    );
    const errors = await onFinish(resultingScheduler);
    setError(errors || {});
    setIsLoading(false);
  }, [
    endDate,
    onFinish,
    scheduler,
    singleDayEvent,
    startDate,
    userSharePercentage,
  ]);
  const onSingleDayEventChange = useCallback((event) => {
    setSingleDayEvent(event.target.checked);
  }, []);
  const onStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);
  const onEndDateChange = useCallback((date) => {
    setEndDate(date);
  }, []);

  return (
    <Dialog open={scheduler} maxWidth="lg" disableBackdropClick>
      <DialogTitle>
        <div className={css.dialog_header}>
          <h3>{scheduler?.id ? 'Edit Split' : 'New Split'}</h3>
          <i onClick={handleCloseDialog} className="fas fa-times closeDialog" />
        </div>
      </DialogTitle>
      <div className={css.dialog_inner}>
        <Stack direction="column" spacing={2}>
          <MobileDatePicker
            name="startDate"
            value={startDate}
            label="Start date"
            onChange={onStartDateChange}
            error={error?.startDate}
            disabled={isLoading || !startDateEditable}
            minDate={moment(new Date()).add(1, 'days')}
            renderInput={(params) => <TextField {...params} />}
          />
          <MobileDatePicker
            name="endDate"
            value={endDate}
            label="End date"
            onChange={onEndDateChange}
            error={error?.endDate}
            disabled={singleDayEvent || isLoading}
            minDate={moment(new Date()).add(1, 'days')}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className={classnames('input-cntr', css.dialog_inner_item)}>
            <label className="label" htmlFor="singleDayEvent">
              Single Day Event
            </label>
            <Switch
              inputProps={{ id: 'singleDayEvent' }}
              size="small"
              color="primary"
              checked={singleDayEvent}
              onChange={onSingleDayEventChange}
              disabled={isLoading}
            />
          </div>
          <TextField
            name="userShare"
            value={userSharePercentage}
            type="number"
            label="User Share"
            onChange={handleUserShareChange}
            disabled={isLoading}
            error={error?.amount}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 0.01 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />

          <TextField
            name="companyShare"
            value={companySharePercentage}
            label="Company Share"
            onChange={handleCompanyShareChange}
            type="number"
            disabled={isLoading}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 0.01 },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          {error?.message && <div className="error_msg">{error.message}</div>}
          {error?.businessId && (
            <div className="error_msg">{error.businessId}</div>
          )}
        </Stack>
      </div>
      <DialogActions>
        {isLoading && <Spinner size={20} />}
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={handleCloseDialog} variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BusinessCashbackSchedulerEdit.propTypes = {
  scheduler: PropTypes.object,
  onFinish: PropTypes.func,
};

export { BusinessCashbackSchedulerEdit };

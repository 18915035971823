/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  CLEAR_ERRORS,
  GET_CATEGORIES,
  GET_CATEGORIES_DROPDOWN,
  GET_ERRORS,
  SET_CATEGORY_LOADING,
} from '../types';

export const setLoading =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_CATEGORY_LOADING,
      payload: val,
    });
  };

export const getCategories =
  (page = undefined, dropdown = false) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS,
    });
    dispatch(setLoading(true));

    try {
      const res = await axios.get(`/categories`, {
        params: { page, dropdown },
      });
      dispatch({
        type: dropdown ? GET_CATEGORIES_DROPDOWN : GET_CATEGORIES,
        payload: res.data,
      });
      dispatch(setLoading(false));
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    }
  };

export const addCategory = (name) => async (dispatch) => {
  try {
    await axios.post(`/categories`, { name });
    dispatch(getCategories());
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    dispatch(setLoading(false));
  }
};

export const editCategory = (closeDialog, id, payload) => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
  dispatch(setLoading(true));

  const path = payload.parentId ? `/subcategories/${id}` : `/categories/${id}`;
  try {
    await axios.put(path, payload);
    dispatch(getCategories());
    dispatch(setLoading(false));
    closeDialog();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    dispatch(setLoading(false));
  }
};

export const deleteCategory =
  (closeDialog, id, parentId = null) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS,
    });
    const path = parentId ? `subcategories/${id}` : `categories/${id}`;
    try {
      await axios.delete(`/${path}`);
      dispatch(getCategories());
      closeDialog();
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    }
  };

// Subcategories

export const addSubcategory = (name, parentId) => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  try {
    await axios.post(`/subcategories`, {
      name,
      parentId,
    });
    dispatch(getCategories());
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    dispatch(setLoading(false));
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

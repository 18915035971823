/* eslint-disable react/button-has-type */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';

import style from './Button.module.scss';

const CustomButton = ({
  color,
  size,
  variant,
  text,
  isLoading,
  className,
  disabled,
  type,
  onClick,
  btnLink,
  url,
  needConfirm,
  confirmationText,
}) => {
  const [confirm, setConfirm] = useState(false);

  if (isLoading) return <CircularProgress size={30} />;

  if (btnLink)
    return (
      <Link
        className={classnames(style.btn_link, {
          'is-disabled': disabled,
        })}
        to={url}
      >
        {text}
      </Link>
    );

  if (needConfirm) {
    return (
      <Fragment>
        <Button
          type={type}
          onClick={() => setConfirm(true)}
          disabled={disabled}
          variant={variant}
        >
          {text}
        </Button>
        <Dialog open={confirm} maxWidth="lg" disableBackdropClick>
          <DialogTitle>
            <div className={style.dialog_header}>
              {confirmationText && <h3>{confirmationText}</h3>}
              {!confirmationText && <h3>Confirm deletion?</h3>}
              <i
                onClick={() => setConfirm(false)}
                className="fas fa-times closeDialog"
              ></i>
            </div>
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              type={type}
              onClick={(...args) => {
                setConfirm(false);
                onClick(...args);
              }}
              disabled={disabled}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              type={type}
              onClick={() => setConfirm(false)}
              disabled={disabled}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      color={color}
      variant={variant}
      size={size}
    >
      {text}
    </Button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  btnLink: PropTypes.bool,
  url: PropTypes.string,
  needConfirm: PropTypes.bool,
  confirmationText: PropTypes.string,
};

CustomButton.defaultProps = {
  isLoading: false,
  disabled: false,
  onClick: undefined,
  className: null,
  btnLink: false,
  url: '',
  needConfirm: false,
  color: 'primary',
  size: 'medium',
  variant: 'contained',
};

export default CustomButton;

/* eslint-disable no-case-declarations */
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  SET_CATEGORY_LOADING,
  GET_CATEGORIES_DROPDOWN,
  EDIT_SUBCATEGORY,
} from '../types';

const initalState = {
  categories: [],
  dropdown: [],
  subcategories: [],
  loading: true,
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
      };
    case GET_CATEGORIES_DROPDOWN:
      const subcat = [];
      payload.forEach((cat) => subcat.push(...cat.subcategories));
      return {
        ...state,
        dropdown: payload,
        subcategories: subcat,
        loading: false,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload],
      };
    case EDIT_CATEGORY:
      const newArr = state.categories.results;
      const updateIndex = newArr.findIndex((el) => el.id === payload.id);
      newArr[updateIndex] = payload;
      return {
        ...state,
        categories: {
          ...state.categories,
          results: newArr,
        },
      };
    case EDIT_SUBCATEGORY:
      const categories = state.categories.results;
      const categoryIndex = categories.findIndex(
        (el) => el.id === payload.parent.id
      );
      const subCategoryIndex = categories[
        categoryIndex
      ].subcategories.findIndex((el) => el.id === payload.id);
      categories[categoryIndex].subcategories[subCategoryIndex] = payload;
      return {
        ...state,
        categories: {
          ...state.categories,
          results: categories,
        },
      };
    case SET_CATEGORY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}

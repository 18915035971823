import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Button from '../../Inputs/Button';
import Spinner from '../../Inputs/Spinner';
import css from './BusinessCashbackScheduler.module.scss';
import { BusinessCashbackSchedulerEdit } from './BusinessCashbackSchedulerEdit';

const getTextValue = (column, schedulerEntry) => {
  if (column.virtual) {
    return column.fieldCalculator(schedulerEntry);
  }
  if (column.fieldFormatter) {
    return column.fieldFormatter(schedulerEntry[column.field], schedulerEntry);
  }
  return schedulerEntry[column.field];
};

const SchedulerActions = ({ scheduler, column, onAction }) => {
  const [canDelete, setCanDelete] = useState(true);
  useEffect(() => {
    if (!scheduler || !scheduler.id) {
      setCanDelete(false);
    } else {
      const tomorrow = moment(new Date()).add(1, 'day').startOf('day');
      if (moment(scheduler.startDate).startOf('day').isBefore(tomorrow)) {
        setCanDelete(false);
      } else {
        setCanDelete(true);
      }
    }
    return () => {};
  }, [scheduler]);
  return (
    <div className={css.schedulerActions}>
      <Button
        variant="text"
        onClick={() => onAction(scheduler, column.field, 'edit')}
        text="Edit"
      />
      {canDelete && (
        <Button
          variant="text"
          onClick={() => onAction(scheduler, column.field, 'delete')}
          text="Delete"
          needConfirm
        />
      )}
    </div>
  );
};

const getComponent = (Component, scheduler, column, onAction) => {
  return (
    <Component scheduler={scheduler} column={column} onAction={onAction} />
  );
};

const sortSchedulers = (schedulers) => {
  return [...schedulers].sort((a, b) => {
    if (moment(a.startDate).isBefore(b.startDate)) {
      return -1;
    }
    if (moment(a.startDate).isAfter(b.startDate)) {
      return 1;
    }
    return 0;
  });
};

const BusinessCashbackSchedulerTable = ({ columns, schedulers, onAction }) => {
  return (
    <table className={css.schedulerTable}>
      <thead>
        <tr className={css.header}>
          {columns.map((column) => (
            <th
              className={`${css.headerCell} ${column.headerClassName ?? ''}`}
              key={column.field}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortSchedulers(schedulers).map((scheduler) => (
          <tr key={scheduler.id} className={css.tableBodyRow}>
            {columns.map((column) => (
              <td key={`${scheduler.id}-${column.field}`}>
                {column.component
                  ? getComponent(column.component, scheduler, column, onAction)
                  : getTextValue(column, scheduler)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const dateFormatter = (date) => {
  return moment(date).format('MMM DD, YYYY');
};

const endDateFormatter = (endDate, scheduler) => {
  if (endDate) {
    return dateFormatter(endDate);
  }
  return dateFormatter(scheduler.startDate);
};

const percentageFormatter = (percentage) => {
  return `${normalizeFloat(percentage)}%`;
};

const companyPercentageFormatter = (scheduler) => {
  const userShare =
    typeof scheduler.amount === 'string'
      ? parseFloat(scheduler.amount)
      : scheduler.amount;
  return percentageFormatter(100 - userShare);
};

const columns = [
  {
    field: 'startDate',
    label: 'Start Date',
    fieldFormatter: dateFormatter,
    headerClassName: css.headerCellEqualWidth,
  },
  {
    field: 'endDate',
    label: 'End Date',
    fieldFormatter: endDateFormatter,
    headerClassName: css.headerCellEqualWidth,
  },
  {
    field: 'amount',
    label: 'User Share',
    fieldFormatter: percentageFormatter,
    headerClassName: css.headerCellEqualWidth,
  },
  {
    field: 'companyPercentage',
    label: 'Company Share',
    virtual: true,
    fieldCalculator: companyPercentageFormatter,
    headerClassName: css.headerCellEqualWidth,
  },
  {
    field: 'actions',
    label: 'Actions',
    component: SchedulerActions,
    headerClassName: css.headerCellEqualWidth,
  },
];

const BusinessCashbackScheduler = React.memo(({ businessId }) => {
  const [cashbackSchedules, setCashbackSchedules] = useState([]);
  const [currentScheduler, setCurrentScheduler] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchBusinessCashbackScheduler = async () => {
      try {
        const { data } = await axios.get(
          `/businesses/${businessId}/cashback-schedulers`
        );
        setCashbackSchedules(data);
        setIsLoading(false);
        setError();
      } catch (error) {
        console.error(error);
        setError('Error fetching cashback schedulers');
        setIsLoading(false);
      }
    };
    if (businessId) {
      setIsLoading(true);
      fetchBusinessCashbackScheduler();
    } else {
      setIsLoading(false);
      setCashbackSchedules([]);
    }
    return () => {};
  }, [businessId]);
  const handleSchedulerDelete = useCallback(async (scheduler) => {
    try {
      await axios.delete(`/businesses/cashback-schedulers/${scheduler.id}`);
      setCashbackSchedules((schedulers) =>
        schedulers.filter((s) => s.id !== scheduler.id)
      );
      setError();
    } catch (error) {
      console.error(error);
      setError('Error deleting cashback scheduler');
    }
  }, []);
  const handleSchedulerEdit = useCallback(async (scheduler) => {
    setCurrentScheduler({ ...scheduler });
  }, []);
  const onScheduleAction = useCallback(
    (scheduler, field, action) => {
      if (action === 'edit') {
        handleSchedulerEdit(scheduler);
      } else if (action === 'delete') {
        handleSchedulerDelete(scheduler);
      }
    },
    [handleSchedulerDelete, handleSchedulerEdit]
  );
  const onSchedulerEditFinish = useCallback(async (scheduler) => {
    if (scheduler) {
      const normalizedScheduler = { ...scheduler };
      normalizedScheduler.startDate = moment(scheduler.startDate).format(
        'YYYY-MM-DD'
      );
      if (scheduler.endDate) {
        normalizedScheduler.endDate = moment(scheduler.endDate).format(
          'YYYY-MM-DD'
        );
      }
      if (normalizedScheduler.id) {
        // Edit
        try {
          const { data } = await axios.put(
            `/businesses/cashback-schedulers/${normalizedScheduler.id}`,
            normalizedScheduler
          );
          setCashbackSchedules((cashbackSchedules) =>
            cashbackSchedules.map((normalizedScheduler) =>
              normalizedScheduler.id === data.id ? data : normalizedScheduler
            )
          );
        } catch (error) {
          const baseError = { message: 'Error updating cashback scheduler' };
          console.error(error);
          if (error.response?.data) {
            return { ...error.response.data, ...baseError };
          }
          return baseError;
        }
      } else {
        // Create
        try {
          const { data } = await axios.post(
            `/businesses/cashback-schedulers`,
            normalizedScheduler
          );
          setCashbackSchedules((cashbackSchedules) => [
            ...cashbackSchedules,
            data,
          ]);
        } catch (error) {
          const baseError = { message: 'Error creating cashback scheduler' };
          console.error(error);
          if (error.response?.data) {
            return { ...error.response.data, ...baseError };
          }
          return baseError;
        }
      }
    }
    setCurrentScheduler();
  }, []);
  const onSchedulerCreate = useCallback(
    (scheduler) => {
      setCurrentScheduler({ businessId: parseInt(businessId, 10) });
    },
    [businessId]
  );
  return (
    <>
      <div className={classnames('input-cntr', css.inSameLine)}>
        <label className="label">Custom Split Schedule</label>
        <Button onClick={() => onSchedulerCreate()} text="add" size="small" />
      </div>
      {cashbackSchedules.length > 0 && (
        <BusinessCashbackSchedulerTable
          columns={columns}
          schedulers={cashbackSchedules}
          onAction={onScheduleAction}
        />
      )}
      {cashbackSchedules.length === 0 && (
        <div>No Custom Cashback Split events have been scheduled</div>
      )}
      {error && <div className="error_msg">{error}</div>}
      {currentScheduler && (
        <BusinessCashbackSchedulerEdit
          scheduler={currentScheduler}
          onFinish={onSchedulerEditFinish}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
});

BusinessCashbackScheduler.propTypes = {
  businessId: PropTypes.string,
};

export { BusinessCashbackScheduler };

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React from 'react';
import ImageSelect from 'src/components/Inputs/ImageSelect';
import ReorderButtons from '../components/ReorderButtons';
import Wysiwyg from '../components/Wysiwyg';

const limitOptions = Array.from({ length: 20 }, (e, i) => i + 1);

export default function ProgramCarouselModule({
  items,
  limit,
  showError,
  handleChange,
  handleLimitChange,
  handleImageChange,
  handleImagesChange,
  handleRemoveItem,
  handleMoveDownItem,
  handleMoveUpItem,
}) {
  return (
    <div>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="limit-select">Limit</InputLabel>
        <Select
          labelId="limit-select"
          autoFocus
          name="limit"
          label="Type"
          value={limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {items
        ? items.map((image, index) => (
            <div key={index}>
              {items.length > 1 ? (
                <Divider textAlign="center">
                  <Chip label={index + 1} />
                </Divider>
              ) : null}
              <Stack direction="row" justifyContent="end">
                <ReorderButtons
                  index={index}
                  items={items}
                  handleMoveDownItem={handleMoveDownItem}
                  handleMoveUpItem={handleMoveUpItem}
                />
              </Stack>
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                error={showError && !image.heading}
                label="Heading"
                variant="outlined"
                value={image.heading}
                inputProps={{ maxLength: 100 }}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'heading')
                }
                helperText={showError && !image.heading ? 'Required' : null}
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                label="Heading (fr)"
                variant="outlined"
                value={image.heading_fr}
                inputProps={{ maxLength: 100 }}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'heading_fr')
                }
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                label="Sub-heading"
                variant="outlined"
                value={image.subHeading}
                inputProps={{ maxLength: 100 }}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'subHeading')
                }
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                label="Sub-heading (fr)"
                variant="outlined"
                value={image.subHeading_fr}
                inputProps={{ maxLength: 100 }}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'subHeading_fr')
                }
              />
              <Stack
                direction="row"
                // sx={{ padding: 1 }}
                spacing={2}
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Stack>
                  <ImageSelect
                    initialImage={image.backgroundImageMedia}
                    label="Background Image Desktop"
                    name="headerImage"
                    type="Desktop"
                    handleSelect={(value, name) =>
                      handleImageChange(value, index, 'backgroundImage')
                    }
                    error={
                      showError && !image.backgroundImage
                        ? 'Please Select an image'
                        : null
                    }
                  />
                  <ImageSelect
                    initialImage={image.imageItem}
                    label="Image"
                    name="headerImage"
                    type="Thumbnail"
                    handleSelect={(value, name) =>
                      handleImageChange(value, index, 'image')
                    }
                  />
                </Stack>
                <Stack>
                  <ImageSelect
                    initialImage={image.backgroundImageMobileMedia}
                    label="Background Image Mobile"
                    name="headerImage"
                    type="Mobile"
                    handleSelect={(value, name) =>
                      handleImageChange(value, index, 'backgroundImageMobile')
                    }
                    error={
                      showError && !image.backgroundImageMobile
                        ? 'Please Select an image'
                        : null
                    }
                  />
                  <ImageSelect
                    initialImage={image.imageItem_fr}
                    label="Image (French)"
                    name="headerImage"
                    type="Thumbnail"
                    handleSelect={(value, name) =>
                      handleImageChange(value, index, 'image_fr')
                    }
                  />
                </Stack>
                <Stack direction="column" spacing={2}></Stack>
              </Stack>
              <Wysiwyg
                autoFocus
                name="text"
                label="Text"
                val={image.text}
                onChange={(content) =>
                  handleImagesChange(content, index, 'text')
                }
              />
              <Wysiwyg
                name="text_fr"
                label="Text (French)"
                val={image.text_fr}
                onChange={(content) =>
                  handleImagesChange(content, index, 'text_fr')
                }
              />
              <TextField
                fullWidth
                sx={{ margin: '15px 0' }}
                label="URL"
                variant="outlined"
                value={image.url}
                onChange={(e) =>
                  handleImagesChange(e.target.value, index, 'url')
                }
              />
            </div>
          ))
        : null}
    </div>
  );
}

import React from "react";
import css from "./ListHeader.module.scss";

const ListHeader = ({ headings }) => {
  return (
    <div className={css.header}>
      {headings.map((heading) => (
        <div>{heading}</div>
      ))}
    </div>
  );
};

export default ListHeader;

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BuilderArea from "./BuilderArea";

export const Builder = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <BuilderArea {...props} />
    </DndProvider>
  );
};

export default Builder;

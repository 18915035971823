import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { configureHeaders } from 'src/utils';
import { logout } from '../../../redux/actions/authActions';
import Login from '../../auth/Login';
import Avatar from './Avatar';
import Menu from './Menu';
import QuickActions from './QuickActions';

const Navbar = ({ auth: { isAuthenticated, refreshingToken, user } }) => {
  let location = useLocation();

  useEffect(() => {
    configureHeaders(location.pathname);
  }, [location]);

  if (!isAuthenticated && !refreshingToken) {
    return (
      <div className="navbar">
        <Login />
      </div>
    );
  }

  return (
    <div className="navbar">
      <div>
        <div>
          <h2>Site manager</h2>
        </div>
        <QuickActions role={user.role} />
        <Menu role={user.role} />
      </div>
      <Avatar />
    </div>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

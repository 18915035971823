import { TextField } from '@mui/material';
import { alignmentOptions, getURLError } from 'src/utils';
import styled from 'styled-components';
import CustomMUISelect from '../components/CustomMUISelect';

const ButtonModule = ({
  alignment,
  text,
  text_fr,
  url,
  handleChange,
  handleChangeRemoveError,
  showError,
}) => {
  let urlError = getURLError(showError, url);
  return (
    <>
      <CustomMUISelect
        name="alignment"
        label="Alignment"
        options={alignmentOptions}
        value={alignment}
        onChange={handleChange}
      />
      <Input
        error={showError && !text}
        autoFocus
        name="text"
        label="Text"
        variant="outlined"
        value={text}
        onChange={handleChangeRemoveError}
        helperText={showError && !text ? 'Required' : null}
        inputProps={{ maxLength: 100 }}
      />
      <Input
        name="text_fr"
        label="Text (fr)"
        variant="outlined"
        value={text_fr}
        onChange={handleChange}
        inputProps={{ maxLength: 100 }}
      />
      <Input
        error={urlError}
        name="url"
        label="Url"
        variant="outlined"
        value={url}
        onChange={handleChangeRemoveError}
        helperText={urlError}
      />
    </>
  );
};

export default ButtonModule;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;

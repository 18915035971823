import React from "react";

const TagListHeader = () => {
  return (
    <li className="header">
      <div className="list-inner">
        <div className="name">Name</div>
        <div className="button-container">Action</div>
      </div>
    </li>
  );
};

export default TagListHeader;
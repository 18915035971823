import axios from 'axios';

export const fetchUsers = async (inputValue) => {
  const url = `/users`;
  const options = {
    dropdown: true,
    search: inputValue,
  };
  try {
    const res = await axios.get(url, { params: options });
    return [undefined, res.data];
  } catch (err) {
    return [err];
  }
};
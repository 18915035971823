import React from 'react';
import styled from 'styled-components';

export const ListHeader = ({ setSort, sortBy, columns, columnWidth }) => {
  const sortIcon = sortBy.asc ? (
    <i className="fas fa-sort-up" style={{ marginLeft: '5px' }}></i>
  ) : (
    <i class="fas fa-sort-down" style={{ marginLeft: '5px' }}></i>
  );

  if (columns) {
    return (
      <Header columnWidth={columnWidth}>
        {columns.map((el) => (
          <div key={el.name} onClick={() => setSort(el.name)}>
            {el.label} {sortBy.field === el.name ? sortIcon : null}
          </div>
        ))}
      </Header>
    );
  }

  return (
    <Header>
      <div onClick={() => setSort('activityDate')}>
        DateTime {sortBy.field === 'activityDate' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('user')}>
        Username {sortBy.field === 'user' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('business')}>
        Business Name {sortBy.field === 'business' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('program')}>
        Program {sortBy.field === 'program' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('activityType')}>
        Activity Type {sortBy.field === 'activityType' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('affiliateCommissionAmount')}>
        Total Commission{' '}
        {sortBy.field === 'affiliateCommissionAmount' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('affiliateCommissionDate')}>
        Commission DateTime{' '}
        {sortBy.field === 'affiliateCommissionDate' ? sortIcon : null}
      </div>
      <div onClick={() => setSort('spendAmount')}>
        Total Purchase Amount
        {sortBy.field === 'spendAmount' ? sortIcon : null}
      </div>
    </Header>
  );
};

export default ListHeader;

const Header = styled.li`
  padding: 12px 20px;
  border-bottom: 2px solid #efefef;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  background: rgba(247, 247, 250, 0.6);
  display: flex;
  align-items: center;

  div {
    width: ${(p) => (p.columnWidth ? p.columnWidth : '12.5%')};
    word-break: break-word;
    line-height: 150%;
    padding: 5px;
    cursor: pointer;
  }
`;

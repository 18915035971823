import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Inputs/Spinner';
import CategoryListHeader from './CategoryListHeader';
import CategoryListItem from './CategoryListItem';

const CategoryList = ({ categories, loading }) => {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    setCats(categories);
  }, [categories]);

  return (
    <div className="list-group">
      <ul>
        <CategoryListHeader />

        {cats ? (
          cats.map((cat) => <CategoryListItem key={cat.id} category={cat} />)
        ) : (
          <Spinner />
        )}
      </ul>
    </div>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CategoryList;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Inputs/Button';

const Header = ({ title, btnText, btnOnClick, btnLink, url, btnReqAuth }) => {
  return (
    <div className="dashboard-header">
      <h1>{title}</h1>

      {btnReqAuth ? null : (
        <div style={{ margin: '10px', padding: '20px' }}>
          <Button
            text={btnText}
            onClick={btnOnClick}
            btnLink={btnLink}
            url={url}
          />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnOnClick: PropTypes.func,
  btnLink: PropTypes.bool,
  url: PropTypes.string,
  btnReqAuth: PropTypes.bool,
};

Header.defaultProps = {
  btnOnClick: undefined,
  btnLink: null,
  url: null,
  btnReqAuth: false,
};

export default Header;

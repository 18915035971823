import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import Spinner from '../../Inputs/Spinner';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import './PollSettings.scss';

function PollSettings(props) {
  const [errors, setErrors] = useState({});

  const [questions, setQuestions] = useState([]);
  const [field, setField] = useState(null);
  const [loading, setLoading] = useState(false);

  const [autoResData, setAutoResData] = useState({
    limit: '0',
    oncePerUser: null,
    err_submissionLimitReached: '',
    err_submissionLimitReached_fr: '',
    err_alreadySubmitted: '',
    err_alreadySubmitted_fr: '',
    autoResponseEnabled: false,
    autoResponseText: '',
    autoResponseText_fr: '',
    autoResponseRecepient: '',
    autoResponseSubject: '',
    autoResponseSubject_fr: '',
    useFormRecepient: false,
    forwardingEmail: '',
  });

  useEffect(() => {
    const { poll } = props;
    setAutoResData({
      limit: poll.limit,
      oncePerUser: poll.oncePerUser,
      err_submissionLimitReached: poll.err_submissionLimitReached,
      err_submissionLimitReached_fr: poll.err_submissionLimitReached_fr,
      err_alreadySubmitted: poll.err_alreadySubmitted,
      err_alreadySubmitted_fr: poll.err_alreadySubmitted_fr,
      autoResponseEnabled: poll.autoResponseEnabled,
      autoResponseText: poll.autoResponseText,
      autoResponseText_fr: poll.autoResponseText_fr,
      autoResponseRecepient: poll.autoResponseRecepient,
      autoResponseSubject: poll.autoResponseSubject,
      autoResponseSubject_fr: poll.autoResponseSubject_fr,
      useFormRecepient: poll.useFormRecepient,
      forwardingEmail: poll.forwardingEmail,
    });
    let questions = [];
    poll.questions.forEach((q) => {
      if (q.validator === 'Email')
        questions.push({
          value: q.id,
          label: q.text.replace(/(<([^>]+)>)/gi, '').slice(0, 30),
        });
      if (q.id) {
        setField(q.id);
      }
      setQuestions(questions);
    });

    return () => {
      setAutoResData({
        limit: 0,
        oncePerUser: null,
        err_submissionLimitReached: '',
        err_submissionLimitReached_fr: '',
        err_alreadySubmitted: '',
        err_alreadySubmitted_fr: '',
        autoResponseEnabled: false,
        autoResponseText: '',
        autoResponseText_fr: '',
        autoResponseRecepient: '',
        autoResponseSubject: '',
        autoResponseSubject_fr: '',
        useFormRecepient: false,
        forwardingEmail: '',
      });
    };
  }, [props]);

  const handleWysiwyg = (content) => {
    setAutoResData({ ...autoResData, autoResponseText: content });
  };
  const handleWysiwygFr = (content) => {
    setAutoResData({ ...autoResData, autoResponseText_fr: content });
  };
  const handleCheckbox = (e) => {
    setAutoResData({ ...autoResData, [e.target.name]: e.target.checked });
  };
  const handleTextField = (e) => {
    setAutoResData({ ...autoResData, [e.target.name]: e.target.value });
  };
  const handleLimitChange = (e) => {
    const { value } = e.target;
    if (value >= 0 && value.length <= 5) {
      setAutoResData({ ...autoResData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    const {
      poll: { name, id },
    } = props;
    const path = `/polls/${id}`;
    const payload = {
      ...autoResData,
      name,
      formRecepientId: !isEmpty(field) ? field : undefined,
    };

    try {
      setLoading(true);
      setErrors({});
      await axios.put(path, payload);
      props.getPoll();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err.response.data);
    }
  };

  let disableButton = false;
  if (
    autoResData.autoResponseEnabled &&
    ((!field && autoResData.useFormRecepient) ||
      (isEmpty(autoResData.autoResponseRecepient) &&
        !autoResData.useFormRecepient))
  )
    disableButton = true;

  if (loading) return <Spinner />;

  return (
    <div className="poll-settings-container">
      <Paper sx={{ my: 2, p: 2 }} elevation={1}>
        <h1>Auto Responder</h1>

        <label className="poll-settings-checkbox" htmlFor="autoResponseEnabled">
          Enable auto responder
          <input
            name="autoResponseEnabled"
            type="checkbox"
            checked={autoResData.autoResponseEnabled}
            onChange={handleCheckbox}
          />
        </label>

        {autoResData.autoResponseEnabled ? (
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              name="autoResponseSubject"
              type="email"
              value={autoResData.autoResponseSubject}
              onChange={handleTextField}
              label="Email Subject"
              error={!isEmpty(errors) ? !!errors.autoResponseSubject : null}
              helperText={!isEmpty(errors) ? errors.autoResponseSubject : null}
            />
            <Wysiwyg
              val={autoResData.autoResponseText}
              onChange={handleWysiwyg}
              label="Response"
              error={!isEmpty(errors) ? errors.autoResponseText : null}
            />
            <TextField
              fullWidth
              name="autoResponseSubject_fr"
              type="email"
              value={autoResData.autoResponseSubject_fr}
              onChange={handleTextField}
              label="Email Subject (French)"
              error={!isEmpty(errors) ? !!errors.autoResponseSubject_fr : null}
              helperText={
                !isEmpty(errors) ? errors.autoResponseSubject_fr : null
              }
            />
            <Wysiwyg
              val={autoResData.autoResponseText_fr}
              onChange={handleWysiwygFr}
              label="Response (fr)"
              error={!isEmpty(errors) ? errors.autoResponseText_fr : null}
            />

            <label
              className="poll-settings-checkbox"
              htmlFor="useFormRecepient"
            >
              Use Form Recipient
              <input
                name="useFormRecepient"
                type="checkbox"
                checked={autoResData.useFormRecepient}
                onChange={handleCheckbox}
                error={!isEmpty(errors) ? errors.useFormRecepient : null}
              />
            </label>
            <FormControl>
              <InputLabel id="type-select-label">
                Select a form field to use as recepient email
              </InputLabel>
              <Select
                name="type"
                label="Select a form field to use as recepient email"
                labelId="type-select-label"
                value={field}
                required
                onChange={(e) => setField(e.target.value)}
                disabled={!autoResData.useFormRecepient}
              >
                {questions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {disableButton
                  ? 'At least 1 form recepient must be defined'
                  : null}
              </FormHelperText>
            </FormControl>
            <TextField
              name="autoResponseRecepient"
              type="email"
              value={autoResData.autoResponseRecepient}
              onChange={handleTextField}
              label="Auto Response Recipient"
              disabled={autoResData.useFormRecepient}
              error={!isEmpty(errors) ? errors.autoResponseRecepient : null}
              helperText={
                disableButton
                  ? 'At least 1 form recepient must be defined'
                  : null
              }
            />
          </Stack>
        ) : null}
      </Paper>
      <Paper sx={{ mb: 2, p: 2 }} elevation={1}>
        <h1>Set Poll Submission Limits</h1>

        <label className="poll-settings-checkbox" htmlFor="oncePerUser">
          Limit to one submission per user
          <input
            name="oncePerUser"
            type="checkbox"
            checked={autoResData.oncePerUser}
            onChange={handleCheckbox}
            error={!isEmpty(errors) ? errors.oncePerUser : null}
          />
        </label>
        <Stack spacing={2} sx={{ mt: 1, width: 500 }}>
          {autoResData.oncePerUser ? (
            <>
              <TextField
                name="err_alreadySubmitted"
                value={autoResData.err_alreadySubmitted}
                onChange={handleTextField}
                error={!isEmpty(errors) ? !!errors.err_alreadySubmitted : null}
                helperText={
                  !isEmpty(errors) ? errors.err_alreadySubmitted : null
                }
                label="Already submitted error"
              />
              <TextField
                name="err_alreadySubmitted_fr"
                value={autoResData.err_alreadySubmitted_fr}
                onChange={handleTextField}
                error={
                  !isEmpty(errors) ? !!errors.err_alreadySubmitted_fr : null
                }
                helperText={
                  !isEmpty(errors) ? errors.err_alreadySubmitted_fr : null
                }
                label="Already submitted error (fr)"
              />
            </>
          ) : null}

          <TextField
            name="limit"
            type="number"
            value={autoResData.limit}
            onChange={handleLimitChange}
            label="Limit"
            error={!isEmpty(errors) ? !!errors.limit : null}
            helperText={!isEmpty(errors) ? errors.limit : null}
          />

          {autoResData.limit > 0 ? (
            <>
              <TextField
                name="err_submissionLimitReached"
                value={autoResData.err_submissionLimitReached}
                onChange={handleTextField}
                error={
                  !isEmpty(errors) ? !!errors.err_submissionLimitReached : null
                }
                helperText={
                  !isEmpty(errors) ? errors.err_submissionLimitReached : null
                }
                label="Submission Limit Reached Error"
              />
              <TextField
                name="err_submissionLimitReached_fr"
                value={autoResData.err_submissionLimitReached_fr}
                onChange={handleTextField}
                error={
                  !isEmpty(errors)
                    ? !!errors.err_submissionLimitReached_fr
                    : null
                }
                helperText={
                  !isEmpty(errors) ? errors.err_submissionLimitReached_fr : null
                }
                label="Submission Limit Reached Error (fr)"
              />
            </>
          ) : null}
        </Stack>
      </Paper>

      <Paper sx={{ p: 2 }} elevation={1}>
        <h1>Forwarding</h1>
        <TextField
          fullWidth
          name="forwardingEmail"
          value={autoResData.forwardingEmail}
          onChange={handleTextField}
          error={!isEmpty(errors) ? !!errors.forwardingEmail : null}
          helperText={!isEmpty(errors) ? errors.forwardingEmail : null}
          label="Forwarding Email"
        />
      </Paper>

      <div style={{ margin: '20px 0' }}>
        <Button text="Save" onClick={handleSubmit} disabled={disableButton} />
      </div>
    </div>
  );
}

PollSettings.propTypes = {};

export default PollSettings;

import { MobileDatePicker } from '@mui/lab';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchUsers } from '../../utils/fetchUsers';
import styled from 'styled-components';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import List from './List';

const activityOptions = [
  { label: 'Sale', value: 'affiliate_sale' },
  { label: 'Lead', value: 'affiliate_lead' },
  { label: 'Click', value: 'affiliate_click' },
  { label: 'Bonus', value: 'affiliate_bonus' },
];

const columns = [
  { label: 'DateTime', name: 'activityDate', type: 'datetime' },
  { label: 'Username', name: 'user', type: 'username' },
  { label: 'Business Name', name: 'business' },
  { label: 'Program', name: 'program' },
  { label: 'Activity Type', name: 'activityType' },
  { label: 'Total Commission', name: 'affiliateCommissionAmount' },
  { label: 'User commission share', name: 'userCashback' },
  { label: 'UPP Commission Share', name: 'uppCommision' },
  {
    label: 'Commission DateTime',
    name: 'affiliateCommissionDate',
    type: 'datetime',
  },
  { label: 'Total Purchase Amount', name: 'spendAmount' },
];

export default function AffiliateTrackingDashboard({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [affiliateProgramOptions, setAffliateProgramOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchAffiliatePrograms();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'activityDate', asc: false });
  const [business, setBusiness] = useState([]);
  const [affiliateProgram, setAffiliateProgram] = useState([]);
  const [activityType, setActivityType] = useState([]);
  const [showUnhealthy, setShowUnhealthy] = useState(false);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    history.push(`/dashboard/transactions/${page}`);
  };

  const fetchAffiliatePrograms = async () => {
    try {
      const res = await axios.get(`/affiliates`);

      setAffliateProgramOptions(
        res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const fetchBusinesses = async () => {
    try {
      const res = await axios.get(`/businesses/dropdown`);
      setBusinessOptions(res.data);
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        const [error, userOptions] = await fetchUsers(request.input);
        if (!error) {
          setUserOptions(userOptions);
        }
      }, 1000),
    []
  );

  useEffect(() => {
    fetch({ input: userSearch });
  }, [userSearch, fetch]);

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const fetchData = useCallback(
    async (page = 1, csv = false) => {
      const url = `/reports/affiliateActivity`;
      const options = {
        page: page,
        user: userFilter && userFilter.map((el) => el.value).toString(),
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        csv: csv,
        business: business && business.map((el) => el.value).toString(),
        program:
          affiliateProgram &&
          affiliateProgram
            .map((el) => (el.value === null ? 'null' : el.label))
            .toString(),
        activity: activityType && activityType.map((el) => el.value).toString(),
        order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
      };
      if (showUnhealthy) {
        options.healthy = false;
      }
      if (page === 1) history.push(`/dashboard/transactions/1`);
      setErrors({});
      setLoading(true);
      try {
        const res = await axios.get(url, { params: options });

        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `transactions-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setTransactionData(res.data.rows);
          setTotalResults(res.data.count);
        }
        setLoading(false);
      } catch (error) {
        setErrors(error.response.data);
        setLoading(false);
        setTotalResults(0);
      }
    },
    [
      userFilter,
      startDate,
      endDate,
      showUnhealthy,
      sortBy.field,
      sortBy.asc,
      business,
      affiliateProgram,
      activityType,
      history,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <h1>Affiliate Tracking Dashboard</h1>

      <Controls>
        <Autocomplete
          size="small"
          sx={{ width: 200, mb: 2 }}
          value={userFilter}
          multiple
          onChange={(event, newValue) => {
            setUserFilter(newValue);
          }}
          inputValue={userSearch}
          onInputChange={(event, newInputValue) => {
            setUserSearch(newInputValue);
          }}
          id="combo-box-user-list"
          options={userOptions}
          renderInput={(params) => (
            <TextField {...params} label="User" placeholder="Search..." />
          )}
        />
        <Autocomplete
          sx={{ width: 200 }}
          multiple
          id="business-select-label"
          options={businessOptions}
          value={business}
          onChange={(event, newValue) => {
            setBusiness(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Business" />}
        />
        <Autocomplete
          sx={{ width: 180 }}
          name="affiliateProgram"
          multiple
          id="affiliateProgram-select-label"
          options={[{ value: null, label: 'None' }, ...affiliateProgramOptions]}
          value={affiliateProgram}
          onChange={(event, newValue) => {
            setAffiliateProgram(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Affiliate Program" />
          )}
        />
        <MobileDatePicker
          name="startDate"
          value={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <MobileDatePicker
          name="endDate"
          value={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <Autocomplete
          sx={{ width: 150 }}
          multiple
          id="activityType-select-label"
          options={activityOptions}
          value={activityType}
          onChange={(event, newValue) => {
            setActivityType(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Activity Type" />
          )}
          error={errors.activityType}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showUnhealthy}
              onChange={(event) => setShowUnhealthy(event.target.checked)}
            />
          }
          label="Show transactions with missing data"
          labelPlacement="start"
        />
      </Controls>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
        showInvalid
      />
      {renderPagination()}
    </div>
  );
}

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  .input-cntr {
    margin: 10px;
    width: 15%;
  }
`;

import { MobileDatePicker } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '../../../utils';
import Button from '../../Inputs/Button';
import Spinner from '../../Inputs/Spinner';
import { AdjustUserCashbackBalance } from './AdjustUserCashbackBalance';
import {
  albertaOptions,
  annualIncomeOptions,
  bcOptions,
  countryOptions,
  employmentOptions,
  genderOptions,
  leaseOptions,
  manitobaOptions,
  newBrunswickOptions,
  NewfoundlandAndLabradorOptions,
  NovaScotiaOptions,
  ontarioOptions,
  PrinceEdwardIslandOptions,
  provinceOptions,
  QuebecOptions,
  rentOptions,
  SaskatchewanOptions,
  unionRoleOptions,
  vehicleMakeOptions,
} from './selectOptions';
export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      birthdate: null,
      gender: '',
      numberOfDependents: '',
      local: '',
      howDidYouFindUs: '',
      referrer: '',
      role: '',
      id: '',
      title: '',
      roleOptions: [],
      isActive: false,
      employmentStatus: '',
      unionRole: '',
      mortgageRenewalDate: null,
      homeOwnership: '',
      vehicleInsuranceCompany: '',
      vehicleLeaseExpirationDate: null,
      leaseOrFinance: '',
      vehicleMake: '',
      wirelessCarrier: '',
      homeInsuranceCompany: '',
      unionOptions: [],
      union: '',
      address: '',
      loading: true,
      password: '',
      annualIncome: '',
      streetAddress: '',
      appartment: '',
      city: '',
      postalCode: '',
      country: '',
      province: '',
      errors: {},
      referralCode: '',
      unionStreetAddress: '',
      unionAppartment: '',
      unionCountry: '',
      unionCity: '',
      unionProvince: '',
      unionPostalCode: '',
      wirelessCarrierOptions: [],
      insuranceCompanyOptions: [],
      autoInsuranceOptions: [],
      cashback: '',
      adjustUserCashbackBalance: false,
    };
    this.userRolesPriorityOrder = [
      'SUPER',
      'WEBSITE ADMIN',
      'USER ADMIN',
      'CONTENT EDITOR',
      'USER',
    ];
  }

  isRoleAvailable(currentUserRole, targetRole) {
    const currentRoleIndex =
      this.userRolesPriorityOrder.indexOf(currentUserRole);
    if (currentRoleIndex === -1) {
      return false;
    }
    const targetRoleIndex = this.userRolesPriorityOrder.indexOf(targetRole);
    if (targetRoleIndex === -1) {
      return false;
    }

    return targetRoleIndex >= currentRoleIndex;
  }

  showAvailableRoles(currentUserRole, targetRole) {
    switch (currentUserRole) {
      case 'WEBSITE ADMIN':
      case 'USER ADMIN':
        return targetRole === 'USER' || targetRole === 'CONTENT EDITOR';
      case 'SUPER':
        return true;
      default:
        return false;
    }
  }

  async componentDidMount() {
    const { match } = this.props;
    if (match.url.includes('add')) {
      this.setState({ loading: false });
    }

    try {
      const res = await axios.get('/users/roles');
      const arr = res.data.map((el) => {
        return { label: el.roleName, value: el.id };
      });
      this.setState({ roleOptions: arr });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
    if (match.params.id) {
      try {
        const res = await axios.get(`/users/${match.params.id}`);
        this.updateState(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const res = await axios.get('/unions', {
        params: { dropdown: true },
      });
      this.setState({
        unionOptions: res.data.map((union) => {
          return { label: union.name, value: union.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/carriers', {
        params: { dropdown: true },
      });
      this.setState({
        wirelessCarrierOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/homeinsurances', {
        params: { dropdown: true },
      });
      this.setState({
        insuranceCompanyOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }

    try {
      const res = await axios.get('/autoinsurances', {
        params: { dropdown: true },
      });
      this.setState({
        autoInsuranceOptions: res.data.map((el) => {
          return { label: el.name, value: el.id };
        }),
      });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  }

  updateState = (data) => {
    const { roleOptions } = this.state;
    this.setState({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      birthdate: data.birthdate && moment(data.birthdate).toDate(),
      mortgageRenewalDate:
        data.mortgageRenewalDate && moment(data.mortgageRenewalDate).toDate(),
      vehicleLeaseExpirationDate:
        data.vehicleLeaseExpirationDate &&
        moment(data.vehicleLeaseExpirationDate).toDate(),
      email: data.email,
      address: data.address,
      isActive: data.isActive,
      gender: data.gender,
      union: !isEmpty(data.union) ? data.union.id : null,
      local: data.local,
      howDidYouFindUs: data.howDidYouFindUs,
      role: roleOptions.find((roleOption) => roleOption.label === data.role).value,
      title: data.title,
      emailConfirmed: data.emailConfirmed,
      numberOfDependents: data.numberOfDependents,
      employmentStatus: data.employmentStatus,
      referrer: data.referrerName,
      unionRole: data.unionRole,
      homeOwnership: data.homeOwnership,

      leaseOrFinance: data.vehicleOwnership,
      vehicleMake: data.makeOfVehicle,
      wirelessCarrier: !isEmpty(data.wirelessCarrierId)
        ? data.wirelessCarrierId
        : null,
      homeInsuranceCompany: !isEmpty(data.homeInsurance)
        ? data.homeInsurance.id
        : null,
      vehicleInsuranceCompany: !isEmpty(data.autoInsuranceId)
        ? data.autoInsuranceId
        : null,
      annualIncome: data.annualIncome,
      streetAddress: data.streetAddress,
      postalCode: data.postalCode,
      appartment: data.appartment,
      unionStreetAddress: data.unionStreetAddress,
      unionAppartment: data.unionAppartment,
      unionPostalCode: data.unionPostalCode,
      unionCity: data.unionCity,
      unionCountry: data.unionCountry,
      unionProvince: data.unionProvince,
      country: data.country,
      province: data.province,
      city: data.city,
      referralCode: data.referralCode,
      cashback: data.cashback,
      loading: false,
    });
  };

  // Reset state when component switches to ADD
  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        birthdate: null,
        gender: '',
        numberOfDependents: '',
        local: '',
        howDidYouFindUs: '',
        referrer: '',
        role: {},
        id: '',
        title: '',
        roleOptions: [],
        isActive: false,
        employmentStatus: '',
        unionRole: {},
        mortgageRenewalDate: null,
        homeOwnership: {},
        vehicleInsuranceCompany: '',
        vehicleLeaseExpirationDate: null,
        leaseOrFinance: '',
        vehicleMake: {},
        wirelessCarrier: {},
        homeInsuranceCompany: {},
        unionOptions: [],
        union: {},
        address: '',
        password: '',
        annualIncome: '',
        streetAddress: '',
        appartment: '',
        city: '',
        postalCode: '',
        country: {},
        province: {},
        errors: {},
        unionStreetAddress: '',
        unionAppartment: '',
        unionCountry: {},
        unionCity: '',
        unionProvince: {},
        unionPostalCode: '',
        wirelessCarrierOptions: [],
        insuranceCompanyOptions: [],
        autoInsuranceOptions: [],
        loading: !this.props.match.url.includes('add'),
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDate = (date) => {
    this.setState({ birthdate: date });
  };

  handleChangeRole = (role) => {
    this.setState({ role });
  };

  handleSelectEmploymentStatus = (val) => {
    this.setState({ employmentStatus: val });
  };

  handleSelectUnionRole = (val) => {
    this.setState({ unionRole: val });
  };

  handleSelectwirelessCarrier = (val) => {
    this.setState({ wirelessCarrier: val });
  };

  handleSelectleaveOrFinance = (val) => {
    this.setState({ leaseOrFinance: val });
  };

  handleLeaseUp = (val) => {
    this.setState({ vehicleLeaseExpirationDate: val });
  };

  handlevehicleInsuranceCompany = (val) => {
    this.setState({ vehicleInsuranceCompany: val });
  };

  handleSelectVehicleMake = (val) => {
    this.setState({ vehicleMake: val });
  };

  handleSelectannualIncome = (val) => {
    this.setState({ annualIncome: val });
  };

  handlehomeOwnership = (val) => {
    this.setState({ homeOwnership: val });
  };

  handlemortgageRenewal = (val) => {
    this.setState({ mortgageRenewalDate: val });
  };

  handlehomeInsurance = (val) => {
    this.setState({ homeInsuranceCompany: val });
  };

  handleChangeUnion = (val) => {
    this.setState({ union: val });
  };
  handleChangeGender = (val) => {
    this.setState({ gender: val });
  };
  handleChangeCountry = (val) => {
    this.setState({ country: val });
  };
  handleChangeProvince = (val) => {
    this.setState({ province: val });
  };
  handleChangeCountryUnion = (val) => {
    this.setState({ unionCountry: val });
  };
  handleChangeProvinceUnion = (val) => {
    this.setState({ unionProvince: val });
  };
  handleSelectCity = (val) => {
    this.setState({ city: val });
  };
  handleSelectUnionCity = (val) => {
    this.setState({ unionCity: val });
  };

  handleAdjustUserCashbackBalance = () => {
    this.setState({ adjustUserCashbackBalance: true });
  };

  adjustUserCashbackBalanceFinishHandler = () => {
    this.setState({ adjustUserCashbackBalance: false });
  };

  handleSubmit = () => {
    const { match } = this.props;
    this.setState({ loading: true });
    const {
      firstName,
      lastName,
      birthdate,
      email,
      gender,
      numberOfDependents,
      union,
      local,
      role,
      id,
      title,
      isActive,
      employmentStatus,
      unionRole,
      homeInsuranceCompany,
      mortgageRenewalDate,
      homeOwnership,
      vehicleInsuranceCompany,
      vehicleLeaseExpirationDate,
      leaseOrFinance,
      vehicleMake,
      wirelessCarrier,
      annualIncome,
      password,
      streetAddress,
      appartment,
      city,
      postalCode,
      country,
      province,
      unionStreetAddress,
      unionAppartment,
      unionCountry,
      unionCity,
      unionProvince,
      unionPostalCode,
    } = this.state;

    const payload = {
      firstName,
      lastName,
      birthdate,
      local,
      title: title ? title : undefined,
      isActive,
      gender: !isEmpty(gender) ? gender : undefined,
      unionId: !isEmpty(union) ? union : undefined,
      email,
      numberOfDependents,
      roleId: role,
      annualIncome: annualIncome ? annualIncome : undefined,
      employmentStatus: !isEmpty(employmentStatus)
        ? employmentStatus
        : undefined,
      unionRole: unionRole ? unionRole : undefined,
      makeOfVehicle: vehicleMake ? vehicleMake : undefined,
      vehicleLeaseExpirationDate: vehicleLeaseExpirationDate,
      homeOwnership: homeOwnership ? homeOwnership : undefined,
      mortgageRenewalDate: mortgageRenewalDate,
      wirelessCarrierId: wirelessCarrier ? wirelessCarrier : undefined,
      homeInsuranceId: homeInsuranceCompany ? homeInsuranceCompany : undefined,
      autoInsuranceId: vehicleInsuranceCompany
        ? vehicleInsuranceCompany
        : undefined,
      vehicleOwnership: leaseOrFinance ? leaseOrFinance : undefined,
      streetAddress,
      appartment,
      city: !isEmpty(city) ? city : null,
      postalCode,
      country: !isEmpty(country) ? country : undefined,
      province: !isEmpty(province) ? province : undefined,
      unionStreetAddress,
      unionAppartment,
      unionPostalCode,
      unionCity: !isEmpty(unionCity) ? unionCity : undefined,
      unionProvince: !isEmpty(unionProvince) ? unionProvince : undefined,
      unionCountry: !isEmpty(unionCountry) ? unionCountry : undefined,
      password: password,
    };
    let method = 'post';
    if (match.params.id) {
      method = 'put';
      delete payload.password;
    }
    axios[method](`/users/${id}`, payload)
      .then(() => {
        this.setState({ loading: false });
        window.location.replace('/dashboard/users');
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  toggleIsActive = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const allowedRoles = ['SUPER', 'WEBSITE ADMIN'];
    const {
      firstName,
      lastName,
      birthdate,
      email,
      gender,
      numberOfDependents,
      union,
      local,
      role,
      id,
      roleOptions,
      isActive,
      loading,
      employmentStatus,
      unionRole,
      homeInsuranceCompany,
      mortgageRenewalDate,
      homeOwnership,
      vehicleInsuranceCompany,
      vehicleLeaseExpirationDate,
      leaseOrFinance,
      vehicleMake,
      wirelessCarrier,
      unionOptions,
      password,
      annualIncome,
      errors,
      streetAddress,
      appartment,
      city,
      country,
      province,
      postalCode,
      unionStreetAddress,
      unionAppartment,
      unionCountry,
      unionCity,
      unionProvince,
      unionPostalCode,
      wirelessCarrierOptions,
      autoInsuranceOptions,
      referralCode,
      insuranceCompanyOptions,
    } = this.state;
    const { match, auth } = this.props;
    if (loading) return <Spinner />;

    let cityOptions = [];
    if (province) {
      switch (province.value) {
        case 'Alberta':
          cityOptions = albertaOptions;
          break;
        case 'British Columbia':
          cityOptions = bcOptions;
          break;
        case 'Manitoba':
          cityOptions = manitobaOptions;
          break;
        case 'New Brunswick':
          cityOptions = newBrunswickOptions;
          break;
        case 'Newfoundland and Labrador':
          cityOptions = NewfoundlandAndLabradorOptions;
          break;
        case 'Nova Scotia':
          cityOptions = NovaScotiaOptions;
          break;
        case 'Ontario':
          cityOptions = ontarioOptions;
          break;
        case 'Prince Edward Island':
          cityOptions = PrinceEdwardIslandOptions;
          break;
        case 'Quebec':
          cityOptions = QuebecOptions;
          break;
        case 'Saskatchewan':
          cityOptions = SaskatchewanOptions;
          break;
        default:
          // no need for default option if it's going to be an empty array
          cityOptions = [];
      }
    }

    const mailToString = `mailto:?subject=UPP%20referral&body=${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`;

    const share = () => {
      if (navigator.share) {
        navigator
          .share({
            title: 'UPP referral',
            text: `Referral Code: ${referralCode}`,
            url: `${process.env.REACT_APP_WEB_URL}/signup?referrerId=${referralCode}`,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Web Share API is not supported in your browser.');
      }
    };

    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(referralCode);
      alert('Referral Code copied to clipboard.');
    };

    return (
      <div>
        <h1>{match.params.id ? 'Edit user profile' : 'Add a new user'}</h1>

        {isActive ? (
          <p
            onClick={this.toggleIsActive}
            className="toggleIsActive"
            style={{ color: 'red' }}
          >
            Disable user
          </p>
        ) : (
          <p
            onClick={this.toggleIsActive}
            className="toggleIsActive"
            style={{ color: 'blue' }}
          >
            Enable user
          </p>
        )}
        {match.params.id ? (
          navigator.share ? (
            <>
              <p>
                USER ID:
                {id}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '2vw',
                }}
              >
                <div
                  className="referralCode"
                  onClick={() => {
                    handleCopyToClipboard();
                  }}
                >
                  <p>REFERRAL CODE : {referralCode}</p>
                </div>
                <button className="shareButton" onClick={share}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 12.0161C12.6578 12.0161 12.0533 12.2721 11.5911 12.6732L5.25333 9.13153C5.29778 8.93524 5.33333 8.73896 5.33333 8.53414C5.33333 8.32932 5.29778 8.13303 5.25333 7.93675L11.52 4.42922C12 4.85592 12.6311 5.12048 13.3333 5.12048C14.8089 5.12048 16 3.97691 16 2.56024C16 1.14357 14.8089 0 13.3333 0C11.8578 0 10.6667 1.14357 10.6667 2.56024C10.6667 2.76506 10.7022 2.96135 10.7467 3.15763L4.48 6.66516C4 6.23845 3.36889 5.9739 2.66667 5.9739C1.19111 5.9739 0 7.11747 0 8.53414C0 9.9508 1.19111 11.0944 2.66667 11.0944C3.36889 11.0944 4 10.8298 4.48 10.4031L10.8089 13.9533C10.7644 14.1325 10.7378 14.3203 10.7378 14.508C10.7378 15.882 11.9022 17 13.3333 17C14.7644 17 15.9289 15.882 15.9289 14.508C15.9289 13.134 14.7644 12.0161 13.3333 12.0161Z"
                      fill="#ED1C24"
                    />
                  </svg>
                  <div className="shareText">SHARE</div>
                </button>
              </div>
            </>
          ) : (
            <>
              <p>
                USER ID:
                {id}
              </p>
              <div
                className="referralCode"
                onClick={() => {
                  navigator.clipboard.writeText(referralCode);
                }}
              >
                <p>REFERRAL CODE : {referralCode}</p>
              </div>
              <a href={mailToString}>SHARE</a>
            </>
          )
        ) : null}
        <Stack spacing={2} sx={{ my: 3 }}>
          <TextField
            name="firstName"
            value={firstName}
            label="First name"
            onChange={this.handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            disabled={!isActive}
          />
          <TextField
            name="lastName"
            value={lastName}
            label="Last name"
            onChange={this.handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            disabled={!isActive}
          />
          <TextField
            name="email"
            value={email}
            label="Email address"
            onChange={this.handleChange}
            error={!!errors.email}
            helperText={errors.email}
            disabled={!isActive}
          />

          <MobileDatePicker
            name="birthDate"
            value={birthdate}
            label="Date of birth"
            onChange={this.handleDate}
            error={!!errors.birthdate}
            helperText={errors.birthdate}
            disabled={!isActive}
            renderInput={(params) => <TextField {...params} />}
          />

          <FormControl>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              name="gender"
              value={gender}
              label="Gender"
              onChange={this.handleChange}
              disabled={!isActive}
              error={!!errors.gender}
            >
              {genderOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.gender}>
              {errors.gender}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="union-select-label">Union</InputLabel>
            <Select
              labelId="union-select-label"
              name="union"
              value={union}
              label="Union"
              onChange={this.handleChange}
              disabled={!isActive}
              error={!!errors.unionId}
            >
              {unionOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.unionId}>
              {errors.unionId}
            </FormHelperText>
          </FormControl>

          <TextField
            name="local"
            value={local}
            label="Local"
            onChange={this.handleChange}
            error={!!errors.local}
            helperText={errors.local}
            disabled={!isActive}
          />

          <TextField
            name="numberOfDependents"
            value={numberOfDependents}
            label="Number of Dependents"
            onChange={this.handleChange}
            error={!!errors.numberOfDependents}
            helperText={errors.numberOfDependents}
            disabled={!isActive}
          />

          {match.params.id === auth.user.id ? null : (
            <FormControl>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                name="role"
                value={role}
                label="Role"
                onChange={this.handleChange}
                error={!!errors.roleId}
                disabled={
                  !isActive ||
                  !['SUPER', 'WEBSITE ADMIN', 'USER ADMIN'].includes(
                    this.props.auth.user.role
                  )
                }
              >
                {roleOptions?.map((item) =>
                  this.showAvailableRoles(
                    this.props.auth.user.role,
                    item.label
                  ) ? (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ) : null
                )}
              </Select>
              <FormHelperText error={!!errors.roleId}>
                {errors.roleId}
              </FormHelperText>
            </FormControl>
          )}

          <FormControl>
            <InputLabel id="annualIncome-select-label">
              Annual Income
            </InputLabel>
            <Select
              labelId="annualIncome-select-label"
              name="annualIncome"
              value={annualIncome}
              label="Annual Income"
              onChange={this.handleChange}
              error={!!errors.annualIncome}
            >
              {annualIncomeOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.annualIncome}>
              {errors.annualIncome}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="employmentStatus-select-label">
              Employment Status
            </InputLabel>
            <Select
              labelId="employmentStatus-select-label"
              name="employmentStatus"
              value={employmentStatus}
              label="Employment Status"
              onChange={this.handleChange}
              error={!!errors.categoryId}
            >
              {employmentOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.categoryId}>
              {errors.categoryId}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="unionRole-select-label">Union Role</InputLabel>
            <Select
              labelId="unionRole-select-label"
              name="unionRole"
              value={unionRole}
              label="Union Role"
              onChange={this.handleChange}
              error={!!errors.unionRole}
            >
              {unionRoleOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.unionRole}>
              {errors.unionRole}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="wirelessCarrier-select-label">
              Wireless Carrier
            </InputLabel>
            <Select
              labelId="wirelessCarrier-select-label"
              name="wirelessCarrier"
              value={wirelessCarrier}
              label="Wireless Carrier"
              onChange={this.handleChange}
              error={!!errors.wirelessCarrier}
            >
              {wirelessCarrierOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.wirelessCarrier}>
              {errors.wirelessCarrier}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="vehicleMake-select-label">
              Make of Vehicle
            </InputLabel>
            <Select
              labelId="vehicleMake-select-label"
              name="vehicleMake"
              value={vehicleMake}
              label="Make of Vehicle"
              onChange={this.handleChange}
              error={!!errors.vehicleMake}
            >
              {vehicleMakeOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.vehicleMake}>
              {errors.vehicleMake}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="leaseOrFinance-select-label">
              Do you lease or finance your vehicle?
            </InputLabel>
            <Select
              labelId="leaseOrFinance-select-label"
              name="leaseOrFinance"
              value={leaseOrFinance}
              label="Do you lease or finance your vehicle?"
              onChange={this.handleChange}
              error={!!errors.leaseOrFinance}
            >
              {leaseOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.leaseOrFinance}>
              {errors.leaseOrFinance}
            </FormHelperText>
          </FormControl>

          <MobileDatePicker
            name="vehicleLeaseExpirationDate"
            value={vehicleLeaseExpirationDate}
            label="When is your lease up?"
            onChange={this.handleLeaseUp}
            error={!!errors.vehicleLeaseExpirationDate}
            helperText={errors.vehicleLeaseExpirationDate}
            renderInput={(params) => <TextField {...params} />}
          />

          <FormControl>
            <InputLabel id="vehicleInsuranceCompany-select-label">
              What company do you use for your vehicle's insurance?
            </InputLabel>
            <Select
              labelId="vehicleInsuranceCompany-select-label"
              name="vehicleInsuranceCompany"
              value={vehicleInsuranceCompany}
              label="What company do you use for your vehicle's insurance?"
              onChange={this.handleChange}
              error={!!errors.vehicleInsuranceCompany}
            >
              {autoInsuranceOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.vehicleInsuranceCompany}>
              {errors.vehicleInsuranceCompany}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="homeOwnership-select-label">
              Do you rent or own your home?
            </InputLabel>
            <Select
              labelId="homeOwnership-select-label"
              name="homeOwnership"
              value={homeOwnership}
              label="Do you rent or own your home?"
              onChange={this.handleChange}
              error={!!errors.homeOwnership}
            >
              {rentOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.homeOwnership}>
              {errors.homeOwnership}
            </FormHelperText>
          </FormControl>

          <MobileDatePicker
            name="mortgageRenewalDate"
            value={mortgageRenewalDate}
            label="When is your lease up?"
            onChange={this.handlemortgageRenewal}
            error={!!errors.mortgageRenewalDate}
            helperText={errors.mortgageRenewalDate}
            renderInput={(params) => <TextField {...params} />}
          />

          <FormControl>
            <InputLabel id="homeInsuranceCompany-select-label">
              What company do you use for your home, condo or renter’s
              insurance?
            </InputLabel>
            <Select
              labelId="homeInsuranceCompany-select-label"
              name="homeInsuranceCompany"
              value={homeInsuranceCompany}
              label="What company do you use for your home, condo or renter’s insurance?"
              onChange={this.handleChange}
              error={!!errors.homeInsuranceCompany}
            >
              {insuranceCompanyOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.homeInsuranceCompany}>
              {errors.homeInsuranceCompany}
            </FormHelperText>
          </FormControl>

          {/* home address */}
          <TextField
            name="streetAddress"
            value={streetAddress}
            label="Home Street Address"
            onChange={this.handleChange}
            error={!!errors.streetAddress}
            helperText={errors.streetAddress}
            disabled={!isActive}
          />
          <TextField
            name="appartment"
            value={appartment}
            label="Home Apartment Number"
            onChange={this.handleChange}
            error={!!errors.appartment}
            helperText={errors.appartment}
            disabled={!isActive}
          />

          <FormControl>
            <InputLabel id="province-select-label">
              Province
            </InputLabel>
            <Select
              labelId="province-select-label"
              name="province"
              value={province}
              label="Province"
              onChange={this.handleChange}
              error={!!errors.province}
            >
              {provinceOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.province}>
              {errors.province}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="city-select-label">City</InputLabel>
            <Select
              labelId="city-select-label"
              name="city"
              value={city}
              label="City"
              onChange={this.handleChange}
              error={!!errors.city}
            >
              {cityOptions?.map((item) => (
                <MenuItem key={item.en} value={item.en}>
                  {item.en}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.city}>{errors.city}</FormHelperText>
          </FormControl>

          <TextField
            name="postalCode"
            value={postalCode}
            label="Postal Code"
            onChange={this.handleChange}
            error={!!errors.postalCode}
            helperText={errors.postalCode}
            disabled={!isActive}
          />

          <FormControl>
            <InputLabel id="country-select-label">Country</InputLabel>
            <Select
              labelId="country-select-label"
              name="country"
              value={country}
              label="Country"
              onChange={this.handleChange}
              error={!!errors.country}
            >
              {countryOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.country}>
              {errors.country}
            </FormHelperText>
          </FormControl>

          {/* home address */}
          <TextField
            name="unionStreetAddress"
            value={unionStreetAddress}
            label="Union Street Address"
            onChange={this.handleChange}
            error={!!errors.unionStreetAddress}
            helperText={errors.unionStreetAddress}
            disabled={!isActive}
          />
          <TextField
            name="unionAppartment"
            value={unionAppartment}
            label="Union Apartment Number"
            onChange={this.handleChange}
            error={!!errors.unionAppartment}
            helperText={errors.unionAppartment}
            disabled={!isActive}
          />

          <FormControl>
            <InputLabel id="unionProvince-select-label">
              Union Province
            </InputLabel>
            <Select
              labelId="unionProvince-select-label"
              name="unionProvince"
              value={unionProvince}
              label="Union Province"
              onChange={this.handleChange}
              error={!!errors.unionProvince}
            >
              {provinceOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.unionProvince}>
              {errors.unionProvince}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="unionCity-select-label">Union City</InputLabel>
            <Select
              labelId="unionCity-select-label"
              name="unionCity"
              value={unionCity}
              label="Union City"
              onChange={this.handleChange}
              error={!!errors.unionCity}
            >
              {cityOptions?.map((item) => (
                <MenuItem key={item.en} value={item.en}>
                  {item.en}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.unionCity}>
              {errors.unionCity}
            </FormHelperText>
          </FormControl>

          <TextField
            name="unionPostalCode"
            value={unionPostalCode}
            label="Union Postal Code"
            onChange={this.handleChange}
            error={!!errors.unionPostalCode}
            helperText={errors.unionPostalCode}
            disabled={!isActive}
          />

          <FormControl>
            <InputLabel id="unionCountry-select-label">
              Union Country
            </InputLabel>
            <Select
              labelId="unionCountry-select-label"
              name="unionCountry"
              value={unionCountry}
              label="Union Country"
              onChange={this.handleChange}
              error={!!errors.unionCountry}
            >
              {countryOptions?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.unionCountry}>
              {errors.unionCountry}
            </FormHelperText>
          </FormControl>

          {match.params.id ? null : (
            <TextField
              name="password"
              value={password}
              label="Password"
              onChange={this.handleChange}
              error={!!errors.password}
              helperText={errors.password}
              disabled={!isActive}
            />
          )}
        </Stack>

        {this.state.id && allowedRoles.includes(this.props.auth.user.role) && (
          <div className="input-cntr">
            <Button
              text="Adjust Cashback Balance"
              onClick={this.handleAdjustUserCashbackBalance}
            />
          </div>
        )}

        {loading ? (
          <Spinner />
        ) : (
          <Button text="Save changes" onClick={this.handleSubmit} />
        )}

        {this.state.adjustUserCashbackBalance && (
          <AdjustUserCashbackBalance
            open={this.state.adjustUserCashbackBalance}
            closeHandler={this.adjustUserCashbackBalanceFinishHandler}
            userId={this.state.id}
          />
        )}
      </div>
    );
  }
}

EditUser.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

import React from "react";
import PropTypes from "prop-types";
import css from "./SubmissionPreview.module.scss";

const Question = ({ question, index }) => {
  const html = { __html: question.questionText };
  console.log(question);
  return (
    <div
      style={{
        margin: "10px",
        borderBottom: "1px solid #efefef",
        padding: "20px",
      }}
    >
      <span>{question.ip}</span>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: "-25px", marginRight: "10px" }}>
          <strong>{index + 1}.</strong>
        </div>
        <div dangerouslySetInnerHTML={html}></div>
      </div>
      <div>
        <strong>Answer:</strong> {question.text}
      </div>
    </div>
  );
};

function SubmissionPreview({ data }) {
  const questionList = data.answers.map((el, index) => {
    return <Question key={el.id} index={index} question={el} />;
  });

  return (
    <div className={css.container}>
      <div>
        <strong>ip:</strong> {data.ip}
      </div>
      <div>
        <strong>username:</strong>{" "}
        {data.userEmail ? data.userEmail : "User was not logged in."}
      </div>
      {questionList}
    </div>
  );
}

SubmissionPreview.propTypes = {};

export default SubmissionPreview;

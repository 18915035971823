import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';

const normalizeFloat = (value) =>
  `${
    Math.round((typeof value === 'string' ? parseFloat(value) : value) * 100) /
    100
  }`;

export default function CashbackField({
  value,
  handleChange,
  errorMessage,
  label,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [cashbackValue, setCashbackValue] = useState('0');
  const [systemCurrency, setSystemCurrency] = useState('CAD');

  useEffect(() => {
    setCashbackValue(normalizeFloat(value || '0'));
  }, [value]);

  const handleInputChange = useCallback((event) => {
    const realCashbackValue = normalizeFloat(event.target.value || '0');
    if(realCashbackValue >= 0) {
      setCashbackValue(realCashbackValue);
      handleChange(parseFloat(realCashbackValue));
    }
  }, [handleChange]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`/system/system-currency`);
        setSystemCurrency(res.data);
      } catch (err) {
        setError({ message: 'Cannot fetch system currency' });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, []);
  return (
    <>
      <TextField
        name="adjustValue"
        label={label}
        value={cashbackValue}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">{systemCurrency}</InputAdornment>,
        }}
        disabled={isLoading}
        inputProps={{ type: 'number', inputMode: 'numeric' }}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  );
}

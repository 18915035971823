import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from "../../Inputs/Spinner";
import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import css from "./List.module.scss";

const CategoryList = ({ data, loading, ent }) => {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    setListData(data);
  }, [data]);

  const headings = ["Name", "Actions"];

  return (
    <div className={css.list_container}>
      <ListHeader headings={headings} />
      <ul>
        {listData ? (
          listData.map((item) => (
            <ListItem key={item.id} item={item} ent={ent} />
          ))
        ) : (
          <Spinner />
        )}
      </ul>
    </div>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CategoryList;

/* eslint-disable react/no-did-update-set-state */
import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import slugify from 'slugify';
import Builder from 'src/components/Builder';
import { getBusinesses } from 'src/redux/actions/businessActions';
import { getCategories } from 'src/redux/actions/categoryActions';
import { getAllDiscounts } from 'src/redux/actions/discountActions';
import { getMediaLibrary } from 'src/redux/actions/mediaActions';
import { getPolls } from 'src/redux/actions/pollActions';
import { a11yProps, isEmpty } from '../../../utils';
import ImageSelect from '../../Inputs/ImageSelect';
import Spinner from '../../Inputs/Spinner';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import TabPanel from '../../Layout/TabPanel';
import ConfirmDialog from '../common/ConfirmDialog';
import SaveConfirmationRedirectDialog from '../common/SaveConfirmationRedirectDialog';
import SEOFields from '../common/SEOFields';
import css from './Page.module.scss';

const publishOptions = [
  { value: false, label: 'Not published' },
  { value: true, label: 'Published' },
];

const visibilityOptions = [
  { value: false, label: 'Private' },
  { value: true, label: 'Public' },
];

const navListedOptions = [
  { value: false, label: "Don't show in navigation" },
  { value: true, label: 'Show in navigation' },
];

export class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      tab: 'en',
      showTitle: true,
      isHomepage: false,
      metaDescription: '',
      twitterSummary: '',
      openGraphTitle: '',
      openGraphType: '',
      openGraphUrl: '',
      openGraphDescription: '',
      //   Page data
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      published: true,
      visibility: true,
      navListed: false,
      startDate: null,
      endDate: null,
      slug: '',
      title: '',
      title_fr: '',
      content: [],
      loading: false,

      // Selected Tab
      selectedTab: 0,

      savedPage: false,
      confirmHomepageSelection: false,
      unsavedContent: false,
      askForRedirect: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { mode } = this.props.match.params;

    if (prevProps.match.params.mode !== mode) {
      window.location.reload();
    }
  };

  componentDidMount = async () => {
    const { mode, id } = this.props.match.params;
    if (mode === 'edit' && id) {
      this.setState({ loading: true });
      try {
        const res = await axios.get(`/pages/${id}`);
        this.updateState(res.data);
      } catch (err) {
        this.setState({ err: err.response.data });
      }
    }

    this.props.getPolls();
    this.props.getAllCategories();
    this.props.getMedia();
    this.props.getDiscounts();
    this.props.getBusinesses();
  };

  updateState = (page) => {
    this.setState({
      isHomepage: page.isHomepage,
      thumbnail: page.thumbnail,
      thumbnail_fr: page.thumbnail_fr,
      headerImage: page.headerImage,
      headerImageMobile: page.headerImageMobile,
      headerImage_fr: page.headerImage_fr,
      headerImageMobile_fr: page.headerImageMobile_fr,
      published: publishOptions.find((el) => el.value === page.published).value,
      visibility: visibilityOptions.find((el) => el.value === page.public)
        .value,
      navListed: navListedOptions.find((el) => el.value === page.navListed)
        .value,
      startDate: page.startDate ? moment(page.startDate).startOf('day') : null,
      endDate: page.endDate ? moment(page.endDate).endOf('day') : null,
      slug: page.slug,
      title: page.title,
      title_fr: page.title_fr,
      content: page.content
        ? page.content.map((item, index) => ({
            ...item,
            id: index,
            index,
            inBasket: true,
          }))
        : [],
      metaDescription: page.metaDescription,
      twitterSummary: page.twitterSummary,
      openGraphTitle: page.openGraphTitle,
      openGraphType: page.openGraphType,
      openGraphUrl: page.openGraphUrl,
      openGraphDescription: page.openGraphDescription,
      loading: false,
      savedPage: page.isHomepage,
      showTitle: page.showTitle,
    });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };
  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBuilder = (content) => {
    this.setState({
      content,
      unsavedContent: true,
    });
  };

  handleClearErrors = () => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, content: [] },
    }));
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  handleSelectPublish = (event) => {
    this.setState({ published: event.target.value });
  };

  handleSelectVisibility = (event) => {
    this.setState({ visibility: event.target.value });
  };

  handleSelectNavListed = (event) => {
    this.setState({ navListed: event.target.value });
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleSubmit = async () => {
    const { snackbar } = this.props;
    const { mode, id } = this.props.match.params;
    const {
      isHomepage,
      title,
      title_fr,
      content,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      slug,
      startDate,
      endDate,
      published,
      navListed,
      visibility,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      openGraphDescription,
      showTitle,
    } = this.state;

    const payload = {
      isHomepage,
      title: title,
      title_fr: title_fr,
      content: content,
      headerImage: !isEmpty(headerImage) ? headerImage.id : undefined,
      headerImageMobile: !isEmpty(headerImageMobile)
        ? headerImageMobile.id
        : undefined,
      headerImage_fr: !isEmpty(headerImage_fr) ? headerImage_fr.id : undefined,
      headerImageMobile_fr: !isEmpty(headerImageMobile_fr)
        ? headerImageMobile_fr.id
        : undefined,
      slug: mode === 'edit' ? undefined : slug,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      published: published,
      navListed: navListed,
      public: visibility,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      openGraphDescription,
      showTitle,
    };

    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/pages/${id}` : `/pages`;

    const imagePayload = new FormData();
    if (typeof headerImage !== 'string')
      imagePayload.append('headerImage', headerImage);
    if (typeof headerImage_fr !== 'string')
      imagePayload.append('headerImage_fr', headerImage_fr);

    try {
      if (
        imagePayload.has('headerImage') ||
        imagePayload.has('headerImage_fr')
      ) {
        const imageRes = await axios.post(`/files`, imagePayload);
        Object.assign(payload, imageRes.data);
        this.setState({ fileUploading: false });
      }
      const res = await axios[requestMethod](path, payload);
      this.setState({
        savedPage: isHomepage,
        unsavedContent: false,
        askForRedirect: true,
        errors: {},
        id: res.data.id,
      });
      snackbar.setSnackbarMessage('Success');
    } catch (error) {
      const obj = error.response.data;
      if (obj.hasOwnProperty('content')) {
        this.setState({ selectedTab: 2 });
      }
      if (obj.hasOwnProperty('title_fr')) {
        this.setState({ tab: 'fr', selectedTab: 0 });
      }
      if (
        obj.hasOwnProperty('title') ||
        obj.hasOwnProperty('headerImage') ||
        obj.hasOwnProperty('headerImageMobile')
      ) {
        this.setState({ tab: 'en', selectedTab: 0 });
      }
      this.setState({ errors: error.response.data });

      snackbar.setSnackbarMessage("There's an error, please check your input.");
    }
  };

  handleDelete = async () => {
    const { id } = this.props.match.params;
    const { history } = this.props;
    try {
      await axios.delete(`/pages/${id}`);
      history.push('/dashboard/pages');
    } catch (err) {
      this.setState({ errors: err.response.data });
    }
  };

  handleDeleteButton = () => {
    const { deleteMode, savedPage, isHomepage } = this.state;
    if (deleteMode) {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={this.handleDelete}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={() => this.setState({ deleteMode: false })}
          >
            Cancel
          </Button>
        </Stack>
      );
    }
    return (
      <Box textAlign="right">
        {savedPage && isHomepage ? (
          <FormHelperText error>
            You can not delete a page that's used as homepage
          </FormHelperText>
        ) : null}
        <Button
          variant="contained"
          color="error"
          onClick={() => this.setState({ deleteMode: true })}
          disabled={savedPage && isHomepage}
        >
          Delete
        </Button>
      </Box>
    );
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  render() {
    const {
      errors,
      isHomepage,
      title,
      title_fr,
      content,
      slug,
      tab,
      startDate,
      endDate,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      published,
      visibility,
      navListed,
      loading,
      selectedTab,
      unsavedContent,
      askForRedirect,
      savedPage,
      confirmHomepageSelection,
      showTitle,
    } = this.state;

    const { mode } = this.props.match.params;

    const isError = !isEmpty(errors);

    let titleErr;
    if (isError) {
      titleErr = tab === 'fr' ? errors.title_fr : errors.title;
    }

    const domain = process.env.REACT_APP_WEB_DOMAIN;
    if (!loading)
      return (
        <div className={css.pageContainer}>
          <Prompt
            when={unsavedContent}
            message={(location) =>
              `You have unsaved content. Are you sure you want to go to ${location.pathname}`
            }
          />

          <ConfirmDialog
            confirm={confirmHomepageSelection}
            message="Please confirm to use this as homepage?"
            onConfirm={() => {
              this.setState({
                confirmHomepageSelection: false,
                isHomepage: true,
              });
            }}
            onCancel={() =>
              this.setState({
                confirmHomepageSelection: false,
                isHomepage: false,
              })
            }
          />

          <SaveConfirmationRedirectDialog
            askForRedirect={askForRedirect}
            id={this.state.id}
            type="pages"
            mode={mode}
            clearState={() => this.setState({ askForRedirect: false })}
          />

          <h1>{mode === 'add' ? 'New page' : 'Edit page'}</h1>

          <p>
            <strong>Url: </strong>
            {`${domain}/page/${slugify(slug, {
              lower: true,
            })}`}
          </p>

          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />

          <Tabs value={selectedTab} onChange={this.handleChangeTab}>
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="Publishing" {...a11yProps(1)} />
            <Tab label="Content" {...a11yProps(2)} />
            <Tab label="Seo" {...a11yProps(3)} />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <Box sx={{ pb: 2 }}>
              <TextField
                value={tab === 'en' ? title : title_fr}
                name={tab === 'en' ? 'title' : 'title_fr'}
                label={`Post title (${tab})`}
                placeholder="New page"
                required
                className="input-lg"
                onChange={this.handleChange}
                error={!!titleErr}
                helperText={titleErr}
                inputProps={{ maxLength: 100 }}
              />
            </Box>
            <FormControlLabel
              sx={{ marginBottom: 2 }}
              control={
                <Switch
                  checked={showTitle}
                  onChange={(event) => {
                    this.setState({ showTitle: event.target.checked });
                  }}
                />
              }
              label="Show title"
              labelPlacement="end"
            />
            <Box sx={{ pb: 2 }}>
              <TextField
                value={slug}
                name="slug"
                label="Page url"
                placeholder="url"
                className="input-lg"
                onChange={this.handleChange}
                disabled={mode === 'edit' ? true : false}
                error={errors.slug}
                helperText={errors.slug}
              />
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl>
                <InputLabel id="navListed-select-label">
                  Show in navigation
                </InputLabel>
                <Select
                  labelId="navListed-select-label"
                  name="navListed"
                  value={navListed}
                  label="Show in navigation"
                  onChange={this.handleSelectNavListed}
                  error={errors.navListed}
                >
                  {navListedOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <FormControlLabel
              sx={{ marginTop: 2 }}
              control={
                <Switch
                  disabled={isHomepage && savedPage}
                  checked={isHomepage}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.setState({ confirmHomepageSelection: true });
                    } else {
                      this.setState({ isHomepage: false });
                    }
                  }}
                />
              }
              label="Use this as the homepage"
              labelPlacement="end"
            />

            <ImageSelect
              initialImage={headerImage}
              label="Header image (en)"
              name="headerImage"
              type="Page Header"
              handleSelect={this.handleSelectImage}
              error={errors.headerImage}
            />

            <ImageSelect
              initialImage={headerImageMobile}
              label="Header image - Mobile (en)"
              name="headerImageMobile"
              type="Mobile"
              handleSelect={this.handleSelectImage}
              error={errors.headerImageMobile}
            />
            <ImageSelect
              initialImage={headerImage_fr}
              label="Header image (fr)"
              name="headerImage_fr"
              type="Page Header"
              handleSelect={this.handleSelectImage}
            />
            <ImageSelect
              initialImage={headerImageMobile_fr}
              label="Header image - Mobile (fr)"
              name="headerImageMobile_fr"
              type="Mobile"
              handleSelect={this.handleSelectImage}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel id="published-select-label">
                  Publishing options
                </InputLabel>
                <Select
                  labelId="published-select-label"
                  name="published"
                  value={published}
                  label="Publishing options"
                  onChange={this.handleSelectPublish}
                  error={errors.published}
                >
                  {publishOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="visibility-select-label">Visibility</InputLabel>
                <Select
                  labelId="visibility-select-label"
                  value={visibility}
                  name="visibility"
                  label="Visibility"
                  onChange={this.handleSelectVisibility}
                  error={errors.public}
                >
                  {visibilityOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <MobileDatePicker
                name="startDate"
                value={startDate}
                label="Start date"
                onChange={this.handleStartDate}
                error={errors.startDate}
                maxDate={endDate}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />

              <MobileDatePicker
                name="endDate"
                value={endDate}
                label="End date"
                onChange={this.handleEndDate}
                error={errors.endDate}
                minDate={moment()}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Builder
              errors={errors.content}
              clearModuleError={askForRedirect}
              value={content}
              handleChange={this.handleBuilder}
              clearErrors={this.handleClearErrors}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <SEOFields
              value={this.state}
              errors={this.state.errors}
              handleChange={this.handleChange}
            />
          </TabPanel>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
          >
            <Button variant="contained" onClick={this.handleSubmit}>
              {mode === 'edit' ? 'Save changes' : 'Create page'}
            </Button>
            {mode === 'edit' ? this.handleDeleteButton() : null}
          </Stack>
        </div>
      );

    return <Spinner />;
  }
}

Page.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getDiscounts: () => dispatch(getAllDiscounts()),
  getBusinesses: () => dispatch(getBusinesses()),
  getAllCategories: () => dispatch(getCategories(null, true)),
  getPolls: () => dispatch(getPolls()),
  getMedia: () => dispatch(getMediaLibrary()),
});

export default connect(null, mapDispatchToProps)(Page);

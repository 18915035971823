import { Button } from '@mui/material';
import axios from 'axios';
import 'cropperjs/dist/cropper.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { isEmpty } from '../../utils';
import Spinner from '../Inputs/Spinner';
import css from './NewFileUpload.module.scss';

function NewFileUpload({
  label,
  imgType,
  getImageUrl,
  getFile,
  imgPreview,
  ar,
  type,
}) {
  const [errors, setErrors] = useState({});
  const [showCropper, setShowCropper] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState('');
  const [preview, setPreview] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    if (imgPreview) setPreview(imgPreview);

    return function cleanup() {
      setPreview(null);
    };
  }, [imgPreview]);

  useEffect(() => {
    setAspectRatio(ar);
  }, [ar]);

  useEffect(() => {
    getFile(file);
    return () => {
      getFile(null);
    };
  }, [file, getFile]);

  useEffect(() => {
    handleCancel();
  }, [type]);

  const [loading, setLoading] = useState(false);

  const handleSelectFile = (e) => {
    setErrors({});
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1024 >= 1024) {
        setErrors('File size must be under 1MB.');
        setFile(null);
        setFileName('');
        setShowCropper(false);
      } else if (
        e.target.files[0].type !== 'image/png' &&
        e.target.files[0].type !== 'image/jpeg' &&
        e.target.files[0].type !== 'image/jpg'
      ) {
        setErrors('Incorrect file type. Please select a png, jpg or jpeg.');
        setFile(null);
        setFileName('');
        setShowCropper(false);
      } else {
        setPreview(null);
        const reader = new FileReader();
        reader.addEventListener('load', () => setFile({ file: reader.result }));
        if (type) {
          setFileName(e.target.files[0].name);
          setFile(window.URL.createObjectURL(e.target.files[0]));
          setShowCropper(true);
        } else {
          handleSave(
            window.URL.createObjectURL(e.target.files[0]),
            e.target.files[0].name
          );
        }
      }
    }
  };

  const handleSave = async (selectedFile, selectedFileName) => {
    setLoading(true);
    const path = `/files`;
    const payload = new FormData();

    const config = {
      params: { crop: showCropper },
    };

    const blob = await fetch(
      typeof selectedFile === 'string' ? selectedFile : file
    ).then((r) => r.blob());
    blob.lastModifiedDate = new Date();
    payload.append(
      `${imgType}`,
      blob,
      selectedFileName ? selectedFileName : filename
    );

    if (type) {
      payload.append(`${imgType}_left`, canvas.x);
      payload.append(`${imgType}_top`, canvas.y);
      payload.append(`${imgType}_width`, canvas.width);
      payload.append(`${imgType}_height`, canvas.height);
    }
    try {
      const res = await axios.post(path, payload, config);
      setLoading(false);
      setFile(null);
      setFileName('');
      setPreview(res.data[imgType]);
      getImageUrl(res.data[imgType]);
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const handleCancel = () => {
    setFile(null);
    setFileName('');
    setPreview(null);
    setShowCropper(false);
    document.getElementById('img-uploader').value = '';
  };

  //  Cropper
  const [canvas, setCanvas] = useState(null);
  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    setCanvas(cropper.getData(true));
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  const renderCropper = () => {
    if (showCropper && file)
      return (
        <div className={css.cropper_container}>
          <Cropper
            src={file}
            style={{ height: 300, width: '100%' }}
            // Cropper.js options
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            initialAspectRatio={aspectRatio}
            aspectRatio={aspectRatio}
            viewMode={1}
          />

          <div className={css.btn_container}>
            <Button variant="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save crop</Button>
          </div>
        </div>
      );
  };

  if (loading) return <Spinner />;

  return (
    <div className={css.container}>
      <label>{label}</label>
      <input
        id="img-uploader"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleSelectFile}
      />

      <div className={css.preview_container}>
        {preview ? <img className={css.preview} src={preview} alt="" /> : null}
      </div>

      {renderCropper()}

      {/* {showCropper ? <hr /> : null} */}

      {!isEmpty(errors) ? <small className={css.errors}>{errors}</small> : null}
    </div>
  );
}

NewFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  imgType: PropTypes.string.isRequired, // Thumbnail or Header
  getImageUrl: PropTypes.func.isRequired,
  imgPreview: PropTypes.string,
  ar: PropTypes.number,
};

export default NewFileUpload;

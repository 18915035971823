import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomMUISelect from '../components/CustomMUISelect';

const PollModule = ({ pollId, handleChange, showError }) => {
  const pollsState = useSelector((state) => state.polls);
  return (
    <CustomMUISelect
      name="pollId"
      label="Poll"
      value={pollId}
      onChange={handleChange}
      options={pollsState.polls}
      itemValue="id"
      itemLabel="name"
      error={showError && !pollId}
    />
  );
};

export default PollModule;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;

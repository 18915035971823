// Fetch pages
// Display a page list
// Create new pages
// Delete pages (list)

// stateless?

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPosts } from "../../../redux/actions/postActions";
import Header from "../../Layout/Header";
import PostList from "./PostList";
import Pagination from "../../Layout/Pagination";
import PostSearch from "./PostSearch";

const Posts = ({ posts, getPosts, errors, history, match }) => {
  const { results, totalResults } = posts;

  const [search, setSearch] = useState({
    name: "",
    orderType: "ASC",
    orderField: "title",
  });

  const [page, setPage] = useState(1);

  useEffect(() => {
    getPosts();
  }, [getPosts]);
  const { name, orderType, orderField } = search;

  const orderString = `${orderField.value},${orderType.value}`;

  const changePage = (page) => {
    getPosts(page, name, orderString);
    setPage(page++);
  };

  const handleSearch = (e, name, order) => {
    e.preventDefault();
    setPage(1);
    getPosts(1, name, order);
    history.push("/dashboard/posts/");
  };

  return (
    <div>
      <Header
        title="Posts"
        btnText="New Post"
        btnLink
        url="/dashboard/posts/add"
      />
      <PostSearch
        handleSearch={handleSearch}
        setSearch={setSearch}
        search={search}
      />
      <PostList posts={results} errors={errors} />
      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={page}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};

Posts.propTypes = {
  posts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getPosts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  posts: state.posts.posts,
  loading: state.posts.loading,
  errors: state.errors,
});

const mapDispatchToProps = {
  getPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);

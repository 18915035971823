import React from "react";
import PropTypes from "prop-types";
import css from "./ImageThumbnail.module.scss";

function ImageThumbnail({ image, onClick }) {
  const style = { background: `url(${image.url})` };
  return (
    <div onClick={() => onClick(image)} className={css.container}>
      <img src={image.url} />
      <div className={css.overlay}>
        <span>
          {image.label.length > 25
            ? `${image.label.slice(0, 10)}... ${image.label.slice(
                image.label.length - 10,
                image.label.lenght
              )}`
            : image.label}
        </span>
      </div>
    </div>
  );
}

ImageThumbnail.propTypes = {};

export default ImageThumbnail;

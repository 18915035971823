import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';

function SubcategoryListItem({ category, parentId }) {
  return (
    <li className="list-inner">
      - {category.name}
      <Category category={category} parentId={parentId} />
    </li>
  );
}

SubcategoryListItem.propTypes = {
  category: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
};

export default SubcategoryListItem;

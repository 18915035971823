import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export const Home = ({ auth: { isAuthenticated } }) => {
  if (!isAuthenticated)
    return (
      <div className='home'>
        <h1>Union Perks Plus CMS Login</h1>
        <p>Enter your email and password to log in.</p>
      </div>
    );

  return <Redirect to='/dashboard/' />;
};

Home.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import axios from 'axios';
import { GET_ERRORS, GET_POLLS } from '../types';

export const getPolls = () => async (dispatch) => {
  try {
    const res = await axios.get(`/polls`, {
      params: { dropdown: true },
    });
    dispatch({
      type: GET_POLLS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

import React from "react";
import PropTypes from "prop-types";
import Tag from "./../Tag";

function TagListItem({ tag, lang, fetchTags }) {
  return (
    <li>
      <div className="list-inner">
        {tag.text}
        <Tag tagData={tag} lang={lang} id={tag.id} fetchTags={fetchTags} />
      </div>
    </li>
  );
}

TagListItem.propTypes = {
  category: PropTypes.object.isRequired,
};

export default TagListItem;

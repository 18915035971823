import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'src/components/Inputs/ColorPicker';
import { getAllDiscounts } from 'src/redux/actions/discountActions';
import ReorderButtons from '../components/ReorderButtons';

const options = [
  {
    id: 'mostPopular',
    name: 'Most Popular',
  },
  {
    id: 'recentlyAdded',
    name: 'Recently Added',
  },
  {
    id: 'manual',
    name: 'Manual',
  },
];

const layoutOptions = [
  {
    id: 'smallThumbnails',
    name: 'Small Thumbnails',
  },
  {
    id: 'largeThumbnails',
    name: 'Large Thumbnails',
  },
  {
    id: 'twoColumns',
    name: 'Two Columns',
  },
];

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
`;

const limitOptions = Array.from({ length: 20 }, (e, i) => i + 1);

const CarouselModule = ({
  limit,
  variety,
  layout,
  discounts,
  title,
  title_fr,
  showError,
  handleChange,
  handleChangeRemoveError,
  handleLimitChange,
  handleVarietyChange,
  handleDiscountChange,
  handleColorChange,
  handleAddDiscount,
  handleRemoveItem,
  handleMoveDownItem,
  handleMoveUpItem,
}) => {
  const [business, setBusiness] = useState(null);
  const [discountText, setDiscountText] = useState('');

  const dispatch = useDispatch();

  const discountState = useSelector((state) => state.discounts);
  const businessState = useSelector((state) => state.business);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        dispatch(getAllDiscounts({ name: request.input }));
      }, 1000),
    []
  );

  useEffect(() => {
    fetch({ input: discountText });
  }, [discountText, fetch]);

  return (
    <>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="variety-select">Variant</InputLabel>
        <Select
          autoFocus
          labelId="variety-select"
          label="Type"
          value={variety}
          onChange={handleVarietyChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="limit-select">Limit</InputLabel>
        <Select
          labelId="limit-select"
          name="limit"
          label="Type"
          value={limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ margin: '15px 0' }}>
        <InputLabel id="layout-select">Layout</InputLabel>
        <Select
          labelId="layout-select"
          name="layout"
          label="Type"
          value={layout}
          onChange={handleChange}
        >
          {layoutOptions
            .filter((op) =>
              variety === 'manual' ? true : op.id !== 'twoColumns'
            )
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {layout === 'twoColumns' ? (
        <Stack
          spacing={2}
          alignItems="baseline"
          justifyContent="space-between"
          direction="row"
          sx={{ mb: '15px' }}
        >
          <Input
            error={showError && (!title || !title.trim())}
            label="Title"
            variant="outlined"
            name="title"
            value={title}
            onChange={handleChangeRemoveError}
            helperText={
              showError && (!title || !title.trim()) ? 'Required' : null
            }
          />
          <Input
            label="Title (French)"
            variant="outlined"
            name="title_fr"
            value={title_fr}
            onChange={handleChange}
          />
        </Stack>
      ) : null}
      {variety === 'manual' ? (
        <>
          <Stack sx={{ marginBottom: 2 }} direction="row" spacing={2}>
            {businessState.loading && <CircularProgress size={20} />}
            {businessState.businesses.rows ? (
              <Autocomplete
                disabled={limit === discounts.length}
                value={business}
                onChange={(event, newValue) => {
                  setBusiness(newValue);
                  dispatch(
                    getAllDiscounts({ business: newValue ? newValue.id : '' })
                  );
                }}
                getOptionLabel={(item) => item.name}
                id="combo-box-business"
                options={businessState.businesses.rows}
                sx={{ flex: 1 }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Business" />
                )}
              />
            ) : null}

            {discountState && discountState.allDiscounts ? (
              <Autocomplete
                disabled={limit === discounts.length}
                clearText={''}
                limitTags={0}
                value={null}
                onChange={(event, newValue) => {
                  handleDiscountChange(newValue, discounts.length);
                  setDiscountText('');
                }}
                inputValue={discountText}
                onInputChange={(event, newInputValue) => {
                  setDiscountText(newInputValue);
                }}
                id="combo-box-discount"
                getOptionLabel={(item) => item.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                options={discountState.allDiscounts.filter(
                  (option) => !discounts.find((op) => op.id == option.id)
                )}
                sx={{ flex: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`${discounts.length} Discount(s) Selected`}
                  />
                )}
              />
            ) : null}
          </Stack>
          {discounts
            ? discounts.map((discount, index) => (
                <Stack
                  key={index}
                  direction="row"
                  sx={{ padding: 1 }}
                  spacing={2}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <div style={{ width: 'calc(100% - 225px)' }}>
                    {discount.name}
                  </div>
                  {layout === 'twoColumns' ? (
                    <ColorPicker
                      color={discount.color}
                      handleChange={(value) => handleColorChange(value, index)}
                    />
                  ) : null}
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: 150 }}
                  >
                    <CloseIcon
                      onClick={() => {
                        handleRemoveItem(index);
                      }}
                    />
                    <ReorderButtons
                      index={index}
                      items={discounts}
                      handleMoveDownItem={handleMoveDownItem}
                      handleMoveUpItem={handleMoveUpItem}
                    />
                  </Stack>
                </Stack>
              ))
            : null}
        </>
      ) : null}
      {showError && variety === 'manual' && discounts.length < limit ? (
        <Alert severity="error">Please select {limit} discounts</Alert>
      ) : null}
    </>
  );
};

export default CarouselModule;

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_LOADING,
  SET_REFETCHING_TOKEN,
  SET_NOT_AUTHENTICATED,
  REMOVE_TOKEN,
  LOGOUT,
} from '../types';
import setAuthToken from '../../components/auth/setAuthToken';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  user: {},
  loading: true,
  refreshingToken: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      setAuthToken();
      return {
        ...state,
        isAuthenticated: true,
        user: payload.user ?? state.user,
        loading: false,
        refreshingToken: false,
        token: payload.token,
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      setAuthToken();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        token: null,
      };
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      setAuthToken();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        refreshingToken: false,
        token: null,
      };
    case SET_NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
      };
    case REMOVE_TOKEN:
      localStorage.removeItem('token');
      setAuthToken();
      return {
        ...state,
        token: null,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_REFETCHING_TOKEN:
      return {
        ...state,
        refreshingToken: payload,
      };
    default:
      return state;
  }
};

export default authReducer;

export const unionRoleOptions = [
  { label: "Union Member", value: "Union Member" },
  { label: "Retired Union Member", value: "Retired Union Member" },
  { label: "Spouse of Union Member", value: "Spouse of Union Member" },
  {
    label: "Child or other family member",
    value: "Child or other family member",
  },
  { label: "Union Leader", value: "Union Leader" },
];

export const employmentStatusOptions = [
  { label: "Full-Time", value: "Full-Time" },
  { label: "Part-Time", value: "Part-Time" },
  { label: "Contract/Freelance", value: "Contract/Freelance" },
  { label: "Retired", value: "Retired" },
  { label: "Unemployed", value: "Unemployed" },
];

export const annualIncomeOptions = [
  { label: "0-20000", value: "0-20000" },
  { label: "20000-50000", value: "20000-50000" },
  { label: "50000-100000", value: "50000-100000" },
  { label: "100000-200000", value: "100000-200000" },
];

export const wirelessCarrierOptions = [
  "Rogers Wireless",
  "Fido Mobile",
  "Chatr Mobile",
  "Cityfone",
  "Primus Wireless",
  "Zoomer Wireless",
  "SimplyConnest",

  "Telus Mobility",
  "Koodo Mobile",
  "Public Mobile",

  "Bell Mobility",
  "Virgin Mobile",
  "Lucky Mobile",
  "Solo Mobile",
  "Bell MTS",

  "Shaw Mobile",
  "Freedom Mobile",

  "Other",
  "I don't have a wireless carrier",
].map((el) => {
  return { label: el, value: el };
});

export const insuranceCompanyOptions = [
  "AIG Insurance Company of Canada",
  "Alberta Motor Association Insurance Company",
  "Algoma Mutual Insurance Company",
  "Allianz Global Risks Insurance Company",
  "Allstate Insurance Company of Canada",
  "Arch Insurance Canada Ltd.",
  "Atradius Credit Insurance N.V.",
  "Aviva Canada Inc.",
  "Aviva General Insurance Company",
  "Aviva Insurance Company of Canada",
  "AXIS Reinsurance Company",
  "BCAA Insurance Corporation",
  "Belair Insurance Company Inc.",
  "Brant Mutual Insurance Company",
  "CAA Insurance Company",
  "CCR RE",
  "Certas Direct Insurance Company",
  "Chubb Insurance Company of Canada",
  "Co-operators General Insurance Company",
  "Continental Casualty Company",
  "CorePointe Insurance Company",
  "COSECO Insurance Company",
  "CUMIS General Insurance Company",
  "Desjardins assurances générales Inc.",
  "Ecclesiastical Insurance Office PLC",
  "Echelon Insurance",
  "Economical Mutual Insurance Company",
  "Electric Insurance Company",
  "Elite Insurance Company",
  "Esurance Insurance Company of Canada",
  "Euler Hermes American Credit Indemnity Company",
  "Everest Insurance Company",
  "Federal Insurance Company of Canada",
  "Federated Insurance Company of Canada",
  "General Reinsurance Corporation",
  "Gore Mutual Insurance Company",
  "Groupe Ledor Inc. Mutuelle d’assurance",
  "Groupe Promutuel",
  "Hartford Fire Insurance Company",
  "HDI Global SE",
  "Howick Mutual Insurance Company",
  "Industrielle Alliance, Assurance auto et habitation inc.",
  "Insurance Company of Prince Edward Island (ICPEI)",
  "Intact Insurance Company",
  "JEVCO Insurance Company",
  "L’Alpha, compagnie d’assurance inc.",
  "L’Unique assurances générales inc.",
  "La Capitale assurances générales inc",
  "Lawyers’ Professional Indemnity Company",
  "Liberty Mutual Insurance Company",
  "Liberty Specialty Markets Bermuda Limited",
  "Lloyd's Underwriters",
  "MAX Insurance",
  "Mitsui Sumitomo Insurance Company Limited",
  "Motors Insurance Corporation",
  "Scotia",
  "Munich Reinsurance Company",
  "North Kent Mutual Fire Insurance",
  "Northbridge General Insurance Corporation",
  "Novex Insurance Company",
  "Odyssey America Reinsurance Corporation (Canadian Branch)",
  "Old Republic Insurance Company of Canada",
  "Omega General Insurance Company",
  "Pafco Insurance Company",
  "Pembridge Insurance Company",
  "Perth Insurance Company",
  "Pilot Insurance Company",
  "Primmum Insurance Company",
  "RSA Group",
  "S & Y Insurance Company",
  "SCOR Canada Reinsurance Company",
  "Scottish & York Insurance Co. Limited",
  "Security National Insurance Company",
  "Sentry Insurance, A Mutual Company",
  "Sirius America Insurance Company",
  "Sonnet Insurance Company",
  "SSQ Insurance Company Inc.",
  "St. Paul Fire and Marine Insurance Company",
  "Starr Insurance and Reinsurance Limited",
  "Swiss Reinsurance Company Ltd.",
  "TD Insurance",
  "Temple Insurance Company",
  "The American Road Insurance Company",
  "The Boiler Inspection and Insurance Company of Canada",
  "The Dominion of Canada General Insurance Company",
  "The Guarantee Company of North America",
  "The Missisquoi Insurance Company",
  "The Mutual Fire Insurance Company of British Columbia",
  "The Nordic Insurance Company of Canada",
  "The Personal General Insurance Inc.",
  "The Personal Insurance Company",
  "The Sovereign General Insurance Company",
  "Toa Reinsurance Company of America",
  "Tokio Marine & Nichido Fire Insurance Co., Ltd.",
  "Traders General Insurance Company",
  "Travelers Insurance Company of Canada",
  "Trisura Guarantee Insurance Company",
  "Triton Insurance Company",
  "Unica Insurance Incorporated",
  "Verassure Insurance Company",
  "Waterloo Insurance Company",
  "Wawanesa Mutual Insurance Company",
  "Westport Insurance Corporation",
  "Wynward Insurance Group",
  "XL Specialty Insurance Company",
  "Zenith Insurance Company",
  "Zurich Insurance Company Ltd.",
  "Other",
].map((el) => {
  return { label: el, value: el };
});

export const vehicleMakeOptions = [
  "Acura",
  "Alfa Romeo",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Daewoo",
  "Dodge",
  "Ferrari",
  "Fiat",
  "Ford",
  "GMC",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lamborghini",
  "Land Rover",
  "Lexus",
  "Lincoln",
  "Lotus",
  "Maserati",
  "Mazda",
  "Mercedes Benz",
  "Mercury",
  "Mini",
  "Mitsubishi",
  "Nissan",
  "Oldsmobile",
  "Plymoth",
  "Pontiac",
  "Porsche",
  "RAM",
  "Rolls-Royce",
  "Saab",
  "Saturn",
  "Scion",
  "Smart",
  "Subaru",
  "Suzuki",
  "Tesla",
  "Toyota",
  "Volkswagen",
  "Volvo",
].map((el) => {
  return { label: el, value: el };
});

export const vehicleOwnershipOptions = [
  { label: "Lease", value: "Lease" },
  { label: "Finance", value: "Finance" },
  { label: "Neither", value: "Neither" },
];

export const rentOptions = [
  { label: "Rent", value: "Rent" },
  { label: "Own with mortgage", value: "Own with mortgage" },
  { label: "Own (mortgage paid off)", value: "Own (mortgage paid off)" },
];

export const countryOptions = [{ label: "Canada", value: "Canada" }];
export const provinceOptions = [
  { label: "Alberta", value: "Alberta" },
  { label: "British Columbia", value: "British Columbia" },
  { label: "Manitoba", value: "Manitoba" },
  { label: "New Brunswick", value: "New Brunswick" },
  { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { label: "Nova Scotia", value: "Nova Scotia" },
  { label: "Ontario", value: "Ontario" },
  { label: "Prince Edward Island", value: "Prince Edward Island" },
  { label: "Quebec", value: "Quebec" },
  { label: "Saskatchewan", value: "Saskatchewan" },
];

export const countryOptionsUnionAddress = [
  { label: "Canada", value: "Canada" },
  { label: "Serbia", value: "Serbia" },
  { label: "India", value: "India" },
];
export const provinceOptionsUnionAddress = [
  { label: "Ontario", value: "Ontario" },
  { label: "Quebec", value: "Quebec" },
  { label: "British Columbia", value: "British Columbia" },
];

export const employmentOptions = [
  { label: "Full-Time", value: "Full-Time" },
  { label: "Part-Time", value: "Part-Time" },
  { label: "Contract/Freelance", value: "Contract/Freelance" },
  { label: "Retired", value: "Retired" },
  { label: "Unemployed", value: "Unemployed" },
];

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
  { label: "I'd rather not say", value: "I'd rather not say" },
];

export const leaseOptions = [
  { label: "Lease", value: "Lease" },
  { label: "Finance", value: "Finance" },
];

export const albertaOptions = [
  {
    en: "Banff",
    fr: "Banff",
  },
  {
    en: "Brooks",
    fr: "Brooks",
  },
  {
    en: "Calgary",
    fr: "Calgary",
  },
  {
    en: "Edmonton",
    fr: "Edmonton",
  },
  {
    en: "Fort McMurray",
    fr: "Fort McMurray",
  },
  {
    en: "Grande Prairie",
    fr: "Grande Prairie",
  },
  {
    en: "Jasper",
    fr: "Jasper",
  },
  {
    en: "Lake Louise",
    fr: "Lake Louise",
  },
  {
    en: "Lethbridge",
    fr: "Lethbridge",
  },
  {
    en: "Medicine Hat",
    fr: "Medicine Hat",
  },
  {
    en: "Red Deer",
    fr: "Red Deer",
  },
  {
    en: "Saint Albert",
    fr: "Saint Albert",
  },
];

export const bcOptions = [
  {
    en: "Barkerville",
    fr: "Barkerville",
  },
  {
    en: "Burnaby",
    fr: "Burnaby",
  },
  {
    en: "Campbell River",
    fr: "Campbell River",
  },
  {
    en: "Chilliwack",
    fr: "Chilliwack",
  },
  {
    en: "Courtenay",
    fr: "Courtenay",
  },
  {
    en: "Cranbrook",
    fr: "Cranbrook",
  },
  {
    en: "Dawson Creek",
    fr: "Dawson Creek",
  },
  {
    en: "Delta",
    fr: "Delta",
  },
  {
    en: "Esquimalt",
    fr: "Esquimalt",
  },
  {
    en: "Fort Saint James",
    fr: "Fort Saint James",
  },
  {
    en: "Fort Saint John",
    fr: "Fort Saint John",
  },
  {
    en: "Hope",
    fr: "Hope",
  },
  {
    en: "Kamloops",
    fr: "Kamloops",
  },
  {
    en: "Kelowna",
    fr: "Kelowna",
  },
  {
    en: "Kimberley",
    fr: "Kimberley",
  },
  {
    en: "Kitimat",
    fr: "Kitimat",
  },
  {
    en: "Langley",
    fr: "Langley",
  },
  {
    en: "Nanaimo",
    fr: "Nanaimo",
  },
  {
    en: "Nelson",
    fr: "Nelson",
  },
  {
    en: "New Westminster",
    fr: "New Westminster",
  },
  {
    en: "North Vancouver",
    fr: "Vancouver Nord",
  },
  {
    en: "Oak Bay",
    fr: "Oak Bay",
  },
  {
    en: "Penticton",
    fr: "Penticton",
  },
  {
    en: "Powell River",
    fr: "Powell River",
  },
  {
    en: "Prince George",
    fr: "Prince George",
  },
  {
    en: "Prince Rupert",
    fr: "Prince Rupert",
  },
  {
    en: "Quesnel",
    fr: "Quesnel",
  },
  {
    en: "Revelstoke",
    fr: "Revelstoke",
  },
  {
    en: "Rossland",
    fr: "Rossland",
  },
  {
    en: "Trail",
    fr: "Trail",
  },
  {
    en: "Vancouver",
    fr: "Vancouver",
  },
  {
    en: "Vernon",
    fr: "Vernon",
  },
  {
    en: "Victoria",
    fr: "Victoria",
  },
  {
    en: "West Vancouver",
    fr: "Vancouver Ouest",
  },
  {
    en: "White Rock",
    fr: "White Rock",
  },
];

export const manitobaOptions = [
  {
    en: "Brandon",
    fr: "Brandon",
  },
  {
    en: "Churchill",
    fr: "Churchill",
  },
  {
    en: "Dauphin",
    fr: "Dauphin",
  },
  {
    en: "Flin Flon",
    fr: "Flin Flon",
  },
  {
    en: "Kildonan",
    fr: "Kildonan",
  },
  {
    en: "Saint Boniface",
    fr: "Saint Boniface",
  },
  {
    en: "Swan River",
    fr: "Swan River",
  },
  {
    en: "Thompson",
    fr: "Thompson",
  },
  {
    en: "Winnipeg",
    fr: "Winnipeg",
  },
  {
    en: "York Factory",
    fr: "York Factory",
  },
];

export const newBrunswickOptions = [
  {
    en: "Bathurst",
    fr: "Bathurst",
  },
  {
    en: "Caraquet",
    fr: "Caraquet",
  },
  {
    en: "Dalhousie",
    fr: "Dalhousie",
  },
  {
    en: "Fredericton",
    fr: "Fredericton",
  },
  {
    en: "Miramichi",
    fr: "Miramichi",
  },
  {
    en: "Moncton",
    fr: "Moncton",
  },
  {
    en: "Saint John",
    fr: "Saint-Jean",
  },
];

export const NewfoundlandAndLabradorOptions = [
  {
    en: "Argentia",
    fr: "Argentia",
  },
  {
    en: "Bonavista",
    fr: "Bonavista",
  },
  {
    en: "Channel-Port aux Basques",
    fr: "Channel-Port aux Basques",
  },
  {
    en: "Corner Brook",
    fr: "Corner Brook",
  },
  {
    en: "Ferryland",
    fr: "Ferryland",
  },
  {
    en: "Gander",
    fr: "Gander",
  },
  {
    en: "Grand Falls–Windsor",
    fr: "Grand Falls–Windsor",
  },
  {
    en: "Happy Valley–Goose Bay",
    fr: "Happy Valley–Goose Bay",
  },
  {
    en: "Harbour Grace",
    fr: "Harbour Grace",
  },
  {
    en: "Labrador City",
    fr: "Labrador City",
  },
  {
    en: "Placentia",
    fr: "Placentia",
  },
  {
    en: "Saint Anthony",
    fr: "Saint Anthony",
  },
  {
    en: "St. John’s",
    fr: "Saint-Jean de Terre-Neuve",
  },
  {
    en: "Wabana",
    fr: "Wabana",
  },
];

export const NorthwestTerritoriesOptions = [
  {
    en: "Fort Smith",
    fr: "Fort Smith",
  },
  {
    en: "Hay River",
    fr: "Hay River",
  },
  {
    en: "Inuvik",
    fr: "Inuvik",
  },
  {
    en: "Tuktoyaktuk",
    fr: "Tuktoyaktuk",
  },
  {
    en: "Yellowknife",
    fr: "Yellowknife",
  },
];

export const NovaScotiaOptions = [
  {
    en: "Baddeck",
    fr: "Baddeck",
  },
  {
    en: "Digby",
    fr: "Digby",
  },
  {
    en: "Glace Bay",
    fr: "Glace Bay",
  },
  {
    en: "Halifax",
    fr: "Halifax",
  },
  {
    en: "Liverpool",
    fr: "Liverpool",
  },
  {
    en: "Louisbourg",
    fr: "Louisbourg",
  },
  {
    en: "Lunenburg",
    fr: "Lunenburg",
  },
  {
    en: "Pictou",
    fr: "Pictou",
  },
  {
    en: "Port Hawkesbury",
    fr: "Port Hawkesbury",
  },
  {
    en: "Springhill",
    fr: "Springhill",
  },
  {
    en: "Sydney",
    fr: "Sydney",
  },
  {
    en: "Yarmouth",
    fr: "Yarmouth",
  },
];

export const NunavutOptions = [
  {
    en: "Iqaluit",
    fr: "Iqaluit",
  },
];

export const ontarioOptions = [
  {
    en: "Bancroft",
    fr: "Bancroft",
  },
  {
    en: "Barrie",
    fr: "Barrie",
  },
  {
    en: "Belleville",
    fr: "Belleville",
  },
  {
    en: "Brampton",
    fr: "Brampton",
  },
  {
    en: "Brantford",
    fr: "Brantford",
  },
  {
    en: "Brockville",
    fr: "Brockville",
  },
  {
    en: "Burlington",
    fr: "Burlington",
  },
  {
    en: "Cambridge",
    fr: "Cambridge",
  },
  {
    en: "Chatham",
    fr: "Chatham",
  },
  {
    en: "Chatham-Kent",
    fr: "Chatham-Kent",
  },
  {
    en: "Cornwall",
    fr: "Cornwall",
  },
  {
    en: "Elliot Lake",
    fr: "Elliot Lake",
  },
  {
    en: "Etobicoke",
    fr: "Etobicoke",
  },
  {
    en: "Fort Erie",
    fr: "Fort Érié",
  },
  {
    en: "Fort Frances",
    fr: "Fort Frances",
  },
  {
    en: "Gananoque",
    fr: "Gananoque",
  },
  {
    en: "Guelph",
    fr: "Guelph",
  },
  {
    en: "Hamilton",
    fr: "Hamilton",
  },
  {
    en: "Iroquois Falls",
    fr: "Iroquois Falls",
  },
  {
    en: "Kapuskasing",
    fr: "Kapuskasing",
  },
  {
    en: "Kawartha Lakes",
    fr: "Kawartha Lakes",
  },
  {
    en: "Kenora",
    fr: "Kenora",
  },
  {
    en: "Kingston",
    fr: "Kingston",
  },
  {
    en: "Kirkland Lake",
    fr: "Kirkland Lake",
  },
  {
    en: "Kitchener",
    fr: "Kitchener",
  },
  {
    en: "Laurentian Hills",
    fr: "Laurentian Hills",
  },
  {
    en: "London",
    fr: "London",
  },
  {
    en: "Midland",
    fr: "Midland",
  },
  {
    en: "Mississauga",
    fr: "Mississauga",
  },
  {
    en: "Moose Factory",
    fr: "Moose Factory",
  },
  {
    en: "Moosonee",
    fr: "Moosonee",
  },
  {
    en: "Niagara Falls",
    fr: "Niagara Falls",
  },
  {
    en: "Niagara-on-the-Lake",
    fr: "Niagara-on-the-Lake",
  },
  {
    en: "North Bay",
    fr: "North Bay",
  },
  {
    en: "North York",
    fr: "North York",
  },
  {
    en: "Oakville",
    fr: "Oakville",
  },
  {
    en: "Orillia",
    fr: "Orillia",
  },
  {
    en: "Oshawa",
    fr: "Oshawa",
  },
  {
    en: "Ottawa",
    fr: "Ottawa",
  },
  {
    en: "Parry Sound",
    fr: "Parry Sound",
  },
  {
    en: "Penetanguishene",
    fr: "Penetanguishene",
  },
  {
    en: "Perth",
    fr: "Perth",
  },
  {
    en: "Peterborough",
    fr: "Peterborough",
  },
  {
    en: "Picton",
    fr: "Picton",
  },
  {
    en: "Port Colborne",
    fr: "Port Colborne",
  },
  {
    en: "Saint Catharines",
    fr: "Saint Catharines",
  },
  {
    en: "Saint Thomas",
    fr: "Saint Thomas",
  },
  {
    en: "Sarnia-Clearwater",
    fr: "Sarnia-Clearwater",
  },
  {
    en: "Sault Sainte Marie",
    fr: "Sault-Sainte-Marie",
  },
  {
    en: "Scarborough",
    fr: "Scarborough",
  },
  {
    en: "Simcoe",
    fr: "Simcoe",
  },
  {
    en: "Stratford",
    fr: "Stratford",
  },
  {
    en: "Sudbury",
    fr: "Sudbury",
  },
  {
    en: "Temiskaming Shores",
    fr: "Temiskaming Shores",
  },
  {
    en: "Thorold",
    fr: "Thorold",
  },
  {
    en: "Thunder Bay",
    fr: "Thunder Bay",
  },
  {
    en: "Timmins",
    fr: "Timmins",
  },
  {
    en: "Toronto",
    fr: "Toronto",
  },
  {
    en: "Trenton",
    fr: "Trenton",
  },
  {
    en: "Waterloo",
    fr: "Waterloo",
  },
  {
    en: "Welland",
    fr: "Welland",
  },
  {
    en: "West Nipissing",
    fr: "Nipissing Ouest",
  },
  {
    en: "Windsor",
    fr: "Windsor",
  },
  {
    en: "Woodstock",
    fr: "Woodstock",
  },
  {
    en: "York",
    fr: "York",
  },
];

export const PrinceEdwardIslandOptions = [
  {
    en: "Borden",
    fr: "Borden",
  },
  {
    en: "Cavendish",
    fr: "Cavendish",
  },
  {
    en: "Charlottetown",
    fr: "Charlottetown",
  },
  {
    en: "Souris",
    fr: "Souris",
  },
  {
    en: "Summerside",
    fr: "Summerside",
  },
];

export const QuebecOptions = [
  {
    en: "Asbestos",
    fr: "Val-des-Sources",
  },
  {
    en: "Baie-Comeau",
    fr: "Baie-Comeau",
  },
  {
    en: "Beloeil",
    fr: "Beloeil",
  },
  {
    en: "Cap-de-la-Madeleine",
    fr: "Cap-de-la-Madeleine",
  },
  {
    en: "Chambly",
    fr: "Chambly",
  },
  {
    en: "Charlesbourg",
    fr: "Charlesbourg",
  },
  {
    en: "Châteauguay",
    fr: "Châteauguay",
  },
  {
    en: "Chibougamau",
    fr: "Chibougamau",
  },
  {
    en: "Côte-Saint-Luc",
    fr: "Côte-Saint-Luc",
  },
  {
    en: "Dorval",
    fr: "Dorval",
  },
  {
    en: "Gaspé",
    fr: "Gaspé",
  },
  {
    en: "Gatineau",
    fr: "Gatineau",
  },
  {
    en: "Granby",
    fr: "Granby",
  },
  {
    en: "Havre-Saint-Pierre",
    fr: "Havre-Saint-Pierre",
  },
  {
    en: "Hull",
    fr: "Hull",
  },
  {
    en: "Jonquière",
    fr: "Jonquière",
  },
  {
    en: "Kuujjuaq",
    fr: "Kuujjuaq",
  },
  {
    en: "La Salle",
    fr: "La Salle",
  },
  {
    en: "La Tuque",
    fr: "La Tuque",
  },
  {
    en: "Lachine",
    fr: "Lachine",
  },
  {
    en: "Laval",
    fr: "Laval",
  },
  {
    en: "Lévis",
    fr: "Lévis",
  },
  {
    en: "Longueuil",
    fr: "Longueuil",
  },
  {
    en: "Magog",
    fr: "Magog",
  },
  {
    en: "Matane",
    fr: "Matane",
  },
  {
    en: "Montreal",
    fr: "Montréal",
  },
  {
    en: "Montréal-Nord",
    fr: "Montréal-Nord",
  },
  {
    en: "Percé",
    fr: "Percé",
  },
  {
    en: "Port-Cartier",
    fr: "Port-Cartier",
  },
  {
    en: "Quebec",
    fr: "Québec",
  },
  {
    en: "Rimouski",
    fr: "Rimouski",
  },
  {
    en: "Rouyn-Noranda",
    fr: "Rouyn-Noranda",
  },
  {
    en: "Saguenay",
    fr: "Saguenay",
  },
  {
    en: "Saint-Eustache",
    fr: "Saint-Eustache",
  },
  {
    en: "Saint-Hubert",
    fr: "Saint-Hubert",
  },
  {
    en: "Sainte-Anne-de-Beaupré",
    fr: "Sainte-Anne-de-Beaupré",
  },
  {
    en: "Sainte-Foy",
    fr: "Sainte-Foy",
  },
  {
    en: "Sainte-Thérèse",
    fr: "Sainte-Thérèse",
  },
  {
    en: "Sept-Îles",
    fr: "Sept-Îles",
  },
  {
    en: "Sherbrooke",
    fr: "Sherbrooke",
  },
  {
    en: "Sorel-Tracy",
    fr: "Sorel-Tracy",
  },
  {
    en: "Trois-Rivières",
    fr: "Trois-Rivières",
  },
  {
    en: "Val-d’Or",
    fr: "Val-d’Or",
  },
  {
    en: "Waskaganish",
    fr: "Waskaganish",
  },
];

export const SaskatchewanOptions = [
  {
    en: "Batoche",
    fr: "Batoche",
  },
  {
    en: "Cumberland House",
    fr: "Cumberland House",
  },
  {
    en: "Estevan",
    fr: "Estevan",
  },
  {
    en: "Flin Flon",
    fr: "Flin Flon",
  },
  {
    en: "Moose Jaw",
    fr: "Moose Jaw",
  },
  {
    en: "Prince Albert",
    fr: "Prince Albert",
  },
  {
    en: "Regina",
    fr: "Régina",
  },
  {
    en: "Saskatoon",
    fr: "Saskatoon",
  },
  {
    en: "Uranium City",
    fr: "Uranium City",
  },
];

export const YukonOptions = [
  {
    en: "Dawson",
    fr: "Dawson",
  },
  {
    en: "Watson Lake",
    fr: "Watson Lake",
  },
  {
    en: "Whitehorse",
    fr: "Whitehorse",
  },
];

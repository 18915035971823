import React from "react";
import PropTypes from "prop-types";
import style from "./LanguageSwitcher.module.scss";

const LanguageSwitcher = ({ language, changeLanguage }) => {
  const activeLang = {
    color: "rgba(33, 134, 235, 1)",
    border: "solid 1px rgba(33, 134, 235, 1)",
  };

  return (
    <ul className={style.container}>
      <li
        onClick={() => changeLanguage("en")}
        style={language === "en" ? activeLang : null}
      >
        english
      </li>
      <li
        onClick={() => changeLanguage("fr")}
        style={language === "fr" ? activeLang : null}
      >
        français
      </li>
    </ul>
  );
};

LanguageSwitcher.propTypes = {
  language: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

export default LanguageSwitcher;

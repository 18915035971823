import React from "react";

export default function Wrapper(props) {
  const style = {
    minWidth: "300px",
    maxWidth: "800px",
  };

  return <div style={style}>{props.children}</div>;
}

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import css from "./Table.module.scss";

export const ListItem = ({ item }) => {
  return (
    <li className={css.list_item}>
      <div>{item.firstName}</div>
      <div>{item.lastName}</div>
      <div>{item.email}</div>
      <div>{item.visitCount}</div>
      <div>{moment(item.lastVisit).format("DD-MM-YYYY HH:mm ")}</div>
    </li>
  );
};

function Table({ data }) {
  const renderData = data.map((el) => {
    return <ListItem item={el} />;
  });

  return (
    <div className={css.container}>
      <div className={css.table_header}>
        <div>First name</div>
        <div>Last name</div>
        <div>Email</div>
        <div>Visit Count</div>
        <div>Visit Date</div>
      </div>
      <ul>{renderData}</ul>
    </div>
  );
}

Table.propTypes = {};

export default Table;

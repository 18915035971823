import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';

const SYSTEM_DEFAULT_OPTION = 'SYSTEM_DEFAULT_VALUE';
const CUSTOM_OPTION = 'CUSTOM_VALUE';

const systemDefaultEntry = {
  label: 'Use system default',
  value: SYSTEM_DEFAULT_OPTION,
};
const customEntry = { label: 'Custom', value: CUSTOM_OPTION };
const cashbackSplitOptions = [systemDefaultEntry, customEntry];

const validateChange = (
  cashbackSplitOption,
  hasBusinessOverride,
  userCashbackPercentage,
  userSharePercentage,
  onStateChange
) => {
  const newHasBusinessOverride = cashbackSplitOption === CUSTOM_OPTION;
  if (
    userCashbackPercentage !== userSharePercentage ||
    newHasBusinessOverride !== hasBusinessOverride
  ) {
    onStateChange({
      userCashbackPercentage: userSharePercentage,
      hasBusinessOverride: cashbackSplitOption === CUSTOM_OPTION,
    });
  }
};

const BusinessCashbackSplitage = ({
  userCashbackPercentage,
  systemWideCashbackUserSplitPercentage,
  hasBusinessOverride,
  onStateChange,
  cashbackSplitOptionError,
  errors,
}) => {
  const [cashbackSplitOption, setCashbackSplitOption] = useState(
    SYSTEM_DEFAULT_OPTION
  );
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [companySharePercentage, setCompanySharePercentage] = useState('100');
  const [systemUserSharePercentage, setSystemUserSharePercentage] =
    useState('0');
  const [systemCompanySharePercentage, setSystemCompanySharePercentage] =
    useState('100');
  useEffect(() => {
    if (hasBusinessOverride) {
      setCashbackSplitOption(CUSTOM_OPTION);
    } else {
      setCashbackSplitOption(SYSTEM_DEFAULT_OPTION);
    }
  }, [hasBusinessOverride]);
  useEffect(() => {
    setSystemUserSharePercentage(
      normalizeFloat(systemWideCashbackUserSplitPercentage)
    );
    setSystemCompanySharePercentage(
      normalizeFloat(100 - parseFloat(systemWideCashbackUserSplitPercentage))
    );
  }, [systemWideCashbackUserSplitPercentage]);
  useEffect(() => {
    if (userCashbackPercentage !== null) {
      setUserSharePercentage(
        userCashbackPercentage
          ? normalizeFloat(userCashbackPercentage)
          : userCashbackPercentage
      );
      if (userCashbackPercentage === '100' && companySharePercentage === '') {
        return;
      }
      setCompanySharePercentage(
        normalizeFloat(100 - parseFloat(userCashbackPercentage || '0'))
      );
    } else {
      setUserSharePercentage(
        normalizeFloat(systemWideCashbackUserSplitPercentage)
      );
      setCompanySharePercentage(
        normalizeFloat(100 - parseFloat(systemWideCashbackUserSplitPercentage))
      );
    }
  }, [
    companySharePercentage,
    systemWideCashbackUserSplitPercentage,
    userCashbackPercentage,
  ]);

  const handleUserShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        if (cashbackSplitOption !== SYSTEM_DEFAULT_OPTION) {
          const newUserSharePercentage = value ? normalizeFloat(value) : value;
          setUserSharePercentage(newUserSharePercentage);
          validateChange(
            cashbackSplitOption,
            hasBusinessOverride,
            userCashbackPercentage,
            newUserSharePercentage,
            onStateChange
          );
          if (value === '100' && !companySharePercentage) {
            return;
          }
          setCompanySharePercentage(
            normalizeFloat(100 - parseFloat(newUserSharePercentage || '0'))
          );
        }
      }
    },
    [
      cashbackSplitOption,
      companySharePercentage,
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
    ]
  );

  const handleCompanyShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        if (cashbackSplitOption !== SYSTEM_DEFAULT_OPTION) {
          const newUserSharePercentage =
            value === '100' && !userSharePercentage
              ? userSharePercentage
              : normalizeFloat(100 - parseFloat(value || '0'));
          setCompanySharePercentage(value ? normalizeFloat(value) : value);
          setUserSharePercentage(newUserSharePercentage);
          validateChange(
            cashbackSplitOption,
            hasBusinessOverride,
            userCashbackPercentage,
            newUserSharePercentage,
            onStateChange
          );
        }
      }
    },
    [
      cashbackSplitOption,
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
      userSharePercentage,
    ]
  );
  const cashbackSplitChangeHandler = useCallback(
    (event) => {
      const value = event.target.value;
      setCashbackSplitOption(value);
      validateChange(
        value,
        hasBusinessOverride,
        userCashbackPercentage,
        userSharePercentage,
        onStateChange
      );
    },
    [
      hasBusinessOverride,
      onStateChange,
      userCashbackPercentage,
      userSharePercentage,
    ]
  );

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="cashbackSplitOption-select-label">
          Cashback Split
        </InputLabel>
        <Select
          labelId="cashbackSplitOption-select-label"
          name="cashbackSplitOption"
          value={cashbackSplitOption}
          label="Cashback Split"
          onChange={cashbackSplitChangeHandler}
          error={!!errors.cashbackSplitOptionError}
        >
          {cashbackSplitOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {cashbackSplitOptionError &&
            'Error getting system wide cashback split percentage'}
        </FormHelperText>
      </FormControl>

      <TextField
        name="userShare"
        value={
          cashbackSplitOption === SYSTEM_DEFAULT_OPTION
            ? systemUserSharePercentage
            : userSharePercentage
        }
        type="number"
        label="User Share"
        onChange={handleUserShareChange}
        disabled={
          cashbackSplitOption === SYSTEM_DEFAULT_OPTION ||
          cashbackSplitOptionError
        }
        error={errors && errors.userCashbackPercentage}
        InputProps={{
          inputProps: { min: 0, max: 100, step: 0.01 },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      <TextField
        name="companyShare"
        value={
          cashbackSplitOption === SYSTEM_DEFAULT_OPTION
            ? systemCompanySharePercentage
            : companySharePercentage
        }
        label="Company Share"
        onChange={handleCompanyShareChange}
        type="number"
        disabled={
          cashbackSplitOption === SYSTEM_DEFAULT_OPTION ||
          cashbackSplitOptionError
        }
        InputProps={{
          inputProps: { min: 0, max: 100, step: 0.01 },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </>
  );
};

BusinessCashbackSplitage.propTypes = {
  userCashbackPercentage: PropTypes.string,
  systemWideCashbackUserSplitPercentage: PropTypes.string,
  hasBusinessOverride: PropTypes.bool,
  onStateChange: PropTypes.func.isRequired,
  cashbackSplitOptionError: PropTypes.bool,
  errors: PropTypes.object,
};

BusinessCashbackSplitage.defaultProps = {
  userCashbackPercentage: null,
  systemWideCashbackUserSplitPercentage: null,
  hasBusinessOverride: false,
};

export { BusinessCashbackSplitage };

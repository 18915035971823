import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import NumberFormat from "react-number-format";

export const TextField = ({
  name,
  type,
  label,
  value,
  onChange,
  placeholder,
  disabled,
  maxLength,
  required,
  helperText,
  format,
  className,
  autofocus,
  error,
  decimalScale,
  allowNegative,
  suffix,
  isAllowed,
  onValueChange,
  defaultValue,
  allowEmptyFormatting,
  onNumericBlur,
}) => {
  if (type === "number") {
    return (
      <div className={classnames("input-cntr", className)}>
        <label className="label" htmlFor={name}>
          {label}
          {required ? <span className="text-danger">*</span> : null}
        </label>
        <div style={{ marginTop: label ? "10px" : "0px" }} className="inner">
          <NumberFormat
            name={name}
            className={classnames("control", {
              "is-invalid": error,
              "is-disabled": disabled,
            })}
            format={format}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onValueChange={onValueChange}
            disabled={disabled}
			decimalScale={decimalScale}
			allowNegative={allowNegative}
			suffix={suffix}
			isAllowed={isAllowed}
			defaultValue={defaultValue}
			allowEmptyFormatting={allowEmptyFormatting}
			onBlur={onNumericBlur}
          />
          {error ? (
            <small className="invalid-feedback">
              <i className="fas fa-times" />
              {error}
            </small>
          ) : (
            helperText && (
              <small className="helper-text">
                <i className="fas fa-info-circle" />
                {helperText}
              </small>
            )
          )}
        </div>
      </div>
    );
  }

  if (type === "area") {
    return (
      <div className={classnames("input-cntr", className)}>
        <label className="label" htmlFor={name}>
          {label}
          {required ? <span className="text-danger">*</span> : null}
        </label>
        <div style={{ marginTop: label ? "10px" : "0px" }} className="inner">
          <textarea
            name={name}
            className={classnames("control", {
              "is-invalid": error,
              "is-disabled": disabled,
            })}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxLength}
            autoFocus={autofocus}
            rows={5}
          />
          {error ? (
            <small className="invalid-feedback">
              <i className="fas fa-times" />
              {error}
            </small>
          ) : (
            helperText && (
              <small className="helper-text">
                <i className="fas fa-info-circle" />
                {helperText}
              </small>
            )
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classnames("input-cntr", className)}>
      <label className="label" htmlFor={name}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <div style={{ marginTop: label ? "10px" : "0px" }} className="inner">
        <input
          name={name}
          className={classnames("control", {
            "is-invalid": error,
            "is-disabled": disabled,
          })}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          autoFocus={autofocus}
        />
        {error ? (
          <small className="invalid-feedback">
            <i className="fas fa-times" />
            {error}
          </small>
        ) : (
          helperText && (
            <small className="helper-text">
              <i className="fas fa-info-circle" />
              {helperText}
            </small>
          )
        )}
      </div>
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  autofocus: PropTypes.bool,
  error: PropTypes.string,
  decimalScale: PropTypes.number,
  allowNegative: PropTypes.bool,
  suffix: PropTypes.string,
  isAllowed: PropTypes.func,
  onValueChange: PropTypes.func,
  onNumericBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  allowEmptyFormatting: PropTypes.bool,
};

TextField.defaultProps = {
  type: "text",
  format: "",
  label: null,
  placeholder: null,
  disabled: false,
  maxLength: null,
  required: false,
  helperText: null,
  className: "",
  autofocus: false,
  error: null,
};

export default TextField;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../../../../utils";
import css from "./Questions.module.scss";

function Questions({ questions, selectQuestion, selectedQ }) {
  if (isEmpty(questions)) return null;

  const renderList = questions.map((q) => {
    let activeStyle = {};

    if (selectedQ && q.id === selectedQ) {
      activeStyle = {
        borderColor: "rgba(33, 134, 235, 1)",
        borderWidth: "2px",
      };
    }

    const questionTitle = q.text.replace(/(<([^>]+)>)/gi, "");

    return (
      <li
        key={questionTitle}
        className={css.list_item}
        onClick={() => selectQuestion(q)}
        style={activeStyle}
      >
        <div>
          <span>{questionTitle.slice(0, 50)}</span>
        </div>
        <span className={css.type}>
          type:{" "}
          <strong>
            <em>{q.type}</em>
          </strong>
        </span>
      </li>
    );
  });

  return <ul className={css.container}>{renderList}</ul>;
}

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default Questions;

import axios from 'axios';
import { reLogInToRefreshToken } from '../../redux/actions/authActions';

const getReLogInPromise = (store) =>
  new Promise((resolve, reject) => {
    const unsubscribe = store.subscribe(() => {
      const { auth } = store.getState();
      if (!auth.refreshingToken) {
        resolve();
        unsubscribe();
      }
    });
  });

const setUpRefreshToken = (store) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error.response ? error.response.status : null;
      if (
        status === 401 &&
        error.response.data.message === 'Invalid JWT token'
      ) {
        store.dispatch(reLogInToRefreshToken());
        if (store.getState().auth.refreshingToken) {
          await getReLogInPromise(store);
        }
        if (!store.getState().auth.isAuthenticated) {
          throw error;
        }
        error.config.headers['x-auth-token'] = store.getState().auth.token;
        return axios.request(error.config);
      }

      throw error;
    }
  );
};

export { setUpRefreshToken };

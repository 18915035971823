import React from "react";
import PropTypes from "prop-types";
import Button from "../../Inputs/Button";

const UserListItem = ({ user }) => {
  return (
    <li>
      <div className="list-inner">
        <div className="name truncate">{`${user.firstName} ${user.lastName}`}</div>
        <div className="email truncate">{user.email}</div>
        <div className="role">{user.role}</div>
        <div className="button-container">
          <Button
            className="btn-sm"
            text="Edit"
            btnLink
            url={`/dashboard/users/edit/${user.id}`}
          />
        </div>
      </div>
    </li>
  );
};

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserListItem;

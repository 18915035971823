import styled from '@emotion/styled';
import React from 'react';
import { useDrag } from 'react-dnd';

let Block = styled.div({
  background: '#efefef',
  border: '1px solid rgba(0,0,0,0.1)',
  borderRadius: 4,
  padding: 6,
  minWidth: 64,
  marginBottom: 4,
  textTransform: 'uppercase',
  cursor: 'pointer',
});

const ComponentItem = ({item}) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: 'component',
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <span ref={dragRef}>
      <Block>{item.type}</Block>
    </span>
  );
};

export default ComponentItem;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../Inputs/Button";

const PostListItem = ({ page }) => {
    const {title, startDate, updatedAt, id, createdAt} = page;

  return (
    <li>
      <div className="list-inner">
        <div className="name">{title}</div>
        <div className="date">{moment(startDate ? startDate : createdAt).format("MM-DD-YYYY")}</div>
        <div className="date">{updatedAt && moment(updatedAt).format("MM-DD-YYYY")}</div>
        <div className="button-container">
          <Button
            className="btn-sm"
            text="Edit"
            btnLink
            url={`/dashboard/pages/edit/${id}`}
          />
        </div>
      </div>
    </li>
  );
};

PostListItem.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PostListItem;

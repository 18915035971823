import axios from 'axios';
import {
  CLEAR_DISCOUNTS,
  CLEAR_ERRORS,
  GET_ALL_DISCOUNTS,
  GET_ALL_DISCOUNTS_ERROR,
  GET_DISCOUNTS,
  GET_ERRORS,
  SET_DISCOUNTS_LOADING,
} from '../types';

export const setLoading =
  (val = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_DISCOUNTS_LOADING,
      payload: val,
    });
  };

export const getAllDiscounts = (params) => async (dispatch) => {
  dispatch(setLoading());
  dispatch({
    type: CLEAR_ERRORS,
  });
  try {
    const res = await axios.get(`/public/offers`, {
      params: { ...params, dropdown: true },
    });
    dispatch({
      type: GET_ALL_DISCOUNTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_DISCOUNTS_ERROR, payload: err.response.data });
  }
};

export const getDiscounts =
  (page, name, subcategoryId, order, businessId) => async (dispatch) => {
    dispatch(setLoading());
    dispatch({
      type: CLEAR_ERRORS,
    });
    try {
      const res = await axios.get(`/offers/`, {
        params: { page, name, subcategoryId, order, businessId },
      });
      dispatch({
        type: GET_DISCOUNTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch({ type: CLEAR_DISCOUNTS });
    }
  };

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import Button from '../../Inputs/Button';

const orderTypeOptions = [
  { label: 'Ascending', value: 'ASC' },
  { label: 'Descending', value: 'DESC' },
];

const orderFieldOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Start date', value: 'startDate' },
  { label: 'End date', value: 'endDate' },
];
export class PostSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  render() {
    const { name, orderType, orderField } = this.props.search;
    const { handleSearch } = this.props;

    return (
      <Stack
        component="form"
        direction="row"
        spacing={1}
        sx={{ mb: 4 }}
        onSubmit={(e) => handleSearch(e, name, `${orderField},${orderType}`)}
      >
        <TextField
          name="name"
          value={name}
          label="Search"
          onChange={this.handleChange}
          inputProps={{ maxLength: 30 }}
          size="small"
        />
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="orderField-select-label">Sort by</InputLabel>
          <Select
            labelId="orderField-select-label"
            name="orderField"
            value={orderField}
            label="Sort by"
            onChange={this.handleChange}
          >
            {orderFieldOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="orderType-select-label">Sort type</InputLabel>
          <Select
            labelId="orderType-select-label"
            name="orderType"
            value={orderType}
            label="Sort by"
            onChange={this.handleChange}
          >
            {orderTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button text="Search" type="submit" />
      </Stack>
    );
  }
}

PostSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default PostSearch;

import axios from 'axios';
import { GET_AFFILIATES, GET_ERRORS } from '../types';

export const getAffiliates = () => async (dispatch) => {
  try {
    const res = await axios.get(`/affiliates`);
    console.log(res.data, 'res.data');
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';

const CssDiv = styled.div((props) => ({
  whiteSpace: 'pre-wrap',
  color: props.empty ? '#777' : '#000',
}));

export default function Poll({ pollId, onChange }) {
  const pollsState = useSelector((state) => state.polls);
  let name = '';
  if (pollsState.polls) {
    name = pollsState.polls.find((poll) => poll.id === pollId)?.name;
  }
  return (
    <CssDiv
      empty={!name}
      dangerouslySetInnerHTML={{
        __html: `${name ? name : 'Add poll'}`,
      }}
    />
  );
}

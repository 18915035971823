import { Box, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';

const TextArea = ({ grabQuestionData, data }) => {
  const [questionData, setQuestionData] = useState({
    maxResponseLength: 100,
  });

  const { maxResponseLength } = questionData;

  useEffect(() => {
    setQuestionData({
      maxResponseLength: data.maxResponseLength,
    });
  }, [data.maxResponseLength]);

  useEffect(() => {
    grabQuestionData({
      maxResponseLength,
    });
  }, [maxResponseLength, grabQuestionData]);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value > 0 && value.length <= 4) {
      setQuestionData({
        ...questionData,
        maxResponseLength: value,
      });
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TextField
        size="small"
        label="Response Length"
        type="number"
        value={maxResponseLength}
        name="maxResponseLength"
        inputProps={{ min: 1 }}
        onChange={handleChange}
      />
    </Box>
  );
};

TextArea.propTypes = {};

export default TextArea;

import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { login } from '../../redux/actions/authActions';
import Button from '../Inputs/Button';

export const Login = ({ login, isAuthenticated, loading, errors }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-form">
      <form onSubmit={(e) => onSubmit(e)}>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            name="email"
            value={email}
            type="text"
            label="Email Address"
            placeholder="Email address"
            onChange={onChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <TextField
            name="password"
            value={password}
            type="password"
            label="Password"
            placeholder="Password"
            onChange={onChange}
            error={!!errors.password}
            helperText={errors.password}
          />
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <Button
            text="Sign in"
            type="submit"
            isLoading={loading}
            disabled={false}
          />
        </Box>
      </form>

      <Link style={{ fontSize: '14px' }} to="/password-reset">
        Forgot password?
      </Link>

      <p className="text-danger">{errors.message}</p>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  errors: state.errors,
});

export default connect(mapStateToProps, { login })(Login);

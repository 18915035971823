import styled from '@emotion/styled';
import React from 'react';

const CssDiv = styled.div((props) => ({
  whiteSpace: 'pre-wrap',
  color: props.empty ? '#777' : '#000',
  fontSize: '1.3em',
  fontWeight: 'bold',
}));

export default function ZPattern({ image, text, text_fr }) {
  return (
    <>
      {image ? <img width="200" src={image.url} /> : ''}
      <CssDiv
        empty={!text}
        dangerouslySetInnerHTML={{
          __html: `${text ? text : ''}`,
        }}
      />
      {text_fr ? (
        <div
          dangerouslySetInnerHTML={{
            __html: text_fr,
          }}
        />
      ) : null}
    </>
  );
}

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../../Inputs/Spinner';
import ImageThumbnail from './ImageThumbnail';
import css from './MediaLibrary.module.scss';
import MediaLibraryEntry from './MediaLibraryEntry';

const filterOptions = [
  { label: 'All', value: '' },
  { label: 'Desktop Header', value: 'Desktop' },
  { label: 'Mobile Header', value: 'Mobile' },
  { label: 'Page Header', value: 'Page Header' },
  { label: 'Thumbnail', value: 'Thumbnail' },
  { label: 'Product', value: 'Product' },
];

export default function MediaLibrary({ mediaType, grabSelectedImage }) {
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [filter, setFilter] = useState('');

  const grid = useRef(null);

  const getImages = useCallback(
    async (page = 1) => {
      const path = `/media`;
      let limit = 20;
      const params = {
        params: {
          page,
          limit,
          type: mediaType ? mediaType : filter,
          label: searchText,
        },
      };
      setLoading(true);
      try {
        const res = await axios.get(path, params);
        setLoading(false);
        setImages((images) =>
          page === 1 ? res.data.results : images.concat(res.data.results)
        );
        setPage(page + 1);
      } catch (err) {
        if (page === 1) {
          setImages([]);
        }
        setLoading(false);
      }
    },
    [filter, mediaType, searchText]
  );

  useEffect(() => {
    getImages(1);
    setPage(1);
  }, [getImages]);

  useEffect(() => {
    if (grid.current.scrollHeight <= grid.current.offsetHeight) {
      getImages(page);
    }
  }, [getImages, page]);

  useEffect(() => {
    if (dialogOpen === false) setSelectedImage(null);
  }, [dialogOpen]);

  const selectImage = (img) => {
    setSelectedImage(img);
    if (grabSelectedImage) {
      setConfirmDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const confirmSelection = () => {
    grabSelectedImage(selectedImage);
    setConfirmDialogOpen(false);
  };

  const renderThumbnails = () => {
    return images.map((el) => {
      return <ImageThumbnail key={el.id} onClick={selectImage} image={el} />;
    });
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleScroll = (e) => {
    if (
      e.target.scrollTop + e.target.offsetHeight + 50 >=
      e.target.scrollHeight
    ) {
      getImages(page);
    }
  };

  const handleNew = () => {
    setSelectedImage({});
    setDialogOpen(true);
  };

  const handleSearchChange = debounce((e) => {
    setSearchText(e.target.value);
  }, 1000);

  return (
    <div className={css.wrapper}>
      <h1>Media Library {mediaType ? `- ${mediaType}` : null}</h1>

      <div className={css.controls_container}>
        <Button color="primary" onClick={handleNew}>
          New Entry
        </Button>
        <FormControl sx={{ width: 300 }} size="small">
          <InputLabel id="filter-select-label">Filter</InputLabel>
          <Select
            labelId="filter-select-label"
            value={filter}
            name="filter"
            label="Filter"
            onChange={(event) => setFilter(event.target.value)}
          >
            {filterOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ mr: 2 }}
          InputLabelProps={{ shrink: true }}
          name="searchText"
          onChange={handleSearchChange}
          label="Search Images"
          size="small"
          inputProps={{ maxLength: 30 }}
        />
      </div>

      {images.length || loading ? null : (
        <Alert severity="error">No items found</Alert>
      )}

      <div
        onScroll={(e) => handleScroll(e)}
        id="grid"
        className={css.container}
        ref={grid}
      >
        {renderThumbnails()}
        {loading ? <Spinner /> : null}
      </div>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick
      >
        <MediaLibraryEntry
          image={selectedImage}
          closeDialog={closeDialog}
          getData={getImages}
        />
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Use this image?</DialogTitle>
        <DialogContent>You are about to select an image</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="secondary">
            Cancel
          </Button>

          <Button onClick={confirmSelection} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MediaLibrary.propTypes = {
  mediaType: PropTypes.string,
  grabSelectedImage: PropTypes.func,
};

MediaLibrary.defaultProps = {
  mediaType: undefined,
  grabSelectedImage: undefined,
};

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty } from '../../../../utils';
import Pagination from '../../../Layout/Pagination';
import css from './PollSubmissions.module.scss';
import SubmissionPreview from './SubmissionPreview';

const limitOptions = [10, 20, 30];

const sortOptions = [
  { label: 'Date', value: 'createdAt' },
  { label: 'User', value: 'userEmail' },
];

const sortTypeOptions = [
  { label: 'Ascending', value: 'ASC' },
  { label: 'Descending', value: 'DESC' },
];

const ListItem = ({ item, setPreview, previewId, handleDelete }) => {
  const [confirmDel, setConfirmDel] = useState(false);

  useEffect(() => {
    if (confirmDel)
      setTimeout(() => {
        setConfirmDel(false);
      }, 3000);
    return () => {
      clearTimeout();
    };
  }, [confirmDel]);

  let activeStyle = {};
  let delStyle = {};
  if (previewId && item.id === previewId) {
    activeStyle = {
      border: '2px solid rgba(33, 134, 235, 1)',
    };
    delStyle = {
      border: '2px solid rgba(255, 67, 104, 1)',
    };
  }

  return (
    <li
      style={confirmDel ? delStyle : activeStyle}
      onClick={() => setPreview(item)}
      className={css.list_item}
    >
      <div>
        <em>id:</em> {item.id}
      </div>
      <div>{moment(item.createdAt).format('MMMM D YYYY, h:mm a')}</div>

      <div className={css.delete_container}>
        {confirmDel ? (
          <span onClick={() => handleDelete(previewId)}>
            <em>Confirm</em>
          </span>
        ) : (
          <span onClick={() => setConfirmDel(true)}>Delete</span>
        )}
      </div>
    </li>
  );
};

const List = ({ results, setPreview, previewId, handleDelete }) => {
  if (!isEmpty(results)) {
    const mapItems = results.map((el) => {
      return (
        <ListItem
          key={el.id}
          item={el}
          setPreview={setPreview}
          previewId={previewId}
          handleDelete={handleDelete}
        />
      );
    });

    return <ul className={css.list_group}>{mapItems}</ul>;
  }
  return <p>Looks like there's nothing here.</p>;
};

function PollSubmissions({ pollId }) {
  const [, setErrors] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [preview, setPreview] = useState({});

  const [orderType, setOrderType] = useState('ASC');
  const [orderField, setOrderField] = useState('createdAt');

  const getData = useCallback(
    async (page, csv = false) => {
      const path = `/polls/${pollId}/submissions/`;
      const params = {
        params: {
          page,
          limit: limit,
          csv,
          orderType: orderType,
          orderField: orderField,
        },
      };
      try {
        const res = await axios.get(path, params);
        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `submissions-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setData(res.data);
        }
      } catch (error) {
        if (error.response.status === 404) setData({});
        setErrors(error.response.data);
      }
    },
    [limit, orderField, orderType, pollId]
  );

  useEffect(() => {
    getData(page);
  }, [limit, orderType, orderField, getData, page]);

  const changePage = (page) => {
    getData(page);
    setPage(page++);
  };

  const handleChangeLimit = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handleDelete = async (submissionId) => {
    const path = `/polls/${pollId}/submissions/${submissionId}`;
    try {
      await axios.delete(path);
      getData();
      if (submissionId === preview.id) {
        setPreview({});
      }
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  return (
    <div className={css.container}>
      <h1>Poll Submissions</h1>

      <div className={css.inner}>
        <div className={css.left}>
          <h3>Submissions</h3>
          <Button onClick={() => getData(undefined, true)}>Export</Button>
          <div className={css.list_controls}>
            <FormControl size="small" sx={{ width: 80 }}>
              <InputLabel id="limit-select-label">Show</InputLabel>
              <MUISelect
                limitId="limit-select-label"
                name="limit"
                value={limit}
                label="Show"
                onChange={handleChangeLimit}
              >
                {limitOptions.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </MUISelect>
            </FormControl>
            <FormControl size="small" sx={{ width: 100 }}>
              <InputLabel id="sortby-select-label">Sort by</InputLabel>
              <MUISelect
                limitId="sortby-select-label"
                name="sortby"
                value={orderField}
                label="Sort by"
                onChange={(e) => setOrderField(e.target.value)}
              >
                {sortOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </MUISelect>
            </FormControl>

            <FormControl size="small" sx={{ width: 140 }}>
              <InputLabel id="Sort-select-label">Sort</InputLabel>
              <MUISelect
                limitId="Sort-select-label"
                name="Sort"
                value={orderType}
                label="Sort"
                onChange={(e) => setOrderType(e.target.value)}
              >
                {sortTypeOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </MUISelect>
            </FormControl>
          </div>

          <span className={css.totalResults}>
            Total Results: {data.totalResults}
          </span>

          <List
            results={data.results}
            setPreview={setPreview}
            previewId={preview.id}
            handleDelete={handleDelete}
          />
          {data.totalResults ? (
            <Pagination
              totalRecords={data.totalResults}
              page={page}
              onPageChange={changePage}
              pageLimit={limit.value}
            />
          ) : null}
        </div>
        <div className={css.right}>
          <h3>View Submissions</h3>
          {!isEmpty(preview) ? <SubmissionPreview data={preview} /> : null}
        </div>
      </div>
    </div>
  );
}

PollSubmissions.propTypes = {};

export default PollSubmissions;

import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Tag from './Tag';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import TagList from './TagList';
import Pagination from '../../Layout/Pagination';
import TagSearch from './TagSearch';
import axios from 'axios';

const Tags = ({ match, history }) => {
  const { params } = match;
  const [tags, setTags] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState('');

  const fetchTags = useCallback(async () => {
    setLoading(true);
    const tagLang = params.lang === 'en' ? 'tags' : 'tagsfr';
    const url = `/${tagLang}`;
    try {
      const res = await axios.get(url, {
        params: { page: params.page, text: search },
      });
      setTags(res.data.results);
      setTotalResults(res.data.totalResults);
      setLoading(false);
      setErrors({});
    } catch (err) {
      setErrors(err.response.data);
      setTotalResults(null);
      setLoading(false);
    }
  }, [params.lang, params.page, search]);

  const handleSearch = () => {
    if (params.page !== 1) {
      history.push(`/dashboard/tags/${params.lang}/1`);
    } else fetchTags();
  };

  const changePage = (page) => {
    history.push(`/dashboard/tags/${params.lang}/${page}`);
  };

  const changeLanguage = (language) => {
    history.push(`/dashboard/tags/${language}`);
  };

  useEffect(() => {
    fetchTags();
  }, [fetchTags, params.lang, params.page]);

  return (
    <div>
      <h1>Tags</h1>
      <LanguageSwitcher
        language={params.lang}
        changeLanguage={changeLanguage}
      />
      <Tag lang={params.lang} fetchTags={fetchTags} />
      <TagSearch
        search={search}
        setSearch={setSearch}
        fetchTags={handleSearch}
      />
      {tags && (
        <TagList
          tags={tags}
          loading={loading}
          lang={params.lang}
          fetchTags={fetchTags}
          errors={errors}
        />
      )}
      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};

export default withRouter(Tags);

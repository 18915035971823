import React from "react";

export const BusinessListHeader = () => {
  return (
    <li className="header">
      <div className="list-inner">
        <div className="name">Name</div>
        <div className="name">Affiliate Network</div>
        <div className="date">Start Date</div>
        <div className="date">End Date</div>
        <div className="button-container">Action</div>
      </div>
    </li>
  );
};

export default BusinessListHeader;

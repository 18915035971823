import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Inputs/Button';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

const orderTypeOptions = [
  { label: 'Ascending', value: 'ASC' },
  { label: 'Descending', value: 'DESC' },
];

const orderFieldOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Business', value: 'businessName' },
  { label: 'Start date', value: 'startDate' },
  { label: 'End date', value: 'endDate' },
];

export class DiscountSearch extends Component {
  handleChange = (e) => {
    const { setSearch, search } = this.props;
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  render() {
    const { name, category, orderType, orderField, business } =
      this.props.search;
    const { handleSearch, businesses, categories } = this.props;

    const categoryOptions = [];
    categories.forEach((item) => {
      categoryOptions.push(
        <ListSubheader key={item.label}>
          {item.label.toUpperCase()}
        </ListSubheader>
      );
      item?.options?.forEach((op) => {
        categoryOptions.push(
          <MenuItem key={op.value} value={op.value}>
            {op.label}
          </MenuItem>
        );
      });
    });

    return (
      <Stack
        component="form"
        direction="row"
        spacing={1}
        sx={{ mb: 4 }}
        onSubmit={(e) =>
          handleSearch(
            e,
            name,
            category ? category : null,
            `${orderField},${orderType}`,
            business ? business : null
          )
        }
      >
        <TextField
          name="name"
          value={name}
          label="Search"
          onChange={this.handleChange}
          inputProps={{ maxLength: 30 }}
          size="small"
        />
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="business-select-label">Business</InputLabel>
          <Select
            labelId="business-select-label"
            name="business"
            value={business}
            label="Business"
            onChange={this.handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {businesses.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            name="category"
            value={category}
            label="Category"
            onChange={this.handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categoryOptions}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="orderField-select-label">Sort by</InputLabel>
          <Select
            labelId="orderField-select-label"
            name="orderField"
            value={orderField}
            label="Sort by"
            onChange={this.handleChange}
          >
            {orderFieldOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="orderType-select-label">Sort type</InputLabel>
          <Select
            labelId="orderType-select-label"
            name="orderType"
            value={orderType}
            label="Sort by"
            onChange={this.handleChange}
          >
            {orderTypeOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button text="Search" type="submit" />
      </Stack>
    );
  }
}

DiscountSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default DiscountSearch;

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelLogin, login } from '../../redux/actions/authActions';
import Spinner from '../Inputs/Spinner';
import TextField from '../Inputs/TextField';
import css from './LoginDialog.module.scss';

const initialFormData = {
  email: '',
  password: '',
};

const LoginDialog = () => {
  const loading = useSelector((state) => state.auth.loading);
  const refreshingToken = useSelector((state) => state.auth.refreshingToken);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormData);

  const { email, password } = formData;

  useEffect(() => {
    if (refreshingToken) {
      setFormData(initialFormData);
    }
  }, [refreshingToken]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const handleCloseDialog = () => {
    dispatch(cancelLogin());
  };

  const handleSubmit = () => {
    dispatch(login(email, password));
  };

  return (
    <Dialog open={refreshingToken} maxWidth="lg" disableBackdropClick>
      <form onSubmit={(e) => onSubmit(e)}>
        <DialogTitle>
          <div className={css.dialog_header}>
            <h3>Refresh authorization token</h3>
            <i
              onClick={handleCloseDialog}
              className="fas fa-times closeDialog"
            ></i>
          </div>
        </DialogTitle>
        <div className={css.dialog_inner}>
          <div className={classnames('input-cntr', css.dialog_inner_item)}>
            Authorization token has expired. Please sign in again.
          </div>
          <TextField
            name="email"
            value={email}
            type="text"
            label="Email Address"
            placeholder="Email address"
            onChange={(e) => onChange(e)}
            error={errors.email}
          />
          <TextField
            name="password"
            value={password}
            type="password"
            label="Password"
            placeholder="Password"
            onChange={(e) => onChange(e)}
            error={errors.password}
          />
          <p className="text-danger">{errors.message}</p>{' '}
        </div>
        <DialogActions>
          {loading && <Spinner size={20} />}
          <Button
            onClick={handleSubmit}
            color="primary"
            isLoading={loading}
            type="submit"
          >
            Sign in
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Exit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { LoginDialog };

/* eslint-disable react/no-did-update-set-state */
import { MobileDatePicker } from '@mui/lab';
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import slugify from 'slugify';
import { a11yProps, isEmpty } from '../../../utils';
import ImageSelect from '../../Inputs/ImageSelect';
import Spinner from '../../Inputs/Spinner';
import Wysiwyg from '../../Inputs/Wysiwyg/Wysiwyg';
import LanguageSwitcher from '../../Layout/LanguageSwitcher';
import TabPanel from '../../Layout/TabPanel';
import SaveConfirmationRedirectDialog from '../common/SaveConfirmationRedirectDialog';
import SEOFields from '../common/SEOFields';
import css from './Post.module.scss';

const publishOptions = [
  { value: false, label: 'Not published' },
  { value: true, label: 'Published' },
];

export class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      tab: 'en',
      tagOptions: [],
      tagOptionsFR: [],

      // SEO
      metaDescription: '',
      twitterSummary: '',
      openGraphTitle: '',
      openGraphType: '',
      openGraphUrl: '',
      // openGraphImage: null,
      openGraphDescription: '',

      // Post data
      thumbnail: null,
      thumbnail_fr: null,
      headerImage: null,
      headerImageMobile: null,
      headerImage_fr: null,
      headerImageMobile_fr: null,
      published: true,
      startDate: null,
      endDate: null,
      slug: '',
      en: {
        title: '',
        content: '',
        excerpt: '',
        tags: [],
        summary: '',
      },
      fr: {
        title: '',
        content: '',
        excerpt: '',
        tags: [],
        summary: '',
      },
      loading: false,

      selectedTab: 0,
      askForRedirect: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { mode } = this.props.match.params;

    if (prevProps.match.params.mode !== mode) {
      window.location.reload();
    }
  };

  fetchTags = async () => {
    try {
      const res = await axios.get(`/tags`, {
        params: { dropdown: true },
      });

      this.setState({
        tagOptions: res.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (err) {}

    try {
      const resFR = await axios.get(`/tagsfr`, {
        params: { dropdown: true },
      });
      this.setState({
        tagOptionsFR: resFR.data.map((el) => ({
          value: el,
          label: el,
        })),
      });
    } catch (error) {}
  };

  componentDidMount = async () => {
    const { mode, id } = this.props.match.params;
    if (mode === 'edit' && id) {
      this.setState({ loading: true });
      try {
        const res = await axios.get(`/posts/${id}`);
        this.updateState(res.data);
      } catch (err) {
        this.setState({ err: err.response.data });
      }
    }

    this.fetchTags();
  };

  componentWillUnmount = () => {
    this.setState({});
  };

  updateState = (post) => {
    this.setState({
      thumbnail: post.thumbnail,
      thumbnail_fr: post.thumbnail_fr,
      headerImage: post.headerImage,
      headerImageMobile: post.headerImageMobile,
      headerImage_fr: post.headerImage_fr,
      headerImageMobile_fr: post.headerImageMobile_fr,
      published: publishOptions.find((el) => el.value === post.published),
      startDate: post.startDate
        ? moment(post.startDate).startOf('day').toDate()
        : null,
      endDate: post.endDate ? moment(post.endDate).endOf('day').toDate() : null,
      slug: post.slug,
      metaDescription: post.metaDescription,
      twitterSummary: post.twitterSummary,
      openGraphTitle: post.openGraphTitle,
      openGraphType: post.openGraphType,
      openGraphUrl: post.openGraphUrl,
      // openGraphImage: post.openGraphImage,
      openGraphDescription: post.openGraphDescription,
      en: {
        title: post.title,
        content: post.content,
        summary: post.summary,
        tags: post.tags.map((el) => {
          return { value: el, label: el };
        }),
      },
      fr: {
        title: post.title_fr,
        content: post.content_fr,
        summary: post.summary_fr,
        tags: post.tagsfr.map((el) => {
          return { value: el, label: el };
        }),
      },
      loading: false,
    });
  };

  changeTabs = (tab) => {
    this.setState({ tab: tab });
  };
  onChange = (e) => {
    const { tab } = this.state;
    this.setState({
      [tab]: { ...this.state[tab], [e.target.name]: e.target.value },
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleWysiwyg = (content) => {
    const { tab } = this.state;
    this.setState({
      [tab]: {
        ...this.state[tab],
        content: content,
      },
    });
  };
  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({ endDate });

    if (moment(startDate).isAfter(endDate)) {
      this.setState({ startDate: endDate });
    }
  };

  handleSelectPublish = (e) => {
    this.setState({ published: e.target.value });
  };

  handleCreatable = (tags) => {
    const { tab } = this.state;
    this.setState({ ...this.state, [tab]: { ...this.state[tab], tags } });
  };

  handleFileUpload = (imgType, file) => {
    this.setState({ [imgType]: file });
  };

  handleSelectImage = (image, name) => {
    this.setState({ [name]: image });
  };

  handleSubmit = async () => {
    const { snackbar } = this.props;
    const { mode, id } = this.props.match.params;
    const {
      en,
      fr,
      slug,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      thumbnail,
      thumbnail_fr,
      startDate,
      endDate,
      published,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      // openGraphImage,
      openGraphDescription,
    } = this.state;
    const payload = {
      title: en.title,
      content: en.content,
      summary: en.summary,
      tags: en.tags.map((el) => el.value),
      title_fr: fr.title,
      content_fr: fr.content,
      summary_fr: fr.summary,
      tagsfr: fr.tags.map((el) => el.value),
      headerImage: !isEmpty(headerImage) ? headerImage.id : null,
      headerImageMobile: !isEmpty(headerImageMobile)
        ? headerImageMobile.id
        : null,
      headerImage_fr: !isEmpty(headerImage_fr) ? headerImage_fr.id : null,
      headerImageMobile_fr: !isEmpty(headerImageMobile_fr)
        ? headerImageMobile_fr.id
        : null,
      thumbnail: !isEmpty(thumbnail) ? thumbnail.id : null,
      thumbnail_fr: !isEmpty(thumbnail_fr) ? thumbnail_fr.id : null,
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
      published: published.value,
      slug: mode === 'edit' ? undefined : slug,
      metaDescription,
      twitterSummary,
      openGraphTitle,
      openGraphType,
      openGraphUrl,
      // openGraphImage,
      openGraphDescription,
    };

    const requestMethod = mode === 'edit' ? 'put' : 'post';
    const path = mode === 'edit' ? `/posts/${id}` : `/posts`;

    try {
      const res = await axios[requestMethod](path, payload);
      this.setState({ askForRedirect: true, errors: {}, id: res.data.id });
      snackbar.setSnackbarMessage('Success');
    } catch (error) {
      const obj = error.response.data;
      if (
        obj.hasOwnProperty('title_fr') ||
        obj.hasOwnProperty('content_fr') ||
        obj.hasOwnProperty('summary_fr') ||
        obj.hasOwnProperty('tagsfr')
      ) {
        this.setState({ tab: 'fr' });
      } else this.setState({ tab: 'en' });
      this.setState({ errors: obj });
      snackbar.setSnackbarMessage("There's an error, please check your input.");
    }
  };

  handleDelete = async () => {
    const { id } = this.props.match.params;
    const { history } = this.props;
    try {
      await axios.delete(`/posts/${id}`);
      history.push('/dashboard/posts');
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  handleDeleteBtn = () => {
    if (this.state.deleteMode) {
      return (
        <Stack direction="row" spacing={1}>
          <Button onClick={this.handleDelete} variant="contained" color="error">
            Confirm
          </Button>
          <Button
            onClick={() => this.setState({ deleteMode: false })}
            variant="contained"
          >
            Cancel
          </Button>
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => this.setState({ deleteMode: true })}
        variant="contained"
        color="error"
      >
        Delete
      </Button>
    );
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  render() {
    const {
      errors,
      slug,
      en,
      fr,
      tab,
      startDate,
      endDate,
      thumbnail,
      thumbnail_fr,
      headerImage,
      headerImageMobile,
      headerImage_fr,
      headerImageMobile_fr,
      published,
      tagOptions,
      tagOptionsFR,
      loading,
      selectedTab,
      askForRedirect,
    } = this.state;

    const { mode } = this.props.match.params;

    let titleErr;
    let summaryErr;
    let contentErr;
    let tagErr;

    if (!isEmpty(errors)) {
      titleErr = tab === 'fr' ? errors.title_fr : errors.title;
      summaryErr = tab === 'fr' ? errors.summary_fr : errors.summary;
      contentErr = tab === 'fr' ? errors.content_fr : errors.content;
      tagErr = tab === 'fr' ? errors.tagsfr : errors.tags;
    }

    const domain = process.env.REACT_APP_WEB_DOMAIN;

    if (!loading)
      return (
        <div className={css.postContainer}>
          <SaveConfirmationRedirectDialog
            askForRedirect={askForRedirect}
            id={this.state.id}
            type="posts"
            mode={mode}
            clearState={() => this.setState({ askForRedirect: false })}
          />

          <h1>{mode === 'add' ? 'New post' : 'Edit post'}</h1>
          <p>
            <strong>Url: </strong>
            {`${domain}/blog/post/${slugify(slug, {
              lower: true,
            })}`}
          </p>

          <LanguageSwitcher language={tab} changeLanguage={this.changeTabs} />

          <Tabs value={selectedTab} onChange={this.handleChangeTab}>
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="SEO" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <Stack direction="column" spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="published-select-label">
                  Publishing options
                </InputLabel>
                <Select
                  labelId="published-select-label"
                  name="published"
                  value={published}
                  label="Publishing options"
                  onChange={this.handleSelectPublish}
                  error={errors.published}
                >
                  {publishOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={tab === 'en' ? en.title : fr.title}
                name="title"
                label={`Post title (${tab})`}
                placeholder="New page"
                required
                onChange={this.onChange}
                fullWidth
                error={titleErr}
                helperText={titleErr}
                maxLength={100}
              />

              <TextField
                value={slug}
                name="slug"
                label="Page url"
                placeholder="url"
                onChange={this.handleChange}
                disabled={mode === 'edit' ? true : false}
                error={!!errors.slug}
                helperText={errors.slug}
              />
              <MobileDatePicker
                name="endDate"
                value={endDate}
                label="End date"
                onChange={this.handleEndDate}
                error={errors.endDate}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
                minDate={moment()}
              />
              <MobileDatePicker
                name="startDate"
                label="Start date"
                onChange={this.handleStartDate}
                error={errors.startDate}
                maxDate={endDate}
                value={startDate}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />
              <Autocomplete
                value={tab === 'en' ? en.tags : fr.tags}
                fullWidth
                multiple
                onChange={(event, newValue) => {
                  const newValueCopy = [...newValue];
                  const latestValue = newValue[newValue.length - 1];
                  if (newValueCopy.length && latestValue.new) {
                    latestValue.label = latestValue.value;
                    latestValue.new = undefined;
                  }
                  this.setState({
                    [tab]: { ...this.state[tab], tags: newValueCopy },
                  });
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const filtered = options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: `Add "${inputValue}"`,
                      new: true,
                    });
                  }

                  return filtered;
                }}
                options={tab === 'en' ? tagOptions : tagOptionsFR}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField error={!!tagErr} helperText={tagErr} {...params} label={`Tags (${tab})`} />
                )}
              />
            </Stack>

            <Wysiwyg
              name="content"
              label={`Page content (${tab})`}
              val={tab === 'en' ? en.content : fr.content}
              onChange={this.handleWysiwyg}
              error={contentErr}
            />

            <TextField
              value={tab === 'en' ? en.summary : fr.summary}
              name="summary"
              label="Post summary"
              placeholder="Post summary"
              onChange={this.onChange}
              error={summaryErr}
              maxLength={200}
            />
            <ImageSelect
              initialImage={headerImage}
              label="Header image (en)"
              name="headerImage"
              type="Desktop"
              handleSelect={this.handleSelectImage}
              error={errors.headerImage}
            />
            <ImageSelect
              initialImage={headerImage_fr}
              label="Header image (fr)"
              name="headerImage_fr"
              type="Desktop"
              handleSelect={this.handleSelectImage}
            />

            <ImageSelect
              initialImage={headerImageMobile}
              label="Header image - Mobile (en)"
              name="headerImageMobile"
              type="Mobile"
              handleSelect={this.handleSelectImage}
              error={errors.headerImageMobile}
            />
            <ImageSelect
              initialImage={headerImageMobile_fr}
              label="Header image - Mobile (fr)"
              name="headerImageMobile_fr"
              type="Mobile"
              handleSelect={this.handleSelectImage}
            />

            <ImageSelect
              initialImage={thumbnail}
              label="Thumbnail (en)"
              name="thumbnail"
              type="Thumbnail"
              handleSelect={this.handleSelectImage}
              error={errors.thumbnail}
            />
            <ImageSelect
              initialImage={thumbnail_fr}
              label="Thumbnail (fr)"
              name="thumbnail_fr"
              type="Thumbnail"
              handleSelect={this.handleSelectImage}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <SEOFields
              value={this.state}
              errors={this.state.errors}
              handleChange={this.handleChange}
            />
          </TabPanel>

          <div className="btn_appart">
            <Button onClick={this.handleSubmit} variant="contained">
              {mode === 'edit' ? 'Save changes' : 'Create post'}
            </Button>
            {this.handleDeleteBtn()}
          </div>
        </div>
      );

    return <Spinner />;
  }
}

Post.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Post);

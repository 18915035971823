import React from "react";
import PropTypes from "prop-types";
import PostListItem from "./PostListItem";
import Spinner from "../../Inputs/Spinner";
import PostLIstHeader from "./PostLIstHeader";
import { isEmpty } from "../../../utils";

const PostList = ({ posts, loading, errors }) => {
  const list = isEmpty(errors) ? (
    posts.map((post) => <PostListItem key={post.id} post={post} />)
  ) : (
    <li>{errors.message}</li>
  );

  return (
    <div className="list-group">
      <ul>
        <PostLIstHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

PostList.propTypes = {
  posts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

PostList.defaultProps = {
  posts: [],
};

export default PostList;

import React from "react";
import PropTypes from "prop-types";
import PageListItem from "./PageListItem";
import Spinner from "../../Inputs/Spinner";
import PostLIstHeader from "../Posts/PostLIstHeader";
import { isEmpty } from "../../../utils";

const PostList = ({ pages, loading, errors }) => {
  const list = isEmpty(errors) ? (
    pages.map((page) => <PageListItem key={page.id} page={page} />)
  ) : (
    <li>{errors.message}</li>
  );
  return (
    <div className="list-group">
      <ul>
        <PostLIstHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

PostList.propTypes = {
  pages: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

PostList.defaultProps = {
  pages: [],
};

export default PostList;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCategories } from '../../../redux/actions/categoryActions';
import Category from './Category';
import CategoryList from './CategoryList';
import Pagination from '../../Layout/Pagination';

const Categories = ({
  getCategories,
  categories,
  totalResults,
  match,
  history,
  loading,
  errors,
}) => {
  const { params } = match;

  useEffect(() => {
    getCategories(params.page ? params.page : undefined);
  }, []);

  const changePage = (page) => {
    getCategories(page);
    history.push(`/dashboard/categories/${page}`);
  };

  return (
    <div>
      <h1>Categories</h1>
      <Category />
      <p>{errors.message}</p>
      <CategoryList categories={categories} loading={loading} />
      {totalResults ? (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      ) : null}
    </div>
  );
};

Categories.propTypes = {
  categories: PropTypes.object.isRequired,
  totalResults: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories.results,
  totalResults: state.categories.categories.totalResults,
  loading: state.categories.loading,
  errors: state.errors,
});

const mapDispatchToProps = {
  getCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Categories));

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user, refreshingToken },
  allowedRoles,
  snackbar,
  ...rest
}) => {
  const rolesArr = ['SUPER', ...allowedRoles];
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading && !refreshingToken) return <Redirect to="/" />;
        if (isAuthenticated && !loading && !rolesArr.includes(user.role))
          return <Redirect to="/" />;
        if (isAuthenticated && !loading && rolesArr.includes(user.role))
          return <Component snackbar={snackbar} {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  allowedRoles: PropTypes.array.isRequired,
};

PrivateRoute.defaultProps = {
  allowedRoles: [],
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const QuickActions = ({ role }) => {
  // Items that will appear in the sidebar
  // Don't forget to also add these in App.js Dashboard route
  // Don't forget to also add these in the Dashboard component
  const menuItems = [
    {
      name: 'New Page',
      url: 'pages/add',
      role: ['SUPER', 'CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'New Post',
      url: 'posts/add',
      role: ['SUPER', 'CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'New Discount',
      url: 'discounts/add',
      role: ['SUPER', 'CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    {
      name: 'New Business ',
      url: 'businesses/add',
      role: ['SUPER', 'CONTENT EDITOR', 'WEBSITE ADMIN'],
    },
    { name: 'Add users', url: 'users/add', role: ['SUPER', 'USER ADMIN'] },
  ];
  return (
    <div className="nav-section">
      <h4 className="heading">Quick Actions</h4>
      <ul>
        {menuItems.map((el) => {
          if (!el.role.includes(role)) return null;
          return (
            <li key={el.url}>
              <Link className="navlink" to={`/dashboard/${el.url}`}>
                <i className="far fa-sticky-note" />
                {el.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

QuickActions.propTypes = {
  role: PropTypes.string,
};

QuickActions.defaultProps = {
  role: null,
};

export default QuickActions;

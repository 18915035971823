import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Inputs/Button';
import { Stack, TextField } from '@mui/material';

const TagSearch = ({ search, setSearch, fetchTags }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ my: 3 }}>
      <TextField
        name="name"
        value={search}
        label="Search"
        size="small"
        onChange={(e) => setSearch(e.target.value)}
        inputProps={{ maxLength: 30 }}
      />
      <Button text="Search" onClick={fetchTags} />
    </Stack>
  );
};

TagSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default TagSearch;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import routes from './routes';

const Breadcrumbs = ({ location }) => {
  const numbers = /^\d+$/;
  const pathSplit = location.pathname.split('/');
  return (
    <div className='breadcrumbs'>
      {pathSplit.map((path, index) => {
        if (index !== 0 && !path.match(numbers) && routes[path] !== undefined)
          return (
            <Link className='breadcrumb-item' key={path} to={`${routes[path]}`}>
              {`${path}/`}
            </Link>
          );
        return null;
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Breadcrumbs);

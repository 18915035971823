import { MobileDatePicker } from '@mui/lab';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '../../utils';
import Pagination from '../Layout/Pagination';
import List from './List';

const activityOptions = [
  { label: 'Sale', value: 'affiliate_sale' },
  { label: 'Lead', value: 'affiliate_lead' },
  { label: 'Click', value: 'affiliate_click' },
  { label: 'Bonus', value: 'affiliate_bonus' },
];

const columns = [
  { label: 'Business Name', name: 'name' },
  { label: 'Program', name: 'program' },
  { label: 'Total affiliate spend', name: 'totalSpend' },
  { label: 'Total commission', name: 'totalComission' },
  { label: 'User commission share', name: 'userCommision' },
  { label: 'Upp Commission Share', name: 'uppCommision' },
  { label: 'Number of Affiliate Leads', name: 'leads' },
  { label: 'Number of UPP Leads', name: 'uppLeadCount' },
  { label: 'Number of purchases', name: 'sales' },
  { label: 'Number of page hits', name: 'pageHits' },
  { label: 'Cash Back %', name: 'cashBackPercentage' },
];

export default function AffiliateSummaryDashboard({ history, match }) {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [affiliateProgramOptions, setAffliateProgramOptions] = useState([]);

  useEffect(() => {
    fetchBusinesses();
    fetchAffiliatePrograms();
  }, []);

  const [totalResults, setTotalResults] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortBy, setSortBy] = useState({ field: 'label', asc: true });
  const [business, setBusiness] = useState([]);
  const [affiliateProgram, setAffiliateProgram] = useState([]);
  const [activityType, setActivityType] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { params } = match;

  const renderPagination = () => {
    if (
      (!isEmpty(errors) && errors.message === 'No data found') ||
      !totalResults
    ) {
      return null;
    } else
      return (
        <Pagination
          totalRecords={totalResults}
          page={params.page ? params.page : '1'}
          onPageChange={changePage}
        />
      );
  };

  const changePage = (page) => {
    fetchData(page);
    history.push(`/dashboard/affiliate-business-summary/${page}`);
  };

  const fetchAffiliatePrograms = async () => {
    try {
      const res = await axios.get(`/affiliates`);

      setAffliateProgramOptions(
        res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const fetchBusinesses = async () => {
    try {
      const res = await axios.get(`/businesses/dropdown`);
      setBusinessOptions(res.data);
    } catch (err) {
      setErrors(err.response.data);
    }
  };

  const setSort = (sort) => {
    setSortBy({ field: sort, asc: !sortBy.asc });
  };

  const fetchData = useCallback(
    async (page = 1, csv = false) => {
      const url = `/reports/affiliateBusinessSummary`;
      const options = {
        page: page,
        csv: csv,
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        business: business && business.map((el) => el.value).toString(),
        program:
          affiliateProgram &&
          affiliateProgram
            .map((el) => (el.value === null ? 'null' : el.value))
            .toString(),
        activity: activityType && activityType.map((el) => el.value).toString(),
        order: `${sortBy.field},${sortBy.asc ? 'ASC' : 'DESC'}`,
      };
      if (page === 1) history.push(`/dashboard/affiliate-business-summary/1`);
      setErrors({});
      setLoading(true);
      try {
        const res = await axios.get(url, { params: options });

        if (csv) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `affiliate-summary-export-${moment().format('MM-DD-YYYY')}.csv`
          );
          document.body.appendChild(link);
          link.click();
        } else {
          setTransactionData(res.data.rows);
          setTotalResults(res.data.count);
        }
        setLoading(false);
      } catch (error) {
        setErrors(error.response.data);
        setLoading(false);
        setTotalResults(0);
      }
    },
    [
      activityType,
      affiliateProgram,
      business,
      endDate,
      history,
      sortBy.asc,
      sortBy.field,
      startDate,
    ]
  );

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, fetchData]);

  return (
    <div>
      <h1>Affiliate Business Dashboard</h1>

      <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
        <Autocomplete
          sx={{ width: 200 }}
          multiple
          id="business-select-label"
          options={businessOptions}
          value={business}
          onChange={(event, newValue) => {
            setBusiness(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Business" />}
        />
        <Autocomplete
          sx={{ width: 200 }}
          name="affiliateProgram"
          multiple
          id="business-select-label"
          options={[{ value: null, label: 'None' }, ...affiliateProgramOptions]}
          value={affiliateProgram}
          onChange={(event, newValue) => {
            setAffiliateProgram(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Affiliate Program" />
          )}
        />
        <MobileDatePicker
          name="startDate"
          value={startDate}
          label="From Date"
          onChange={(date) => setStartDate(date)}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <MobileDatePicker
          name="endDate"
          value={endDate}
          label="To Date"
          onChange={(date) => setEndDate(date)}
          clearable
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <Autocomplete
          sx={{ width: 200 }}
          multiple
          id="activityType-select-label"
          options={activityOptions}
          value={activityType}
          onChange={(event, newValue) => {
            setActivityType(newValue);
          }}
          size="small"
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Activity Type" />
          )}
          error={errors.activityType}
        />
      </Stack>
      <Button onClick={() => fetchData(1, true)}>Export</Button>
      <List
        columns={columns}
        setSort={setSort}
        sortBy={sortBy}
        data={transactionData}
        loading={loading}
        errors={errors}
      />
      {renderPagination()}
    </div>
  );
}

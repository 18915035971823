import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Spinner from '../../Inputs/Spinner';
import css from './AdjustUserCashbackBalance.module.scss';

const AdjustUserCashbackBalance = ({ open, closeHandler, userId }) => {
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [systemCurrency, setSystemCurrency] = useState('CAD');
  const [cashback, setCashback] = useState('0');
  const [adjustCashbackValue, setAdjustCashbackValue] = useState('0');
  const [isCredit, setIsCredit] = useState(true);
  const [isCashout, setIsCashout] = useState(false);
  const [adjustReason, setAdjustReason] = useState('');
  const onIsCreditChange = useCallback((event) => {
    setIsCredit(event.target.checked);
  }, []);
  const adjustCashbackChangeHandler = useCallback((event) => {
    const { value } = event.target;
    if (value >= 0 && value <= 500) {
      const realAdjustCashbackValue = value ? normalizeFloat(value) : value;
      setAdjustCashbackValue(realAdjustCashbackValue);
    }
  }, []);
  const onIsCashoutChange = useCallback((event) => {
    setIsCashout(event.target.checked);
    if (event.target.checked) {
      setIsCredit(false);
    }
  }, []);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setError({});
    try {
      await axios.post(`/users/${userId}/cashback`, {
        adjustValue: adjustCashbackValue || '0',
        isCredit,
        isCashout,
        adjustReason,
      });
      closeHandler();
    } catch (error) {
      const baseError = { message: 'Error adjusting cashback' };
      console.error(error);
      if (error.response && error.response.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [
    adjustCashbackValue,
    adjustReason,
    closeHandler,
    isCashout,
    isCredit,
    userId,
  ]);
  const handleCloseDialog = useCallback(() => {
    closeHandler();
  }, [closeHandler]);
  const handleAdjustReasonChange = useCallback((event) => {
    setAdjustReason(event.target.value);
  }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await Promise.all([
          axios.get(`/users/${userId}/cashback`),
          axios.get(`/system/system-currency`),
        ]);
        setCashback(res[0].data);
        setSystemCurrency(res[1].data);
      } catch (err) {
        setError({ message: "Cannot fetch user's cashback" });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, [userId]);
  return (
    <Dialog open={open} maxWidth="lg" disableBackdropClick>
      <DialogTitle>
        <div className={css.dialog_header}>
          <h3>Adjust Cashback Balance</h3>
          <i
            onClick={handleCloseDialog}
            className="fas fa-times closeDialog"
          ></i>
        </div>
      </DialogTitle>
      <div className={css.dialog_inner}>
        <div
          className={classnames('input-cntr', css.dialog_inner_item)}
        >{`Current cash back balance ${cashback} ${systemCurrency}`}</div>
        <TextField
          fullWidth
          name="adjustValue"
          value={adjustCashbackValue}
          label={`Adjust Cashback Value (${isCredit ? 'Credit' : 'Debit'})`}
          onChange={adjustCashbackChangeHandler}
          type="number"
          disabled={isLoading}
          error={error && !!error.adjustValue}
          helperText={error && error.adjustValue}
          InputProps={{
            inputProps: { min: 0, max: 100, step: 0.01 },
            endAdornment: (
              <InputAdornment position="end">{systemCurrency}</InputAdornment>
            ),
          }}
        />
        {!isCashout ? (
          <div className={classnames('input-cntr', css.dialog_inner_item)}>
            <label className="label" htmlFor="isCredit">
              Is Credit
            </label>
            <Switch
              inputProps={{ id: 'isCredit' }}
              size="small"
              color="primary"
              checked={isCredit}
              onChange={onIsCreditChange}
              disabled={isLoading}
            />
          </div>
        ) : null}

        <div className={classnames('input-cntr', css.dialog_inner_item)}>
          <label className="label" htmlFor="isCashout">
            Is Cashout
          </label>
          <Switch
            inputProps={{ id: 'isCashout' }}
            size="small"
            color="primary"
            checked={isCashout}
            onChange={onIsCashoutChange}
            disabled={isLoading}
          />
        </div>
        <TextField
          fullWidth
          name="adjustReason"
          value={adjustReason}
          label={`${isCredit ? 'Credit' : 'Debit'} reason`}
          onChange={handleAdjustReasonChange}
          disabled={isLoading}
          error={error && !!error.adjustReason}
          helperText={error && error.adjustReason}
        />
        {error && error.message && (
          <div className="error_msg">{error.message}</div>
        )}
      </div>
      <DialogActions>
        {isLoading && <Spinner size={20} />}
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
        <Button onClick={handleCloseDialog} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdjustUserCashbackBalance.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  userId: PropTypes.number,
};

export { AdjustUserCashbackBalance };

import { InputAdornment, Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Button from '../../Inputs/Button';

export default function CashbackPercentage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [userSharePercentage, setUserSharePercentage] = useState('0');
  const [isNewPercentage, setIsNewPercentage] = useState(true);
  const [companySharePercentage, setCompanySharePercentage] = useState('100');
  const handleUserShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        const newUserSharePercentage = value ? normalizeFloat(value) : value;
        setUserSharePercentage(newUserSharePercentage);
        if (value === '100' && !companySharePercentage) {
          return;
        }
        setCompanySharePercentage(
          normalizeFloat(100 - parseFloat(value || '0'))
        );
      }
    },
    [companySharePercentage]
  );
  const handleCompanyShareChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value <= 100 && value >= 0) {
        setCompanySharePercentage(value ? normalizeFloat(value) : value);
        if (value === '100' && !userSharePercentage) {
          return;
        }
        const newUserSharePercentage = normalizeFloat(
          100 - parseFloat(value || '0')
        );
        setUserSharePercentage(newUserSharePercentage);
      }
    },
    [userSharePercentage]
  );
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`/system/user-cashback-percentage`);
        setIsNewPercentage(!res.data);
        setUserSharePercentage(res.data);
        setCompanySharePercentage(100 - parseFloat(res.data || '0'));
      } catch (err) {
        setError({ message: 'Cannot fetch system wide cashback' });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, []);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const userPercentage = userSharePercentage || '0';
      const method = isNewPercentage ? 'post' : 'put';
      await axios[method](`/system/user-cashback-percentage`, {
        systemWideCashbackPercentage: userPercentage,
      });
      setIsNewPercentage(false);
      setError({});
    } catch (error) {
      const baseError = { message: 'Error saving system settings' };
      console.error(error);
      if (error.response?.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [isNewPercentage, userSharePercentage]);
  return (
    <>
      <Stack direction="column" spacing={2} sx={{ mb: 3, width: 300 }}>
        <TextField
          name="userShare"
          value={userSharePercentage}
          type="number"
          label="User Share"
          onChange={handleUserShareChange}
          disabled={isLoading}
          error={error?.amount}
          InputProps={{
            inputProps: { min: 0, max: 100, step: 0.01 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          name="companyShare"
          value={companySharePercentage}
          label="Company Share"
          onChange={handleCompanyShareChange}
          type="number"
          disabled={isLoading}
          InputProps={{
            inputProps: { min: 0, max: 100, step: 0.01 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        {error?.message && <div className="error_msg">{error.message}</div>}
      </Stack>
      <Button
        onClick={handleSubmit}
        text="Save"
        needConfirm
        confirmationText="You are about to change the split percentage for the whole system"
        isLoading={isLoading}
      />
    </>
  );
}

import React, { Component } from 'react';
import Select from './Select';
import TextField from './TextField';
import Button from './Button';
import { isEmpty } from '../../utils';
import Wysiwyg from './Wysiwyg/Wysiwyg';

export class InputTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      numbers: '',
      select: null,
      disabled: false,
      html: '',
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleWysiwyg = (html) => {
    this.setState({ html });
  };

  handleSelect = (select) => {
    this.setState({ select });
  };

  handleDisable = () => {
    const { disabled } = this.state;
    this.setState({ disabled: !disabled });
  };

  handleErrors = () => {
    const { errors } = this.state;
    this.setState({
      errors: isEmpty(errors)
        ? { select: 'This is an error', text: 'This is an error' }
        : {},
    });
  };

  render() {
    const { text, select, numbers, html, disabled, errors } = this.state;

    const options = [
      { value: 'one', label: 'one' },
      { value: 'two', label: 'two' },
      { value: 'three', label: 'three' },
      { value: 'four', label: 'four' },
    ];

    return (
      <div>
        <h2>Input test</h2>

        <Button text="Toggle disable" onClick={this.handleDisable} />
        <Button
          text="Toggle errors"
          onClick={this.handleErrors}
          error={!isEmpty(errors)}
        />

        <div className="margin-lg">
          <Select
            value={select}
            name="select"
            label="Select"
            options={options}
            helperText="This is helper text"
            onChange={this.handleSelect}
            error={errors.select}
            disabled={disabled}
          />
        </div>

        <div className="margin-lg">
          <TextField
            value={text}
            name="text"
            label="Text"
            placeholder="Enter text"
            helperText="This is helper text"
            onChange={this.onChange}
            error={errors.text}
            disabled={disabled}
          />
        </div>
        <div className="margin-lg">
          <TextField
            value={numbers}
            name="numbers"
            type="number"
            format="+1 ### ### ####"
            label="Numbers"
            placeholder="Enter numbers"
            helperText="This is helper text"
            maxLength={5}
            onChange={this.onChange}
            error={errors.text}
            disabled={disabled}
          />
        </div>

        <div className="margin-lg">
          <Wysiwyg
            name="wysiwyg"
            label="Wysiwyg"
            val={html}
            onChange={this.handleWysiwyg}
          />
        </div>
      </div>
    );
  }
}

export default InputTest;

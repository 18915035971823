const routes = {
  dashboard: "/dashboard",
  users: "/dashboard/users",
  posts: "/dashboard/posts",
  pages: "/dashboard/pages",
  categories: "/dashboard/categories",
  tags: "/dashboard/tags",
  discounts: "/dashboard/discounts",
  polls: "/dashboard/polls",
};

export default routes;

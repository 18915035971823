import { Tab, Tabs } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { a11yProps } from 'src/utils';
import Spinner from '../../Inputs/Spinner';
import TabPanel from '../../Layout/TabPanel';
import css from './Poll.module.scss';
import PollSettings from './PollSettings';
import Question from './Questions/Question';
import Questions from './Questions/Questions';
import PollSubmissions from './Submissions/PollSubmissions';

function Poll({ match, location }) {
  const [errors, setErrors] = useState({});
  const [poll, setPoll] = useState({});
  const [loading, setloading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState({});

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getPoll = useCallback(async () => {
    try {
      const res = await axios.get(`/polls/${match.params.id}`);
      setloading(false);
      setPoll(res.data);
      setQuestions(res.data.questions);
    } catch (err) {
      setErrors(err.response);
      setloading(false);
    }
  }, [match.params.id]);

  useEffect(() => {
    getPoll();
  }, [getPoll]);

  if (loading) return <Spinner />;

  return (
    <div className={css.container}>
      <h1>Edit poll</h1>
      <p>Internal Name: {poll.name}</p>
      <p>
        <strong>id:</strong> {poll.id}
      </p>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        <Tab label="Fields" {...a11yProps(0)} />
        <Tab label="Submissions" {...a11yProps(1)} />
        <Tab label="Settings" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <div className={css.questions}>
          <div className={css.left}>
            <Questions
              questions={questions}
              selectQuestion={setQuestion}
              selectedQ={question.id}
            />
          </div>
          <div className={css.right}>
            <Question
              question={question}
              getPoll={getPoll}
              questions={questions}
              pollQuestionHelper={setQuestion}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <PollSubmissions pollId={poll.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <PollSettings getPoll={getPoll} poll={poll} />
      </TabPanel>
    </div>
  );
}

Poll.propTypes = {};

export default Poll;

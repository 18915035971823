import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from "../../Inputs/Spinner";
import TagListHeader from "./TagList/TagListHeader";
import TagListItem from "./TagList/TagListItem";
import { isEmpty } from "lodash";

const TagList = ({ tags, lang, loading, fetchTags, errors }) => {
  const list = isEmpty(errors) ? (
    tags.map((tag) => (
      <TagListItem
        key={tag.id}
        tag={tag}
        lang={lang}
        id={tag.id}
        fetchTags={fetchTags}
      />
    ))
  ) : (
    <li>{errors.message}</li>
  );

  return (
    <div className="list-group">
      <ul>
        <TagListHeader />

        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TagList;

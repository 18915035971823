import React from "react";
import Spinner from "../../Inputs/Spinner";
import { isEmpty } from "../../../utils";
import BusinessListItem from "./BusinessListItem";
import BusinessListHeader from "./BusinessListHeader";

const BusinessList = ({ businesses, loading, errors }) => {
  const list = isEmpty(errors) ? (
    businesses.map((business) => (
      <BusinessListItem key={business.id} business={business} />
    ))
  ) : (
    <li>{errors.message}</li>
  );
  return (
    <div className="list-group">
      <ul>
        <BusinessListHeader />
        {loading ? <Spinner /> : list}
      </ul>
    </div>
  );
};

export default BusinessList;

import { InputAdornment, Box, TextField } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeFloat } from '../../../utils';
import Button from '../../Inputs/Button';

export default function ReferralValue({
  endpoint,
  fieldName,
  fieldLabel,
  confirmationText,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [cashbackValue, setCashbackValue] = useState('0');
  const [isNewCashbackValue, setIsNewCashbackValue] = useState(true);
  const [systemCurrency, setSystemCurrency] = useState('CAD');

  const cashbackValueChangeHandler = useCallback((event) => {
    const newCashbackValue = normalizeFloat(event.target.value || '0');
    if (newCashbackValue >= 0) {
      setCashbackValue(newCashbackValue);
    }
  }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const res = await Promise.all([
          axios.get(`/system/${endpoint}`),
          axios.get(`/system/system-currency`),
        ]);
        setIsNewCashbackValue(
          res[0].data === null ||
            res[0].data === undefined ||
            res[0].data === ''
        );
        setCashbackValue(res[0].data || '0');
        setSystemCurrency(res[1].data);
      } catch (err) {
        setError({ message: 'Cannot fetch system settings' });
        console.error(err);
      }
      setIsLoading(false);
    };
    setError({});
    fetchInitialData();
    return () => {};
  }, [endpoint]);
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const cashbackValueToSubmit = cashbackValue || '0';
      const method = isNewCashbackValue ? 'post' : 'put';
      await axios[method](`/system/${endpoint}`, {
        [fieldName]: cashbackValueToSubmit,
      });
      setIsNewCashbackValue(false);
      setError({});
    } catch (error) {
      const baseError = { message: 'Error saving system settings' };
      console.error(error);
      if (error.response?.data) {
        setError({ ...error.response.data, ...baseError });
      } else {
        setError(baseError);
      }
    }
    setIsLoading(false);
  }, [cashbackValue, endpoint, fieldName, isNewCashbackValue]);
  return (
    <>
      <Box sx={{ mb: 3 }}>
        <TextField
          name="adjustValue"
          label={fieldLabel}
          value={cashbackValue}
          onChange={cashbackValueChangeHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{systemCurrency}</InputAdornment>
            ),
          }}
          disabled={isLoading}
          inputProps={{ type: 'number', inputMode: 'numeric' }}
          error={!!error?.[fieldName]}
          helperText={error?.[fieldName]}
        />
      </Box>
      {error?.message && <div className="error_msg">{error.message}</div>}
      <Button
        onClick={handleSubmit}
        text="Save"
        needConfirm
        confirmationText={confirmationText}
        isLoading={isLoading}
      />
    </>
  );
}
